import React, { useState } from 'react';
import './styles.css';
import { AiOutlineCar } from 'react-icons/ai';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { BiBookmarkAltMinus, BiUserPlus } from 'react-icons/bi';
import { AiOutlineHome } from 'react-icons/ai';
import FormInput from './FormInput';
import FormOptions from './FormOptions';
import leftGrid from '../../assets/img/Repeat_Grid_1.png';
import rightGrid from '../../assets/img/Repeat_Grid_2.png';
import { vehicleinfo, Policyinfo, profileinfo } from './AllQuestions';
const mysteps = [vehicleinfo, Policyinfo, profileinfo];

const AnimatedCard = props => {
  const [progress, setProgress] = useState(0);
  const [progress1, setProgress1] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  function handleChange(evt) {
    const value = evt.target.value;
    console.log(value)
    setState({
      ...state,
      [evt.target.name]: value
    });
  }

  const [state, setState] = React.useState({
    firstname: '',
    lastname: '',
    middlename: '',
    email: '',
    postaladdress: '',
    password: '',
    color: '',
    dob: '',
    phone_number: '',
    gender: '',
    registration_no: '',
    vehicle_make: '',
    body_type: '',
    engine_no: '',
    chasis_number: '',
    year_of_manufacturing: '',
    year_of_registration: '',
    year_of_claim: '',
    date_purchased: '',
    purchase_amount: '',
    buy_back_excess: '',
    altered_vehicle_design: '',
    third_party_damage: '',
    value_of_vehicle: '',
    state_of_vehicle: '',
    mileage: 0,
    what_use_of_vehicle: '',
    insurance_product_type: '',
    national_id: '',
    inception_date: '',
    expiry_date: '',
    currency: '',
    model_id: '',
    seats: '',
    other_usage: '',
    selected_model: true
  });

  const increaseProgress = () => {
    let section = 100 / vehicleinfo.length;
    setProgress(prevProgress => prevProgress + section);
  };

  const deccreaseProgress = () => {
    let section = 100 / vehicleinfo.length;
    setProgress(prevProgress => prevProgress - section);
  };

  const increaseProgress1 = () => {
    let section = 100 / (Policyinfo.length);
    setProgress1(prevProgress => prevProgress + section);
  };

  const deccreaseProgress1 = () => {
    let section = 100 / (Policyinfo.length);
    setProgress1(prevProgress => prevProgress - section);
  };

  const incrementSecond = e => {
    e.preventDefault();
    increaseProgress1();
    setPageIndex(pageIndex + 1);
  };
  const moveNext = e => {
    e.preventDefault();
    setActiveStep(2);
    setPageIndex(0);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh'
      }}
    >
      <div style={{ position: 'absolute', left: 0, top: 0 }}>
        <img src={leftGrid} height={'50%'} />
      </div>
      <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
        <img src={rightGrid} height={200} />
      </div>
      <div
        style={{
          width: 550,
          display: 'flex',
          padding: 10,
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginTop: '5%',
          marginBottom: '5%'
        }}
      >
        <div
          className="header"
          style={{ display: 'flex', flexDirection: 'row', marginTop: 50 }}
        >
          <AiOutlineCar
            size={20}
            className={activeStep == 0 ? 'active-class' : ''}
          />
          <div style={{ width: '250px', marginTop: 5 }}>
            
          </div>

          <BiBookmarkAltMinus
            size={20}
            className={activeStep == 1 ? 'active-class' : ''}
          />
          <div style={{ width: '250px', marginTop: 5 }}>
            <LinearProgress variant="determinate" value={progress1} />
          </div>

          <BiUserPlus
            size={20}
            className={activeStep == 2 ? 'active-class' : ''}
          />
        </div>

        <div>
          <form>
            <>
              {activeStep === 0 ? (
                <>
                  <h3 style={{ textAlign: 'center', marginTop: 20 }}>
                    {mysteps[0][pageIndex].title}
                  </h3>

                  {mysteps[0][pageIndex].questions.map(currentValue => (
                    <div style={{ paddingBottom: 20 }}>
                      {currentValue.type === 'select' ? (
                        <FormOptions
                        type={currentValue.type}
                        placeholder={currentValue.placeholder}
                        name={currentValue.name}
                        label={currentValue.label}
                        handleChange={handleChange}
                        options={currentValue.options}
                      />
                      ) : (
                        <FormInput
                          type={currentValue.type}
                          placeholder={currentValue.placeholder}
                          name={currentValue.name}
                          label={currentValue.label}
                          handleChange={handleChange}
                        />
                      )}
                    </div>
                  ))}
                </>
              ) : activeStep === 1 ? (
                <>
                  <h3 style={{ textAlign: 'center', marginTop: 20 }}>
                    {mysteps[1][pageIndex].title}
                  </h3>

                  {mysteps[1][pageIndex].questions.map(currentValue => (
                    <div style={{ paddingBottom: 20 }}>
                      {currentValue.type === 'select' ? (
                        <FormOptions
                          type={currentValue.type}
                          placeholder={currentValue.placeholder}
                          name={currentValue.name}
                          label={currentValue.label}
                          handleChange={handleChange}
                          options={currentValue.options}
                        />
                      ) : (
                        <FormInput
                          type={currentValue.type}
                          placeholder={currentValue.placeholder}
                          name={currentValue.name}
                          label={currentValue.label}
                          handleChange={handleChange}
                        />
                      )}
                    </div>
                  ))}
                </>
              ) : activeStep === 2 ? (
                <>
                  <h3 style={{ textAlign: 'center', marginTop: 20 }}>
                    {mysteps[2][pageIndex].title}
                  </h3>

                  {mysteps[2][pageIndex].questions.map(currentValue => (
                    <div style={{ paddingBottom: 20 }}>
                      {currentValue.type === 'select' ? (
                        <FormOptions
                          type={currentValue.type}
                          placeholder={currentValue.placeholder}
                          name={currentValue.name}
                          label={currentValue.label}
                          handleChange={handleChange}
                          options={currentValue.options}
                        />
                      ) : (
                        <FormInput
                          type={currentValue.type}
                          placeholder={currentValue.placeholder}
                          name={currentValue.name}
                          label={currentValue.label}
                          handleChange={handleChange}
                        />
                      )}
                    </div>
                  ))}
                </>
              ) : null}
            </>
          </form>
        </div>

        <div>
          {activeStep == 0 ? (
            <>
              {progress <= 0 ? (
                <>
                  {' '}
                  <button
                    className="btn btn-primary"
                    style={{ width: '100%' }}
                    onClick={e => {
                      e.preventDefault();
                      increaseProgress();
                      setPageIndex(pageIndex + 1);
                    }}
                  >
                    Continue
                  </button>{' '}
                </>
              ) : (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <button
                    className="btn btn-primary"
                    style={{ width: '25%' }}
                    onClick={e => {
                      e.preventDefault();
                      deccreaseProgress();
                      setPageIndex(pageIndex - 1);
                    }}
                  >
                    Back
                  </button>{' '}
                  <button
                    className="btn btn-primary"
                    style={{ width: '65%' }}
                    onClick={e => {
                      e.preventDefault();
                      increaseProgress();
                      setActiveStep(1);
                      setPageIndex(0);
                    }}
                  >
                    Continue
                  </button>
                </div>
              )}
            </>
          ) : activeStep === 1 ? (
            <>
              {progress1 <= 0 ? (
                <>
                  {' '}
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <button
                      className="btn btn-primary"
                      style={{ width: '25%' }}
                      onClick={e => {
                        e.preventDefault();
                        deccreaseProgress();
                        setActiveStep(0);
                        setPageIndex(1);
                      }}
                    >
                      Back
                    </button>{' '}
                    <button
                      className="btn btn-primary"
                      style={{ width: '65%' }}
                      onClick={e => {
                        e.preventDefault();
                        increaseProgress1();
                        setPageIndex(pageIndex + 1);
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </>
              ) : (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <button
                    className="btn btn-primary"
                    style={{ width: '25%' }}
                    onClick={e => {
                      e.preventDefault();
                      deccreaseProgress1();
                      setPageIndex(pageIndex - 1);
                    }}
                  >
                    Back
                  </button>{' '}
                  <button
                    className="btn btn-primary"
                    style={{ width: '65%' }}
                    onClick={e =>
                      progress1 >= 100 ? moveNext(e) : incrementSecond(e)
                    }
                  >
                    Continue
                  </button>
                </div>
              )}
            </>
          ) : activeStep === 2 ? (
            <>
              {' '}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button
                  className="btn btn-primary"
                  style={{ width: '25%' }}
                  onClick={e => {
                    e.preventDefault();

                    setActiveStep(1);
                    setPageIndex(Policyinfo.length - 1);
                  }}
                >
                  Back
                </button>{' '}
                <button
                  className="btn btn-primary"
                  style={{ width: '65%' }}
                  onClick={e => {
                    e.preventDefault();
                    console.log(state)
                  }}
                >
                  Submit
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AnimatedCard;
