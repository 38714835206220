import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 5px 10px hsla(214, 37%, 89%, 0.996)',
    marginBottom: '20px',
    background: '#fff',
    borderRadius: '5px'
  },
  row1: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px'
  },
  row2: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px'
  }
}));

const SummaryItem = ({data}) => {
 const {time,totalamount,price,vendor_name,item_name,count,customer_name}=data
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.row1)}>
        <div>
          {' '}
          <Typography
            align="center"
            // color="primary"

            variant="body1"
          >
            {time} <strong>Sales</strong>
          </Typography>
        </div>
        <div>
          {' '}
          <Typography
            align="center"
            color="primary"

            variant="body1"
          >
            
            GHS{price}
          </Typography>
        </div>
      </div>
      <div className={clsx(classes.row2)}>
        <div>
          <Typography
            align="center"
            variant="body1"
          >
            {customer_name||item_name}
          </Typography>
        </div>
      </div>
     
    </div>
  );
};

SummaryItem.propTypes = {
  className: PropTypes.string
};

export default SummaryItem;
