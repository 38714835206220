import React, { useState, useEffect, useRef } from 'react';
import './styles.css';
import './MobileStyles.css';
import { useAppContext } from '../../Context';
import { useNavigate } from 'react-router-dom';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import MyStepper from 'src/components/MyStepper';
import { Modal, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useAuth0 } from '@auth0/auth0-react';

import { useLazyQuery } from '@apollo/client';
import { LOGIN_USER } from 'src/Graphql/Queries';
import myPolicyLogo from '../../assets/img/MyPolicy.svg';
import myPolicyLogo_white from '../../assets/img/MyPolicy_white.png';
import { ChevronDown, Menu, X } from 'react-feather';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Insurance Products
import fire from '../../assets/img/fire.png';
import life from '../../assets/img/life.png';
import marine from '../../assets/img/marine.png';
import motor from '../../assets/img/motor.png';
import propertyInsurance from '../../assets/img/home-owner.png';

import axios from 'axios';

//Steps
import shield from '../../assets/img/shield.png';
import options from '../../assets/img/options.png';
import chat from '../../assets/img/chat.png';
import policy from '../../assets/img/policies.png';
import quote from '../../assets/img/quote.png';
import { Link } from 'react-scroll';

//Screens
import screen_1 from '../../assets/img/screen.png';

// import { border, borderRadius } from '@mui/material/node_modules/@mui/system';
import { SuccessNotification } from 'src/components/Notification';
import WebFont from 'webfontloader';
import { BsCloudUpload } from 'react-icons/bs';
import { HiOutlinePencil } from 'react-icons/hi';

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const LandingPageMobile = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const context = useAppContext();
  const navigate = useNavigate();
  const { dispatch, allpolicies, policyloading } = context;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const steps = [
    'Vehicle Information',
    'Policy Information',
    'Personal Information',
    'Summary'
  ];
  const [policy_id, setPolicy_id] = useState('');
  const { loginWithRedirect } = useAuth0();
  const { logout } = useAuth0();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [openFirstModal, setOpenFirstModal] = useState(false);
  const handleClose2 = () => setOpenFirstModal(false);

  const createToken = () => {
    if (localStorage.access_token) {
      localStorage.setItem('access_token', localStorage.access_token);
    } else {
      var data = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        audience: process.env.REACT_APP_AUDIENCE,
        grant_type: process.env.REACT_APP_GRANT_TYPE
      };
      // e.preventDefault();
      setLoading(true);
      var config = {
        method: 'post',
        url: process.env.REACT_APP_AUTH0_URL,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function(response) {
          localStorage.setItem('access_token', response.data.access_token);
        })
        .catch(function(error) {});
    }
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem('auth0_id', user.sub);
      login(user.email);
      createToken();
    }
  }, [isAuthenticated]);

  const imgArray = [
    {
      picture: motor,
      name: 'Motor Insurance',
      state: true
    },
    {
      picture: propertyInsurance,
      name: 'Home Owner Insurance',
      state: true
    },
    {
      picture: life,
      name: 'Life Insurance',
      state: false
    },
    {
      picture: marine,
      name: 'Marine Insurance',
      state: false
    },
    {
      picture: fire,
      name: 'Fire Insurance',
      state: false
    }
  ];

  const stepArray = [
    {
      name: 'Step 1',
      caption: 'Select your Insurance Type',
      desc: 'Select the insurance type you want to buy',
      img: shield
    },
    {
      name: 'Step 2',
      caption: 'Make a Request',
      desc: 'Provide your information for the selected insurance type.',
      img: chat
    },
    {
      name: 'Step 3',
      caption: 'Get your Quote',
      desc:
        'Our trusted insurance brokers will send you quotes based on your request.',
      img: quote
    },
    {
      name: 'Step 4',
      caption: 'Pay',
      desc: 'Select the best quote for you and pay instantly.',
      img: options
    },
    {
      name: 'Step 5',
      caption: 'Manage your Policies',
      desc: 'You can now manage your purchased insurance on the platform.',
      img: policy
    }
  ];

  const faqs = [
    {
      question: 'What is MyPolicy.market?',
      answer: `MyPolicy.com offers customers the convenience of accessing insurance policies from the 
                  comfort of their homes and offices through our partner insurance 
                  intermediaries (Brokers & Agents).`
    },
    {
      question: 'Who are insurance intermediaries?',
      answer: `Insurance intermediaries serve as a link between consumers
          and insurance companies. An Insurance Intermediary means
          individual agents, corporate agents including banks and
          brokers. Insurance Intermediary such as Loss Adjusters who
          are not involved in the sale of insurance policies.`
    },
    {
      question: 'Which Intermediaries does MyPolicy.com partner with?',
      answer: ` MyPolicy.market partners with Insurance Agents and Brokers`
    },
    {
      question: 'Insurance Agents',
      answer: `An insurance agent is licensed to sell insurance on behalf
          of an insurance company. Agents represent the insurer in the
          insurance process and usually operate under the terms of an
          agency agreement with the insurer.`
    },
    {
      question: 'Insurance Broker',
      answer: `An insurance broker is a person or company licensed to sell
          insurance and typically works with multiple insurance
          companies offering a variety of products to a customer. A
          broker works with the client to find the most suitable
          policy at the right price for the client.`
    },
    {
      question: 'Why should I take insurance on my vehicle?',
      answer: `Owning a vehicle exposes you to risks and liabilities which
          could be crippling when they occur. Aside losing your
          vehicle to fire, theft, and damage from accidents which
          would be a considerable financial burden to a household or
          business, the legal liability arising from the injury or
          death of a third party would be unbearable for most people.
          Having legally mandated third-party policy covers you from
          financial liabilities arising from the injury or death of
          third parties and a cover of GHS 5,000 for damage caused to
          property belonging to third parties which can be increased
          at a cost to the insured.`
    },
    {
      question: 'What are my responsibilities as policy holder?',
      answer: `It is important for you to disclose all relevant information
          and avoid concealing and providing false information to your
          insurer. Failure to do this will result in you being left
          without insurance cover. It is also the responsibility of
          the insured to inform the insurer of incidents and take
          adequate measures in preventing further losses`
    },
    {
      question: 'What is "No Claim Discount"?',
      answer: `No Claim Discount is a discount offered by the insurance
          company for driving for a year and beyond without making a
          claim. You get a discount on your premium.`
    },
    {
      question: ' What is Excess or Deductibles?',
      answer: `Insurance excess form the amount which the insured will have
          to pay from his own pocket before the insurer deals with the
          remaining amount. This is typically used in order to deter
          large number of claims The Excess amount can however be
          reduced by paying an increased premium. The process is
          called excess buyback.`
    }
  ];

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const makeRequest = productName => {
    if (productName === 'Motor Insurance') {
      // setOpenFirstModal(true)
      navigate('/animateV2');
    } else if (productName === 'Home Owner Insurance')
      navigate('/HomeRequestsLanding');
  };

  const navigateFromModal = action => {
    console.log(action.id);
    setOpenFirstModal(false);

    if (action.id === 2) {
      navigate('/animateV2');
    } else {
      navigate('/upload-document');
    }
  };

  const navigation = data => {
    handleClose();
    if (data) {
      localStorage.setItem(
        'auth',
        JSON.stringify({
          ...data.insert_motor_request_detail_one.policy_request.customer,
          token:
            data.insert_motor_request_detail_one.policy_request.customer.id,
          usertype: 'User'
        })
      );
      SuccessNotification(
        'Your request was saved successfully.Login to check your request'
      );
    }
  };


  const performActions = data => {
    if (data.customers.length === 0 && data.proxies.length === 0) {
      navigate('/selectuser');
    } else if (data.customers[0]?.active == true) {
      navigate('/customer');

      dispatch({
        type: 'FETCH_CUSTOMERS',
        payload: {}
      });
    } else {
      navigate('/intermediary');
      dispatch({
        type: 'FETCH_CUSTOMERS',
        payload: {}
      });
    }
  };

  const [Login] = useLazyQuery(LOGIN_USER, {
    onCompleted: data => {
      if (data.customers?.length !== 0) {
        let jsonString = JSON.stringify(data?.customers[0]);
        localStorage.setItem('user', jsonString);
        localStorage.setItem('userType', 'Customer');
        performActions(data);
      } else {
        let jsonString = JSON.stringify(data?.proxies[0]);
        localStorage.setItem('user', jsonString);
        localStorage.setItem('userType', 'Proxy');
        if (data.proxies[0]?.active === false) {
          navigate('/not-verified');
        } else {
          performActions(data);
        }
      }
    }
  });

  const login = email => {
    try {
      Login({
        variables: {
          _email: [email],
          _proxyEmail: [email]
        }
      });
    } catch (error) {}
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen(prevState => !prevState);
  };
  const closeDrawer = () => {
    setIsOpen(false);
  };

  const actions = [
    {
      id: 1,
      action: 'Upload Documents',
      icon: <BsCloudUpload size={45} />,
      help_text:
        'Upload the required documents to create your request and get a quote.',
      actionMethod: navigateFromModal
    },

    {
      id: 2,
      action: 'Fill Form',
      icon: <HiOutlinePencil size={45} />,
      help_text:
        'Fill out the quick form with the required details to make your request',
      actionMethod: navigateFromModal
    }
  ];
  const modal2 = () => {
    return (
      <Modal
        show={openFirstModal}
        onHide={handleClose2}
        animation={true}
        size="md"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
          <button
            onClick={() => handleClose2()}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: 20,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {actions?.map(action => (
              <div
                onClick={() => action.actionMethod(action)}
                className="col-xl-6 col-md-6 "
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  alignContent: 'center',
                  paddingTop: 20,
                  margin: 25,
                  borderRadius: 30,
                  backgroundColor: '#F6F8FB',
                  cursor: 'pointer'
                }}
              >
                <div style={{ marginTop: 20 }}>{action.icon}</div>
                <p
                  style={{
                    fontSize: 17,
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }}
                >
                  {action.action}
                </p>
                <p
                  style={{ fontSize: 12, color: 'black', textAlign: 'center' }}
                >
                  {action.help_text}
                </p>
              </div>
            ))}
          </div>
          <div
            style={{
              position: 'absolute',
              left: 165,
              marginTop: -265,
              fontWeight: 'bold'
            }}
          >
            <span>OR</span>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <div>
      {loading ? (
        <Spinner
          style={{
            position: 'relative',
            left: '35%',
            marginTop: '20%'
          }}
          animation="border"
        ></Spinner>
      ) : (
        <>
          {modal2()}
          <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction="right"
            className="bla bla bla"
            zIndex="999"
            size={375}
            style={{
              backgroundColor: '#0E3E86',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 23
              }}
            >
              <X
                onClick={() => closeDrawer()}
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#0E3E86',
                  borderRadius: '50%'
                }}
                size={30}
              ></X>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 10,
                marginTop: 26
              }}
            >
              <a className="side-menu-button default-link-state font-loader">
                <Link
                  onClick={toggleDrawer}
                  to="howitworks"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  How it works
                </Link>
              </a>
              <a className="side-menu-button default-link-state font-loader">
                <Link
                  onClick={toggleDrawer}
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Get a Quote
                </Link>
              </a>
              <a className="side-menu-button default-link-state font-loader">
                <Link
                  onClick={toggleDrawer}
                  to="faqs"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  FAQs
                </Link>
              </a>
              <Button
                onClick={() => loginWithRedirect()}
                className="side-menu-button font-loader"
              >
                Login
              </Button>
              <Button
                onClick={() => navigate('/selectuser')}
                className="side-menu-button font-loader"
              >
                Sign Up
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 20,
                margin: 20,
                marginTop: 180,
                fontWeight: 'bold'
              }}
            >
              <span
                className="font-loader"
                style={{
                  color: '#FFFFFF',
                  fontSize: '14px'
                }}
              >
                ©2022 MyPolicy.market, All Rights Reserved
              </span>
              <span
                className="font-loader"
                style={{
                  color: '#FFFFFF',
                  fontSize: '14px'
                }}
              >
                Privacy Policy • Cookie Policy
              </span>
            </div>
          </Drawer>
          <div
            id="header"
            style={{
              backgroundColor: '#FFFFFF',
              display: 'flex',
              padding: 15,
              justifyContent: 'space-between',
              width: '100%'
            }}
            className="fixed-top"
          >
            <div>
              <a href="/">
                <img
                  style={{ height: '28px', width: '259.84px' }}
                  src={myPolicyLogo}
                />
              </a>
            </div>
            <Button
              onClick={toggleDrawer}
              style={{ backgroundColor: '#FFFFFF', borderColor: '#FFFFFF' }}
            >
              <Menu style={{ color: '#0E3E8699' }} />
            </Button>
          </div>
          <section id="services" className="services section-bg">
            <div
              style={{
                padding: 25,
                paddingBottom: 50
              }}
              className=""
              data-aos="fade-up"
            >
              <div className="main-title">
                <h2 className="font-loader">
                  <p>All Your Insurance,</p>
                  <p>In One Place</p>
                </h2>
                <span
                  className="font-loader"
                  style={{
                    color: '#0E3E8699',
                    fontSize: '1.5rem'
                  }}
                >
                  Select an insurance type below and get a quote that works for
                  you!
                </span>
              </div>
              <div className="step-container row">
                {/* {policyloading ? (
                  <Spinner
                    style={{
                      position: 'relative',
                      left: '0%',
                      marginTop: '15%'
                    }}
                    animation='border'
                  ></Spinner>
                ) : ( */}
                <>
                  {imgArray?.map(policy => (
                    <div
                      className="col-xl-3 col-md-6"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                    >
                      <div
                        onClick={() => makeRequest(policy.name)}
                        style={{ cursor: 'pointer', marginBottom: 15 }}
                        data-aos="zoom-in"
                        data-aos-delay="100"
                      >
                        <div
                          style={{
                            height: '240px',
                            width: '240px',
                            textAlign: 'center',
                            border: '#0E3E8633 solid 1px',
                            opacity: !policy.state ? 0.5 : 1
                          }}
                          className="icon-box"
                        >
                          <div className="icon">
                            <i></i>
                          </div>
                          <img
                            style={{
                              height: '100px',
                              width: '100px',
                              marginBottom: 15
                            }}
                            src={policy.picture}
                          />
                          <h4
                            className="font-loader"
                            style={{
                              fontSize: '20px',
                              color: 'black'
                            }}
                          >
                            <span className="font-loader">{policy.name}</span>
                          </h4>
                        </div>
                      </div>
                      {!policy.state && (
                        <div
                          className="coming-soon-parent-web"
                          data-aos="zoom-in"
                          data-aos-delay="100"
                        >
                          <div className="coming-soon-web">
                            <span className="font-loader">{'Coming Soon'}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '5px',
                paddingBottom: '15px'
              }}
            >
              <Button
                style={{
                  borderRadius: '50%',
                  height: '76px',
                  width: '76px',
                  backgroundColor: '#FFFFFF',
                  color: '#2E79E9',
                  border: '#0E3E8633 1px solid'
                }}
                onClick={executeScroll}
              >
                <ChevronDown
                  style={{
                    width: '36px',
                    height: '18px'
                  }}
                />
              </Button>
            </div>
          </section>

          <section
            id="howitworks"
            ref={myRef}
            style={{
              position: 'relative',
              backgroundColor: '#FFFFFF',
              paddingBottom: '20%'
            }}
          >
            <div
              style={{
                margin: '0px',
                paddingBottom: '48px',
                padding: 0
              }}
              className="section-title"
            >
              <h2
                className="font-loader"
                style={{
                  fontSize: '64px'
                }}
              >
                <p>How it works</p>
              </h2>
            </div>
            <div className="step-container row">
              <>
                {stepArray?.map(step => (
                  <div>
                    <div
                      className="step col-xl-3 col-md-6 d-flex align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className=" icon-box">
                        <img className="step-img" src={step.img} />
                        <br />
                        <div className="text-container">
                          <p className="step-caption font-loader">
                            {step.caption}
                          </p>
                          <p className="step-desc font-loader">{step.desc}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            </div>
          </section>
          <section className="screen-section-container">
            <img className="screen-img" src={screen_1} />

            <div className="text-button-container">
              <h4 className="click-away">You're just one click away!</h4>
              <Button className="get-quote-btn font-loader">Get a quote</Button>
            </div>
          </section>
          <section
            id="faqs"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 25
            }}
          >
            <h4
              className="font-loader"
              style={{
                color: '#001027',
                fontSize: '64px',
                fontWeight: 'bold',
                paddingBottom: '5%'
              }}
            >
              FAQs
            </h4>

            <div
              style={{
                alignSelf: 'stretch'
              }}
            >
              <>
                {faqs.map(faq => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <span
                          className="font-loader"
                          style={{
                            color: '#001027',
                            fontSize: '20px'
                          }}
                        >
                          {faq.question}
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <span
                          className="font-loader"
                          style={{
                            fontSize: 15
                          }}
                        >
                          {faq.answer}
                        </span>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </>
            </div>
          </section>
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              padding: 25,
              backgroundColor: '#001027'
            }}
          >
            <img className="footer-img" src={myPolicyLogo_white} />

            <p
              style={{
                color: '#FFFFFF',
                marginTop: 10,
                fontSize: 14
              }}
            >
              mypolicy.market is an insurance management platform that finds the
              best rates for
              <br />
              your needs, by connecting you with trusted brokers across the
              country. <br />
              <br />
              With an account, you can manage and renew all your insurance
              coverages in one place.
            </p>

            <h4
              style={{
                color: '#FFFFFF',
                fontSize: 32
              }}
            >
              For General Enquiries
            </h4>
            <span
              style={{
                color: '#FFFFFF',
                fontSize: '14px'
              }}
            >
              +233 (0) 200000000 <br />
              info@mypolicy.market
            </span>
            <br />
            <br />
            <div
              style={{
                border: 'solid #FFFFFF 2px',
                width: '100%'
              }}
            ></div>
            <br />
            <br />
            <span
              style={{
                color: '#FFFFFF',
                fontSize: '14px'
              }}
            >
              ©2022 MyPolicy.market, All Rights Reserved
            </span>
            <span
              style={{
                color: '#FFFFFF',
                fontSize: '14px'
              }}
            >
              Privacy Policy • Cookie Policy
            </span>
          </section>

          {/* <div id="preloader"></div> */}
          <a
            href="#"
            className="back-to-top d-flex align-items-center justify-content-center"
          >
            <i className="bi bi-arrow-up-short"></i>
          </a>
        </>
      )}
    </div>
  );
};

export default LandingPageMobile;
