import React, { Component, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from './Constants';
import { GET_VEHICLE_INFO } from 'src/Graphql/Queries';
import { gql, useMutation, throwServerError } from '@apollo/client';
import { ContentPasteSearchOutlined } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import { encryptText, decryptText } from './utils/Encryption';

const Context = React.createContext();

class Provider extends Component {
  reducer = (state, action) => {
    switch (action.type) {
      case 'SIGN_IN':
        return {
          ...state,
          user: action.payload,
          error: action.error,
          isLoggedIn: action.payload ? true : false
        };

      case 'IS_FIRST_TIME':
        return {
          ...state,
          user: { ...state.user, isfirstTime: false }
        };

      case 'LOGOUT':
        return {
          ...state,
          user: null,
          error: null,
          isLoggedIn: false
        };

      case 'FETCH_CUSTOMERS':
        this.getCustomerProfile();
        return {
          ...state
        };

      case 'FETCH_UPDATED_CUSTOMER_DETAILS':
        this.refetchCustomerDetails(this.state.user.id);
        return {
          ...state
        };

      case 'UPDATE_CUSTOMER_PROFILE':
        return {
          ...state,
          customer_profile: action.payload
        };

      case 'SET_REDEMPTION_DETAIL':
        return {
          ...state,
          redemptionDetail: action.payload
        };

      case 'FETCH_MESSAGES':
        this.fetchallNotification(action.payload);
        return {
          ...state
        };

      case 'UPDATE_MESSAGES':
        this.removeNotificationItem(action.payload);
        return {
          ...state
        };

        case 'READ_MESSAGES':
          // this.readNotification(action.payload.message_id)
          this.fetchallNotification(action.payload.user_id)
          return{
            ...state
          }

      case 'FETCH_PRODUCT_BENEFIT':
        this.fetchProductBenefits(action.payload);
        return {
          ...state
        };

      case 'REMOVE_ALL_NOTIFICATIONS':
        this.removeAllNotification();
        return {
          ...state
        };

      case 'SET_PAGE_HEADER':
        this.setPageHeader(action.payload);
        return {
          ...state
        };

      case 'FETCH_POLICY':
        this.fetchCustomerPolicy(action.payload);
        return {
          ...state
        };

      case 'FETCH_CUSTOMER_TRANSACTION':
        this.fetchCustomerTransactions(action.payload);
        return {
          ...state
        };

      case 'PULL_ALL_CUSTOMER_DATA':
        this.getCustomerProfile();
        this.fetchallNotification(action.payload.user_id);
        // this.fetchCustomerPolicy(action.payload.user_id)
        // this.getprofileImage(action.payload.image_url)
        // this.fetchCustomerTransactions(action.payload.user_id)
        // this.fetchCustomerDetails(action.payload.id, action.payload.user_id)
        return {
          ...state
        };

      case 'SET_LOGIN':
        this.setState({ isLoggedIn: true });
        return{
          ...state
        };

      case 'PULL_ALL_BROKER_DATA':
        this.fetchPolicySummary(action.payload.token);
        this.fetchallNotification(action.payload.user_id);
        return {
          ...state
        };

      case 'UPDATE_PROFILE_PIC':
        this.getprofileImage(action.payload);
        return {
          ...state
        };

      default:
        return state;
    }
  };
  state = {
    pageHeader: '',
    user: null,
    error: '',
    profile_pic: '',
    profile_pic_loading: false,
    isLoggedIn: false,
    customer_profile: null,
    customerdetailsLoading: false,
    notification: null,
    policyloading: true,
    answeredRequest: null,
    insurance_products: null,
    customerPolicies: null,
    vehicle_makes: [],
    isAuthenticated: false,
    allMessages: [],
    unreadMessages: [],
    dispatch: action => {
      this.setState(state => this.reducer(state, action));
    }
  };

  setPageHeader = header => {
    this.setState({
      pageHeader: header
    });
  };

  async componentDidMount() {
    try {
      this.getCustomerProfile();
      // this.fetchVehicleMakes();
    } catch (error) {}
  }

  removeNotificationItem = id => {
    var lists = this.state.allMessages.filter(x => {
      return x.id != id;
    });

    this.setState({ allMessages: lists });
  };

  removeAllNotification = () => {
    this.setState({ allMessages: [], unreadMessages: [] });
  };

  updateUser = data => {
    this.setState({ user: data });
  };

  getCustomerProfile = () => {
    const user = JSON.parse(localStorage.user);
    // console.log(user)
    if (localStorage.userType === 'Customer') {
      let newuser = {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        phone_number: user.phone_numbers[0],
        email: user.emails[0]
        // user_id: user.id
      };

      this.setState({ user: newuser });
    } else {
      let newuser = {
        id: user.id,
        name: user.name,
        digital_address: user.digital_address,
        phone_number: user.phone_numbers[0],
        email: user.emails[0],
        country: user.country,
        proxy_type: user.type,
        system_commission: user.system_commission
      };

      this.setState({ user: newuser });
    }
  };

  refetchCustomerDetails = id => {
    this.props.client
      .query({
        query: gql`
          query getCustomerByID($id: uuid) {
            customers(where: { id: { _eq: $id } }) {
              first_name
              emails
              last_name
              phone_numbers
            }
          }
        `,
        variables: {
          id: id
        }
      })
      .then(json => {
        let updatedUser = {
          id: id,
          first_name: json.data.customers[0].first_name,
          last_name: json.data.customers[0].last_name,
          phone_number: json.data.customers[0].phone_numbers,
          email: json.data.customers[0].emails
        };

        this.setState({ user: updatedUser });
      });
  };

  // fetchVehicleMakes = () => {
  //   this.props.client
  //     .query({
  //       query: gql`
  //       query getVehicleMakes {
  //         references(where: { is_parent: { _eq: true } }) {
  //           id
  //           name
  //         }
  //       }
  //       `,
  //     })
  //     .then(json => {
  //       this.setState({ vehicle_makes: json.data.references })
  //     })
  // }

  fetchallNotification = id => {
    this.props.client
      .query({
        query: gql`
          query getNotificationsByCustomerID($_customerID: uuid) {
            notifications(where: { customer_id: { _eq: $_customerID } }) {
              id
              message_short
              is_read
              type
              request_id
              created_at
              subject
            }
          }
        `,
        variables: {
          _customerID: id
        }
      })
      .then(json => {
        let read = [];
        let unread = [];
        this.setState({ allMessages: json.data.notifications });

        for (let i = 0; i < json.data.notifications.length; i++) {
          if (json.data.notifications[i].is_read === false) {
            unread.push(json.data.notifications[i]);
          } else {
            read.push(json.data.notifications[i]);
          }
        }
        this.setState({ unreadMessages: unread.slice() });
      });
  };



  fetchCustomerDetails = user_id => {
    this.setState({ customerdetailsLoading: true });
    this.props.client
      .query({
        query: gql`
          query fetchUserByID($_userID: uuid) {
            customers(where: { id: { _eq: $_userID } }) {
              first_name
              last_name
              emails
              phone_numbers
              status
              image
            }
          }
        `,
        variables: {
          _userID: user_id
        }
      })
      .then(json => {
        this.setState({ customerdetailsLoading: false });
        const user = json.data.customers;
        let u = {
          first_name: user?.first_name,
          last_name: user?.last_name,
          phone_number: user?.phone_numbers,
          email: user?.user.emails,
          photo: user?.image,
          user_id: user_id
        };
        this.setState({ customer_profile: u });
      });
  };

  getprofileImage = filename => {
    var m = this;
    m.setState({ profile_pic_loading: true });
    var config = {
      method: 'get',
      url: `https://backend.staging.mypolicy.market/getfile/?bucketname=althia&itemname=${filename}`,
      headers: {
        accept: 'application/json'
      }
    };

    axios(config)
      .then(function(response) {
        m.setState({ profile_pic_loading: false });

        m.setState({ profile_pic: response.data.message.download_url });
        // localStorage.setItem("profile_pic",response.data.message.download_url)
      })
      .catch(function(error) {
        console.log(error);
        m.setState({ profile_pic_loading: false });
      });
  };

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}
const useAppContext = () => useContext(Context);

export { Provider, useAppContext };
