import { useState, useEffect } from 'react';
import TableRows from './TableRow'
import { BsPlusCircle } from 'react-icons/bs'
import { SuccessNotification } from 'src/components/Notification'
import WebFont from 'webfontloader'
import "../../AnimatedCard/styles.css";

function AddDeleteTablePeopleRows ({ name, label, setContents }) {
  const [rowsData, setRowsData] = useState([]);  

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    })
  }, [])

  const addTableRows = () => {
    const rowsInput = {
      name: '',
      occupation: '',
      sum_assured: '',
      date_of_birth: ''
    }
    setRowsData([...rowsData, rowsInput])
  }
  const deleteTableRows = index => {
    const rows = [...rowsData]
    rows.splice(index, 1)
    setRowsData(rows)
  }

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target
    const rowsInput = [...rowsData]
    rowsInput[index][name] = value
    setRowsData(rowsInput)
  }
  return (
    <div className='container font-loader' style={{ padding: 10 }}>
      <div style={{ paddingBottom: 10 }}>
        {label} (Remember to press save after adding your persons)<span style={{ color: 'red' }}>*</span>
      </div>
      <div className='row'>
        <div className='col-sm-8'>
          <table className='table'>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Occupation</th>
                <th>Sum Assured</th>
                <th>Date of birth</th>
              </tr>
            </thead>
            <tbody>
              <TableRows
                rowsData={rowsData}
                deleteTableRows={deleteTableRows}
                handleChange={handleChange}
              />
            </tbody>
          </table>
          <div style={{ marginLeft: 20, display: 'flex' }}>
            <button
              className='btn btn-outline-primary'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClick={(e) => {
                e.preventDefault()
                addTableRows()
              }}
            >
              {<BsPlusCircle />}{' '}
              <span style={{ marginLeft: 3, paddingTop: 5 }}>Add new line</span>
            </button>

            <button
              style={{ marginLeft: 10 }}
              className='btn btn-outline-success'
              onClick={(e) => {
                e.preventDefault()
                setContents(name, rowsData);
                SuccessNotification('All your people have been saved');
              }}
            >
              <span style={{ marginLeft: 3, paddingTop: 5 }}>Save</span>
            </button>
          </div>
        </div>
        <div className='col-sm-4'></div>
      </div>
    </div>
  )
}
export default AddDeleteTablePeopleRows
