

export const formatCurrency = (premium) => {
    let result = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'GHS' }).format(premium);
    return result;
}

export const escapeRegExp = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
} 

export const convertWildcardStringToRegExp = (expression) => {
    let terms = expression.split('*');

    let trailingWildcard = false;

    let expr = '';
    for (let i = 0; i < terms.length; i++) {
        if (terms[i]) {
            if (i > 0 && terms[i - 1]) {
                expr += '.*';
            }
            trailingWildcard = false;
            expr += escapeRegExp(terms[i]);
        } else {
            trailingWildcard = true;
            expr += '.*';
        }
    }

    if (!trailingWildcard) {
        expr += '.*';
    }

    return new RegExp('^' + expr + '$', 'i');
}