import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import WebFont from 'webfontloader'
import './styles.css'

const FileInput = ({
  label,
  placeholder,
  handleChange,
  name,
  defaultValue,
//   value,
  style,
  onInput,
  disabled,
  onBlur
}) => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    })
  }, [])
  return (
    <>
      <Form.Group>
        <Form.Label className='font-loader'>
          {label}
          <span style={{ color: 'red' }}>*</span>
        </Form.Label>

        <Form.Control
          // className='font-loader form-control'
          onChange={handleChange}
          type={'file'}
          placeholder={placeholder}
          name={name}
        //   defaultValue={defaultValue}
        //   value={value}
          style={style}
        //   onInput={onInput}
          disabled={disabled}
          onBlur={onBlur}
        //   min={type === 'number' ? '0' : null}
        ></Form.Control>
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        <Form.Control.Feedback type='invalid'>
          Field must not be empty
        </Form.Control.Feedback>
      </Form.Group>
    </>
  )
}

export default FileInput
