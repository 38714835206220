import * as React from 'react';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/ShoppingCart';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';

export default function SimpleBadge({msgCounter}) {
 
  return (
    <Badge badgeContent={msgCounter>0 ? msgCounter.toString() : null} color="error">
      <NotificationsIcon style={{color:"white",fontSize:20}} color="action" />
    </Badge>
  );
}
