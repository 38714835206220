import React, { useState, useEffect } from 'react';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import { SIGN_UP_PROXY } from 'src/Graphql/Mutations';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  ErrorNotification,
  SuccessNotification
} from 'src/components/Notification';
import axios from 'axios';
import '../MobileStyles.css';
import '../auth.css';
import { Form } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import WebFont from 'webfontloader';
import { useAuth0 } from '@auth0/auth0-react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

const GET_COUNTRIES = gql`
  query getCountries {
    countries {
      id
      name
    }
  }
`;

const GET_PROXY_BY_EMAIL = gql`
  query getProxyByEmail($email: jsonb) {
    proxies(where: { emails: { _eq: $email } }) {
      id
    }
  }
`;

const IntermediarySignup = ({ changeUser }) => {
  const [auth0loading, setLoading] = useState(false);
  const [userType, setuserType] = useState('agent');
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState(null);

  const { loginWithRedirect } = useAuth0();

  const { data: CountriesData } = useQuery(GET_COUNTRIES);
  const [getProxyByEmail] = useLazyQuery(GET_PROXY_BY_EMAIL, {
    onCompleted: data => {
      if (data.proxies?.length > 0) {
        ErrorNotification('Sorry email belongs to existing user');
      } else {
        Signupbroker();
      }
    }
  });

  const checkUser = email => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/auth/check-user?email=${email}`
      )
      .then(response => {
        if (response.data.data === email) {
          getProxyByEmail({
            variables: {
              email: [email]
            }
          });
        } else {
          createToken();
        }
      });
  };

  const confirmSubmission = () => {
    Swal.fire({
      title: 'Are you sure you want to submit this request?',
      text: 'You want be able to revert this',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, accept',
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        checkUser(formik.values.email);
      }
    });
  };

  const FILE_SIZE = 10000000;
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/pdf'
  ];

  const toDataURL = image => {
    return URL.createObjectURL(image);
  };

  const formik = useFormik({
    initialValues: {
      intermediary_type: 'AGENT',
      name: '',
      email: '',
      licence_document: null,
      agreement_document: null,
      // license_number: '',
      phone_number: '',
      // token: '',
      // is_file_chosen: '',
      physical_address: '',
      // registration_doc: null,
      postal_address: '',
      digital_address: '',
      // insurance_companies: null,
      password: '',
      confirmpassword: '',
      country: ''
    },

    validationSchema: Yup.object({
      name: Yup.string().required('Please enter fullname'),
      email: Yup.string()
        .email('Enter valid email')
        .required('Please enter an email'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/,
          'Should contain at least one Upper Case, one Lower Case, one number and a special character'
        )
        .required('Please enter a password'),
      phone_number: Yup.string()
        .max(10, 'Must be 10 numbers or less')
        .matches(/[0-9]{3}[0-9]{3}[0-9]{4}/, 'Invalid phone number')
        .required('Please enter a phone number'),

      // license_number: Yup.string().required(
      //   'Please enter your brokerage licence number'
      // ),
      licence_document: Yup.mixed()
        .required('Please upload your licence document')
        .test(
          'fileSize',
          'File is too large',

          value => value && value.size <= FILE_SIZE
        )
        .test(
          'fileFormat',
          'Unsupported Format! we only support (jpg, jpeg, png, pdf)',
          value => !value || (value && SUPPORTED_FORMATS.includes(value.type))
        ),
      physical_address: Yup.string().required(
        'Please enter your physical address'
      ),
      agreement_document: Yup.mixed()
        .required('Please upload your Brokerage/Agency agreement document')
        .test(
          'fileSize',
          'File is too large',

          value => value && value.size <= FILE_SIZE
        )
        .test(
          'fileFormat',
          'Unsupported Format! we only support (jpg, jpeg, png, pdf)',
          value => !value || (value && SUPPORTED_FORMATS.includes(value.type))
        ),
      // .test(
      //   "fileSize",
      //   "File too large",
      //   (value) => !value || (value && value.size <= FILE_SIZE)
      // )
      // .test(
      //   "fileFormat",
      //   "Unsupported Format",
      //   (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
      // insurance_companies: Yup.string()
      //   .when('userType', {
      //     is: userType => userType === 'AGENT',
      //     then: Yup.string().required('Please select your insurance company')
      //   })
      //   .nullable(),
      postal_address: Yup.string().required('Please enter your postal address'),
      digital_address: Yup.string()
        .required('Please enter a digital address')
        .matches(
          /^[A-Z]{2}-[0-9]{3,4}-[0-9]{4}$/,
          'Please enter a valid digital address'
        ),
      confirmpassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required')
    }),

    onSubmit: values => {
      // licenceStatus.then(res => console.log(res))

      // if (licenceStatus.status === 200 && agreementStatus.status === 200) {
      confirmSubmission();
      // console.log(values['licence_document'].type)
      // console.log(values)
      // console.log(licenceStatus.data)
      // console.log(agreementStatus.data)
      // }
    }
  });

  const createToken = () => {
    var data = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      audience: process.env.REACT_APP_AUDIENCE,
      grant_type: process.env.REACT_APP_GRANT_TYPE
    };
    // e.preventDefault();
    setLoading(true);
    var config = {
      method: 'post',
      url: process.env.REACT_APP_AUTH0_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function(response) {
        const token = response.data.access_token;
        localStorage.setItem('config_token', token);
        // onFileUpload(token, values)
        signup0(token);
      })
      .catch(function(error) {
        console.log(error);
        setLoading(false);
      });
  };

  // function checkWord (sentence) {
  //   if (sentence.indexOf('brokers_phone_number_key') >= 0) {
  //     ErrorNotification('User with this phone number already exist')
  //   } else if (sentence.indexOf('brokers_email_key') >= 0) {
  //     ErrorNotification('User with this email already exist')
  //   } else {
  //     ErrorNotification('An error occurred while creating user')
  //   }
  // }

  const signup0 = async token => {
    const t = localStorage.getItem('config_token');
    if (formik.values.confirmpassword !== formik.values.password) {
      setError(true);
    } else {
      setLoading(true);

      var data = JSON.stringify({
        connection: process.env.REACT_APP_AUTH0_CONNECTION_TYPE,
        email: formik.values.email,
        password: formik.values.password
      });

      var config = {
        method: 'post',
        url: process.env.REACT_APP_AUTH0_USERS_URL,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function(response) {
          setLoading(false);
          if (response.data.user_id && response.status === 201) {
            Signupbroker();
          }
        })
        .catch(function(error) {
          setLoading(false);
          console.log(error.response);
          ErrorNotification('User with this email already exist');
        });
    }
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    });
  }, []);

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = '';
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        baseURL = reader.result.replace('data:', '').replace(/^.+,/, '');

        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const uploadFile = async (fieldIdentifier, file, reference_id) => {
    let ext = file.type.slice(-3);

    return getBase64(file).then(res => {
      const body = {
        key: `${fieldIdentifier}.${ext}`,
        type: 'registration_document',
        contents: res,
        reference_id: reference_id,
        properties: file.type,
        created_by: {
          id: reference_id,
          username: formik.values.name,
          name: formik.values.name
        }
      };

      return axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/files/upload`,
        body
      );
    });
  };

  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Password should contain at least one uppercase letter, lowercase letter, a
      number and a special character
    </Tooltip>
  );

  const register = (newName, values) => {
    let comp = [];
    for (var i = 0; i < selectedOption.length; i++) {
      let m = {
        insurance_company_id: selectedOption[i]['value']
      };
      comp.push(m);
    }

    Signupbroker({
      variables: {
        name: values.name,
        phone_numbers: [values.phone_number],
        emails: [values.email],
        created_by: 'self',
        digital_address: '',
        type: '',
        country: '',
        license_document: newName,
        license_number: values.license_number,
        address_line_2: values.physical_address,
        address_line_1: values.postal_address,
        digital_address: values.digital_address,
        intermediary_type: userType,
        insurance_companies: comp
      },

      onCompleted: data => {
        SuccessNotification(
          'Your account has been successfully created. We will contact you when your account is activated'
        );
        navigate('/not-verified');
      },

      onError: error => {
        ErrorNotification('Sign up failed');
        console.log(error);
      }
    });
  };

  const [
    Signupbroker,
    { loading: SignUploding, error: SignUpError }
  ] = useMutation(SIGN_UP_PROXY, {
    variables: {
      name: formik.values.name,
      phone_numbers: [formik.values.phone_number],
      emails: [formik.values.email],
      created_by: 'self',
      digital_address: formik.values.digital_address,
      type: userType,
      country: formik.values.country,
      // license_document: newName,
      // license_number: formik.values.license_number,
      address_line_2: formik.values.physical_address,
      address_line_1: formik.values.postal_address,
      digital_address: formik.values.digital_address
      // insurance_companies: comp
    },

    onCompleted: async data => {
      let licenceStatus = await uploadFile(
        `${formik.values.name}_Licence_Document`,
        formik.values.licence_document,
        data.insert_proxies_one.id
      );
      let agreementStatus = await uploadFile(
        `${formik.values.name}_Agreement_Document`,
        formik.values.agreement_document,
        data.insert_proxies_one.id
      );

      if (licenceStatus.data.data.id && agreementStatus.data.data.id) {
        SuccessNotification(
          'Your account has been successfully created. We will contact you when your account is activated'
        );
        navigate('/not-verified');
      }
    },

    onError: error => {
      ErrorNotification('Sign up failed');
      console.log(error);
    }
  });



  const handleUser = e => {
    setuserType(e.target.value);
  };

  const addCompany = obj => {
    let m = [];
    m.push(obj);
    setSelectedOption(m);
    formik.setFieldValue('insurance_companies', m);
  };

  // useEffect(() => {
  //   if (data) {
  //     let m = []
  //     for (var i = 0; i < data.insurance_company.length; i++) {
  //       let v = {
  //         value: data.insurance_company[i].id,
  //         label: data.insurance_company[i].name
  //       }

  //       m.push(v)
  //     }

  //     setSelectedOption(m)
  //     setCompanies(m)
  //     setAPIData(data.insurance_company)

  //   }
  // }, [data])

  const nav = [
    {
      id: 1,
      component: 'Customer'
    }
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        justifyContent: 'center'
        // overflowY:"auto"
      }}
    >
      <div className="customer-change-link-container">
        <div className="customer-change-link">
          {nav.map((item, index) => (
            <ul id="horizontal-list">
              <li
                className="font-loader"
                style={{ cursor: 'pointer' }}
                onClick={() => changeUser(item.component)}
              >
                Not an Intermediary? Sign Up as a {item.component}
              </li>
            </ul>
          ))}
        </div>
      </div>
      <div className="form-heading-container">
        <h1 className="sign-up-title font-loader">Intermediary Sign Up</h1>
      </div>

      <Form onSubmit={formik.handleSubmit}>
        <div className="form-class">
          <div className="form-item">
            <label className="font-loader">
              Select Intermediary Type<span className="star">*</span>
            </label>
            <select
              class="form-control"
              name="intermediary_type"
              style={{ textAlign: 'left' }}
              onInput={e => handleUser(e)}
              onChange={formik.handleChange}
            >
              <option className="font-loader" value="agent">
                Agent (Individual/Agency)
              </option>
              <option className="font-loader" value="broker">
                Broker
              </option>
            </select>
          </div>

          <Form.Group className="form-item">
            <Form.Label className="font-loader">
              Intermediary Name
              <span className="star">*</span>
            </Form.Label>
            <Form.Control
              style={{
                borderColor:
                  formik.touched.name && formik.errors.name ? 'red' : null
              }}
              onChange={formik.handleChange}
              type="text"
              onBlur={formik.handleBlur}
              name="name"
              class="form-control"
            />

            {formik.touched.name && formik.errors.name && (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors.name}
              </p>
            )}
          </Form.Group>

          <Form.Group className="form-item">
            <Form.Label className="font-loader">
              Phone Number<span className="star">*</span>
            </Form.Label>
            <Form.Control
              style={{
                borderColor:
                  formik.touched.phone_number && formik.errors.phone_number
                    ? 'red'
                    : null
              }}
              name="phone_number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              class="form-control"
            />
            {formik.touched.phone_number && formik.errors.phone_number && (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors.phone_number}
              </p>
            )}
          </Form.Group>
          <Form.Group className="form-item">
            <Form.Label className="font-loader">
              Country<span className="star">*</span>
            </Form.Label>
            <select
              class="form-control col-md-12"
              style={{ textAlign: 'left' }}
              onChange={formik.handleChange}
              name="country"
            >
              <option value={null}>Select country of origin</option>
              {CountriesData?.countries?.map(country => (
                <option value={country.name}>{country.name}</option>
              ))}
            </select>

            {formik.touched['country'] && formik.errors['country'] ? (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors['country']}
              </p>
            ) : null}
          </Form.Group>
          <Form.Group className="form-item">
            <Form.Label className="font-loader">
              Digital Address<span className="star">*</span>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              class="form-control"
              name="digital_address"
              id="digital_address"
              style={{
                borderColor:
                  formik.touched['digital_address'] &&
                  formik.errors['digital_address']
                    ? 'red'
                    : null
              }}
            />
            {formik.touched['digital_address'] &&
            formik.errors['digital_address'] ? (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors['digital_address']}
              </p>
            ) : null}
          </Form.Group>
          <Form.Group className="form-item">
            <Form.Label className="font-loader">
              Physical Address<span className="star">*</span>
            </Form.Label>
            <Form.Control
              style={{
                borderColor:
                  formik.touched.physical_address &&
                  formik.errors.physical_address
                    ? 'red'
                    : null
              }}
              name="physical_address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              class="form-control"
            />

            {formik.touched.physical_address &&
              formik.errors.physical_address && (
                <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                  {formik.errors.physical_address}
                </p>
              )}
          </Form.Group>

          <Form.Group className="form-item">
            <Form.Label className="font-loader">
              Postal Address<span className="star">*</span>
            </Form.Label>
            <Form.Control
              style={{
                borderColor:
                  formik.touched.postal_address && formik.errors.postal_address
                    ? 'red'
                    : null
              }}
              name="postal_address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              class="form-control"
            />

            {formik.touched.postal_address && formik.errors.postal_address && (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors.postal_address}
              </p>
            )}
          </Form.Group>
          <Form.Group className="form-item">
            <Form.Label className="font-loader">
              NIC Licence Document<span className="star">*</span>
            </Form.Label>
            <Form.Control
              style={{
                borderColor:
                  formik.touched.licence_document &&
                  formik.errors.licence_document
                    ? 'red'
                    : null
              }}
              name="licence_document"
              onChange={event => {
                formik.setFieldValue('licence_document', event.target.files[0]);
                setSelectedFile(event.currentTarget.files[0]);
              }}
              onBlur={formik.handleBlur}
              type="file"
              class="form-control"
            />
            {formik.touched.licence_document &&
              formik.errors.licence_document && (
                <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                  {formik.errors.licence_document}
                </p>
              )}
          </Form.Group>

          {formik.values.licence_document?.type === 'image/png' ||
          formik.values.licence_document?.type === 'image/jpeg' ||
          formik.values.licence_document?.type === 'image/jpg' ? (
            <div>
              <img
                style={{ border: 'solid', height: 300 }}
                src={toDataURL(formik.values.licence_document)}
                alt="/"
              />
            </div>
          ) : null}

          <Form.Group className="form-item">
            <Form.Label className="font-loader">
              Insurance Brokerage/Agency Agreement
              <span className="star">*</span>
            </Form.Label>
            <Form.Control
              style={{
                borderColor:
                  formik.touched.agreement_document &&
                  formik.errors.agreement_document
                    ? 'red'
                    : null
              }}
              name="agreement_document"
              onChange={event => {
                formik.setFieldValue(
                  'agreement_document',
                  event.target.files[0]
                );
                setSelectedFile(event.currentTarget.files[0]);
              }}
              onBlur={formik.handleBlur}
              type="file"
              class="form-control"
            />
            {formik.touched.agreement_document &&
              formik.errors.agreement_document && (
                <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                  {formik.errors.agreement_document}
                </p>
              )}
          </Form.Group>

          {formik.values.agreement_document?.type === 'image/png' ||
          formik.values.agreement_document?.type === 'image/jpeg' ||
          formik.values.agreement_document?.type === 'image/jpg' ? (
            <div>
              <img
                style={{ border: 'solid', height: 300 }}
                src={toDataURL(formik.values.agreement_document)}
                alt=""
              />
            </div>
          ) : null}

          <Form.Group className="form-item">
            <Form.Label className="font-loader">
              Email<span className="star">*</span>
            </Form.Label>
            <Form.Control
              style={{
                borderColor:
                  formik.touched.email && formik.errors.email ? 'red' : null
              }}
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="email"
              class="form-control"
            />
            {formik.touched.email && formik.errors.email && (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors.email}
              </p>
            )}
          </Form.Group>
          <Form.Group className="form-item">
            <Form.Label className="font-loader">
              Password<span className="star">*</span>
            </Form.Label>

            <Form.Control
              style={{
                borderColor:
                  formik.touched.password && formik.errors.password
                    ? 'red'
                    : null
              }}
              name="password"
              type="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              class="form-control"
            />
            {formik.touched.password && formik.errors.password && (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors.password}
              </p>
            )}
          </Form.Group>

          <Form.Group className="form-item">
            <Form.Label className="font-loader">
              Confirm Password<span className="star">*</span>
            </Form.Label>
            <Form.Control
              style={{
                borderColor:
                  formik.touched.confirmpassword &&
                  formik.errors.confirmpassword
                    ? 'red'
                    : null
              }}
              name="confirmpassword"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              class="form-control"
            />
            {formik.touched.confirmpassword &&
              formik.errors.confirmpassword && (
                <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                  {formik.errors.confirmpassword}
                </p>
              )}
          </Form.Group>
          {/* 
          <Form.Group as={Col} md='6'>
            <Form.Label className='font-loader'>
              License Number<span className='star'>*</span>
            </Form.Label>
            <Form.Control
              style={{
                borderColor:
                  formik.touched.license_number && formik.errors.license_number
                    ? 'red'
                    : null
              }}
              name='license_number'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type='text'
              class='form-control'
            />

            {formik.touched.license_number && formik.errors.license_number && (
              <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                {formik.errors.license_number}
              </p>
            )}
          </Form.Group> */}

          {/* <Form.Group controlId='licenseDoc' as={Col} md='6'>
            <Form.Label className='font-loader'>
              Upload Document (Max Size 1MB)<span className='star'>*</span>
            </Form.Label>
            <Form.Control
              style={{
                borderColor:
                  formik.touched.licence_document &&
                  formik.errors.licence_document
                    ? 'red'
                    : null
              }}
              name='licence_document'
              onChange={event => {
                formik.setFieldValue('licence_document', event.target.files[0])
                setSelectedFile(event.currentTarget.files[0])
              }}
              onBlur={formik.handleBlur}
              type='file'
              class='form-control'
            />
            <small style={{ color: 'lightgray', fontSize: 13, marginTop: 5 }}>
              File upload should not exceed 1MB
            </small>

            {formik.touched.licence_document &&
              formik.errors.licence_document && (
                <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                  {formik.errors.licence_document}
                </p>
              )}
          </Form.Group> */}

          {/* {userType === 'BROKER' ? null : (
            <div class='form-group col-md-6'>
              <label className='font-loader'>
                Select Insurance Partners
                <span style={{ color: 'red' }}> *</span>
              </label>
              <Select
                style={{
                  borderColor:
                    formik.touched.insurance_companies &&
                    formik.errors.insurance_companies
                      ? 'red'
                      : null
                }}
                onChange={e => {
                  addCompany(e)
                }}
                name='insurance_companies'
                options={companies}
                onBlur={formik.handleBlur}
                className='basic-multi-select'
                classNamePrefix='select'
              />
              {formik.touched.insurance_companies &&
                formik.errors.insurance_companies && (
                  <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                    {formik.errors.insurance_companies}
                  </p>
                )}
            </div>
          )} */}
        </div>
        <br></br>
        <div>
          <button
            className="font-loader"
            disabled={SignUploding}
            class="btn btn-primary"
          >
            {SignUploding || auth0loading ? (
              <div class="spinner-border" role="status">
                <span className="font-loader" class="sr-only">
                  Loading...
                </span>
              </div>
            ) : (
              'Create Account'
            )}
          </button>
        </div>
      </Form>
      <br></br>
      <div>
        <p className="font-loader">
          Already have an account ?{' '}
          <span
            className="font-loader"
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              textDecoration: 'underline',
              color: '#0E3E86',
              cursor: 'pointer'
            }}
          >
            <span
              onClick={e => {
                e.preventDefault();
                loginWithRedirect();
              }}
            >
              Log In
            </span>
          </span>
        </p>
      </div>
    </div>
  );
};

export default IntermediarySignup;
