import React, { useState, useEffect } from 'react'
  import Tooltip from 'react-bootstrap/Tooltip'
  import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Form } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import WebFont from 'webfontloader'
import './styles.css'

const FormOptions = ({
  label,
  handleChange,
  type,
  value,
  name,
  options,
  style,
  onInput,
  disabled,
  onBlur,
  id
}) => {
  const [defaultVal, setDefaultVal] = useState('Select an option')

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    })
  }, [])

  // function getData(options, value, name) {

  //   if (name === 'interval') {
  //     let c = options.find(({ days }) => days == value);
  //     return c?.month + 'months(s)';
  //   } else {
  //     let c = options.find(({ id }) => id == value);

  //     return c?.name
  //   }
  // }

  return (
    <>
      {name?.includes('buy_back')  ? (
        <OverlayTrigger
          placement='right'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip} 
        >
          <Form.Group>
            <Form.Label className='font-loader'>
              {label}
              <span style={{ color: 'red' }}>*</span>
            </Form.Label>

            <Form.Control
              className='font-loader'
              as={type}
              type={type}
              name={name}
              // defaultValue={defaultVal}
              id={id}
              onChange={handleChange}
              style={style}
              onInput={onInput}
              disabled={disabled}
              onBlur={onBlur}
              // value={value}
            >
              {/* <option className="font-loader" value={value}>
          {value ? getData(options, value, name) : 'Select an option'}
        </option> */}
              <option className='font-loader' value={'Select an option'}>
                Select an option
              </option>
              {options &&
                options?.map(option => (
                  <option
                    className='font-loader'
                    selected={value == option.id}
                    key={option.id}
                    value={name === 'vehicle_make' ? option.name : option.id}
                  >
                    {option.name ? option.name : option.month + ' Month(s)'}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </OverlayTrigger>
      ) : (
        <Form.Group>
          <Form.Label className='font-loader'>
            {label}
            <span style={{ color: 'red' }}>*</span>
          </Form.Label>

          <Form.Control
            className='font-loader'
            as={type}
            type={type}
            name={name}
            // defaultValue={defaultVal}
            id={id}
            onChange={handleChange}
            style={style}
            onInput={onInput}
            disabled={disabled}
            onBlur={onBlur}
            // value={value}
          >
            {/* <option className="font-loader" value={value}>
          {value ? getData(options, value, name) : 'Select an option'}
        </option> */}
            <option className='font-loader' value={'Select an option'}>
              Select an option
            </option>
            {options &&
              options?.map(option => (
                <option
                  className='font-loader'
                  // selected={value == option.id}
                  key={option.id}
                  value={name === 'vehicle_make' ? option.nam : option.id}
                >
                  {option.name ? option.name : option.month + ' Month(s)'}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
      )}
    </>
  )
}

const renderTooltip = props => {
  return (
    <Tooltip id='button-tooltip' {...props}>
      This means you would pay a higher premium, but you would pay less
      out-of-pocket for a claim.
    </Tooltip>
  )
}

export default FormOptions
