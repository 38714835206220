import { useState, useEffect } from 'react';
import TableRows from './TableRow';
import { BsPlusCircle } from 'react-icons/bs';
import { SuccessNotification } from 'src/components/Notification'
import WebFont from 'webfontloader'
import "../../AnimatedCard/styles.css";


const AddDeleteTableRows = ({ name, label, setContents}) => {
  const [rowsData, setRowsData] = useState([]);

  const addTableRows = () => {
    const rowsInput = {
      name: '',
      quantity: '',
      value: '',
      total_value:''
    };
    setRowsData([...rowsData, rowsInput]);
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    })
  }, [])


  const onMakeInput = (index,e) => {

    const rowsInput = [...rowsData];
    let total=parseInt(rowsInput[index]['quantity'])*e.target.value
    rowsInput[index]['total_value'] = total;
    setRowsData(rowsInput);
  };


  const deleteTableRows = index => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };




  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };
  return (
    <div className="container font-loader" style={{ padding: 10}}>
      <div style={{paddingBottom: 10}}>
        {label} (Remember to press save after adding your items)<span style={{ color: 'red' }}>*</span>
      </div>
      <div className="row ">
        <div className="col-sm-8">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Quantity</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <TableRows
                rowsData={rowsData}
                deleteTableRows={deleteTableRows}
                handleChange={handleChange}
                onMakeInput={onMakeInput}
              />
            </tbody>
          </table>
          <div style={{marginLeft:15, display: 'flex'}}>
            <button style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }} className="btn btn-outline-primary" onClick={(e)=> {
              e.preventDefault();
              addTableRows();
            }}>
              {<BsPlusCircle />}{' '}
              <span style={{ marginLeft: 3,paddingTop:5 }}>Add new line</span>
            </button>

            <button style={{marginLeft:10}} className="btn btn-outline-success" onClick={(e)=>{
              e.preventDefault();
              setContents(name, rowsData);
              SuccessNotification('All your content has been saved');
            }}>
             
              <span style={{ marginLeft: 3,paddingTop:5 }}>Save</span>
            </button>

          </div>
        </div>
        <div className="col-sm-4"></div>
      </div>
    </div>
  );
}
export default AddDeleteTableRows;