import React from 'react';
import {Pie} from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js'

const PieChart = ({chartData}) => {


  return (

      <Pie height={220} data={chartData} options={
        {  maintainAspectRatio:false,
          responsive: true,
          title:{
          display:true,
          text: chartData?.datasets[0].label,
          fontSize:20,
          fontColor:'#0E3E86'
        },
        legend:{
          display:true,
          position:'right'
        }
      }
    }
      />
  )
}

export default PieChart
