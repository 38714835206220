import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Badge,
  Toolbar,
  makeStyles,
  Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import WebFont from 'webfontloader';
import { useAppContext } from '../../Context';
import SimpleBadge from 'src/components/notify';
import './style.css';
import { ChevronLeftIcon} from '@heroicons/react/24/outline'

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 50
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  usertype,
  userName,
  toggleDrawer,
  showModal,
  MessageDetaiModal,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const context = useAppContext();
  const { dispatch, user, unreadMessages, pageHeader } = context;
  const m = 2;
  // const [header, setHeader] = useState('Dashboard');


  const localuser = localStorage.getItem('auth');
  const localuserObj = JSON.parse(localuser);
  const navigate = useNavigate();
  const currentPath = window.location.pathname

  const formatPath = pathname => {
    let p = pathname.split('/').slice(-1)[0];

    let newpathname = p.charAt(0).toUpperCase() + p.slice(1);
    return newpathname;
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    });
  }, []);

  return (
    <AppBar
      style={{
        width: '1665px',
        color: '#0E3E86',
        backgroundColor: '#FFFFFF',
        border: '1px solid #0E3E8633'
      }}
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        {currentPath === '/customer/requestdetails' || currentPath === '/customer/homedetails' ? (<>
            <button onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }} className='request-back-btn'>
              <ChevronLeftIcon style={{ height: 20 }} />
              Back to Requests
            </button>
          </>) : null}

        {usertype === 'Proxy' ? (
          <Box flexGrow={1}>
            <p className="font-loader">Welcome, {userName} </p>
          </Box>
        ) : (
          <Box flexGrow={1}></Box>
        )}

        {usertype === 'Customer' ? (
         <div>
           <button
            onClick={() => showModal()}
            className="btn font-loader"
            style={{
              position: 'relative',
              left: -15,
              borderRadius: 5,
              backgroundColor: '#2055A3',
              color: 'white'
            }}
          >
            Get a Quote
          </button>
         </div>
        ) : null}

        <Hidden mgDown>
          <IconButton className="notify" onClick={() => toggleDrawer()}>
            <SimpleBadge msgCounter={unreadMessages.length} />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="#0E3E86" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
