export const browserNotification = (
    notificationText = 'Thank you for enabling notifications!'
  ) => {
    if (!('Notification' in window)) {
      alert('Browser does not support notifications');
    } else if (Notification.permission == 'granted') {
      const notification = new Notification(notificationText);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission == 'granted') {
          const notification = new Notification(notificationText);
        }
      });
    }
  };