import React from 'react';
import { useNavigate } from 'react-router-dom';

function SomethingWentWrong() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center'
      }}
    >
      <h1>Ooops!,something went wrong</h1>
      <p style={{ fontSize: 15, textAlign: 'center' }}>
        Check your internet connection or contact administrator if problem
        persist
      </p>

      <button
        style={{
          color: '#2E79E9',
          backgroundColor: '#F6F8FB',
          border: 'solid #2E79E9',
          width: '160px',
          height: '40px',
          fontSize: '14px',
          cursor: 'pointer',
          margin: 15
        }}
        className="getstarted font-loader"
        type="button"
        value="Contact Support"
        onClick={() => navigate(-1)}
      >
        Go home
      </button>
    </div>
  );
}

export default SomethingWentWrong;
