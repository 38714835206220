import React, { useState } from 'react';
// import { Route, Switch, withRouter } from 'react-router-dom';
import { useNavigate, Outlet, Routes, Route } from 'react-router-dom';
import { PrivateRoutes } from '../../routes';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { Modal } from 'react-bootstrap';
import { useAppContext } from '../../Context';
import MyStepper2 from 'src/components/MyStepper2';
import { GET_CUSTOMER_REQUEST } from 'src/Graphql/Queries';
import { gql, useMutation, useSubscription } from '@apollo/client';
import Drawer from 'react-modern-drawer';
import { formatDateFull } from 'src/utils/FormatDate';
import Spinner from 'react-bootstrap/Spinner';
import { SuccessNotification } from 'src/components/Notification';
import Card from 'react-bootstrap/Card';
import { isMobile } from 'react-device-detect';
import { BsCloudUpload } from 'react-icons/bs';
import { HiOutlinePencil } from 'react-icons/hi';

// Insurance Products
import fire from '../../assets/img/fire.png';
import life from '../../assets/img/life.png';
import marine from '../../assets/img/marine.png';
import motor from '../../assets/img/motor.png';
import propertyInsurance from '../../assets/img/home-owner.png';
import { browserNotification } from 'src/utils/lib';

// const ClientRequestView = React.lazy(() =>
//   import('src/views/requestquote/RequestQuoteView')
// );

// const CLientView = React.lazy(() => import('src/views/client/ClientView'));

const READ_MESSAGE = gql`
  mutation readNotification($_notification_id: uuid) {
    update_notifications(
      where: { id: { _eq: $_notification_id } }
      _set: { is_read: true }
    ) {
      affected_rows
    }
  }
`;

const READ_ALL_MESSAGES = gql`
  mutation readNotification($customer_id: uuid) {
    update_notifications(
      where: { customer_id: { _eq: $customer_id } }
      _set: { is_read: true }
    ) {
      affected_rows
    }
  }
`;

const REQUESTS_SUBSCRIPTION = gql`
  subscription GetRequests($proxyId: uuid, $includeNull: Boolean!) {
    assigned_unassigned_view(
      where: {
        _or: [
          { proxy_id: { _eq: $proxyId } }
          { proxy_id: { _is_null: $includeNull } }
        ]
      }
      order_by: { created_at: desc }
    ) {
      created_at
      request_id
      request_type_id
      customer_name
      proxy_id
    }
  }
`;

// const loading = <div class="spinner"></div>;
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflowY: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    width: '100%'
    // overflowY: 'auto',
    // overflowX: 'hidden'
  }
}));

const DashboardLayout = () => {
  const [readNotification] = useMutation(READ_MESSAGE, {
    onCompleted: () => {
      dispatch({
        type: 'READ_MESSAGES',
        payload: {
          user_id: user?.id
        }
      });
    },

    onError: error => {
      console.log(error);
    }
  });

  const [readAllNotification] = useMutation(READ_ALL_MESSAGES, {
    onCompleted: () => {
      dispatch({
        type: 'READ_MESSAGES',
        payload: {
          user_id: user?.id
        }
      });
    },

    onError: error => {
      console.log(error);
    }
  });

  const context = useAppContext();
  console.log("fishes")
  const { user, dispatch, unreadMessages, isLoggedIn } = context;
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const handleClose = () => {
    // setModalSize('sm')
    setShow(false);
    setShowForm(false);
  };
  const handleShow = () => setShow(true);
  // const mysteps = ['Vehicle Information', 'Policy Information', 'Summary'];
  const [deleteid, setdeletedid] = useState(null);
  const navigate = useNavigate();
  let usertype = localStorage.userType;

  const [modalSize, setModalSize] = useState('lg');
  const [showForm, setShowForm] = useState(false);
  const [openMotorModal, setOpenMotorModal] = useState(false);
  const [dataSize, setDataSize] = useState(0)
  const handleClose2 = () => setOpenMotorModal(false);

  const makeRequest = productName => {
    if (productName === 'Motor Insurance') {
      setOpenMotorModal(true);
      handleClose();
    } else if (productName === 'Home Owner Insurance')
      navigate('/HomeOwnerPolicyRequest');
  };

  const makeMotorRequest = type => {
    if (type === 'Fill Form') {
      navigate('/MotorPolicyRequest');
    } else {
      navigate('/upload-document-login');
    }
  };

  useSubscription(REQUESTS_SUBSCRIPTION, {
    variables: {
      proxyId: user?.id,
      includeNull: true
    },

    onSubscriptionData: ({ subscriptionData: { data } }) => {
      // setSubscriptionData(data);
      if (usertype === 'Proxy' && isLoggedIn == true && dataSize != data.assigned_unassigned_view.length) {
        browserNotification('New customer request!!!');
        setDataSize(data.assigned_unassigned_view.length)
      } else {
        dispatch({
          type: 'SET_LOGIN'
        });
      }
      // console.log(data);
    },

    onError: err => {
      console.log(err);
    }
  });

  const actions = [
    {
      id: 1,
      action: 'Upload Documents',
      icon: <BsCloudUpload size={45} />,
      help_text:
        'Upload the required documents to create your request and get a quote.'
    },

    {
      id: 2,
      action: 'Fill Form',
      icon: <HiOutlinePencil size={45} />,
      help_text:
        'Fill out the quick form with the required details to make your request'
    }
  ];

  const imgArray = [
    {
      picture: motor,
      name: 'Motor Insurance',
      state: true
    },
    {
      picture: propertyInsurance,
      name: 'Home Owner Insurance',
      state: true
    }
    // {
    //   picture: life,
    //   name: 'Life Insurance',
    //   state: false
    // },
    // {
    //   picture: marine,
    //   name: 'Marine Insurance',
    //   state: false
    // },
    // {
    //   picture: fire,
    //   name: 'Fire Insurance',
    //   state: false
    // }
  ];

  // const [
  //   UpdateNotifation,
  //   { error: NotificationError, loading: NotificationLoading }
  // ] = useMutation(UPDATE_NOTIFICATION, {
  //   onCompleted: data => getallmessages(),
  //   onError: error => console.log('an error occurrerd', error)
  // });

  // const updateSingleMessage = id => {
  //   UpdateNotifation({
  //     variables: {
  //       id: id,
  //       state: 'READ'
  //     }
  //   });
  // };

  const delete_all_messages = () => {
    dispatch({
      type: 'REMOVE_ALL_NOTIFICATIONS'
    });
  };

  const token = window.localStorage.getItem('token');

  const getallmessages = () => {
    dispatch({
      type: 'FETCH_MESSAGES',
      payload: token
    });
  };

  const confirmdeletion = data => {
    SuccessNotification('You have successfully deleted all messages');
  };

  const confirmdeletion2 = data => {
    dispatch({
      type: 'UPDATE_MESSAGES',
      payload: deleteid
    });
    setDetailOpen(false);
  };

  // const showProductForm = () => {
  //   setShowForm(true)
  //   setModalSize('xl')
  // }

  // const confimsubmission = data => {
  //   handleClose();
  //   SuccessNotification('Your request was successfully submitted');
  //   navigate('/customer/myrequests');
  // };

  const toggleDrawer = () => {
    setIsOpen(prevState => !prevState);
  };
  const closeDrawer = () => {
    setIsOpen(false);
  };

  const modal = () => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size={modalSize}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ marginTop: 30 }}
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            Select a Product
          </Modal.Title>
          <button
            onClick={() => handleClose()}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {imgArray?.map((policy, key) => (
                <div
                  key={key}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center'
                  }}
                >
                  <Card
                    bg="#FFFFFF"
                    onClick={() => makeRequest(policy.name)}
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: 5,
                      opacity: !policy.state ? 0.5 : 1,
                      height: 200,
                      width: 200,
                      border: 'none',
                      borderRadius: 20,
                      boxShadow: '2px 0px 49px -6px rgba(222,214,214,0.79)',
                      cursor: 'pointer'
                    }}
                  >
                    <Card.Img
                      variant="bottom"
                      src={policy.picture}
                      style={{
                        height: 100,
                        width: 100
                      }}
                    />
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontSize: 16,
                          fontWeight: 'bold'
                        }}
                      >
                        {policy.name}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const modal2 = () => {
    return (
      <Modal
        show={openMotorModal}
        onHide={handleClose2}
        animation={true}
        size="md"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
          <button
            onClick={() => handleClose2()}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              padding: 20,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {actions?.map((action, key) => (
              <div
                key={key}
                onClick={e => {
                  e.preventDefault();
                  makeMotorRequest(action.action);
                }}
                className="col-xl-6 col-md-6 "
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  alignContent: 'center',
                  paddingTop: 20,
                  margin: 25,
                  borderRadius: 30,
                  backgroundColor: '#F6F8FB',
                  cursor: 'pointer'
                }}
              >
                <div style={{ marginTop: 20 }}>{action.icon}</div>
                <p
                  style={{
                    fontSize: 17,
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }}
                >
                  {action.action}
                </p>
                <p
                  style={{ fontSize: 12, color: 'black', textAlign: 'center' }}
                >
                  {action.help_text}
                </p>
              </div>
            ))}
          </div>
          <div
            style={{
              position: 'absolute',
              left: isMobile ? 175 : 237,
              marginTop: isMobile ? -260 : -150,
              fontWeight: 'bold'
            }}
          >
            <span>OR</span>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const MessageDetailModal = () => {
    return (
      <Modal
        show={detailOpen}
        onHide={handleClose}
        animation={true}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ marginTop: 30 }}
      >
        <Modal.Header>
          <div
            style={{
              display: 'flex',
              paddingLeft: 10,
              paddingRight: 10,
              justifyContent: 'space-between',
              width: '100%',
              fontWeight: '700'
            }}
          >
            <h4>{selectedMessage?.subject}</h4>
            <button
              onClick={() => setDetailOpen(false)}
              type="button"
              className="close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <>
              <p style={{ textAlign: 'center', marginBottom: 25 }}>
                {selectedMessage?.message_short}
              </p>
              {/* <button
                  className="btn btn-danger"
                  style={{ width: '100%' }}
                  onClick={() => {
                    DeleteAmessage(selectedMessage?.id);
                    setdeletedid(selectedMessage?.id);
                  }}
                >
                  Delete
                </button> */}
            </>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="bla bla bla"
        zIndex="999"
        size={370}
        style={{
          backgroundColor: 'white',
          overflowY: 'scroll'
        }}
      >
        <div
          onClick={() => closeDrawer()}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            width: 48,
            height: 48,
            borderRadius: 24,
            backgroundColor: 'white',
            right: 10,
            top: 5,
            border: '4px solid #0E3E86',
            opacity: 1,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <p
            style={{
              color: '#0E3E86',
              fontWeight: '700',
              top: 35,
              fontSize: 22
            }}
          >
            x
          </p>
        </div>

        <div style={{ marginTop: 100, width: '93%', marginLeft: 20 }}>
          <span
            style={{ float: 'right', fontWeight: '600', cursor: 'pointer' }}
            onClick={() =>
              readAllNotification({
                variables: {
                  customer_id: user?.id
                }
              })
            }
          >
            Clear Notifications
          </span>

          <>
            {unreadMessages.length > 0 ? (
              <>
                {unreadMessages.map(message => (
                  <div
                    class="card"
                    key={message.id}
                    style={{
                      width: '100%',
                      marginTop: 20,
                      backgroundColor: 'white',
                      borderRadius: 10,
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      closeDrawer();
                      setSelectedMessage(message);
                      // updateSingleMessage(message.id);
                      setDetailOpen(true);
                      readNotification({
                        variables: {
                          _notification_id: message.id
                        }
                      });
                    }}
                  >
                    <div class="card-body">
                      <h5 style={{ fontWeight: '700' }}>{message.subject}</h5>
                      {/* <p class="card-text">{message.short}</p> */}
                      {/* {console.log(message)} */}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between'
                        }}
                      >
                        <p>{formatDateFull(message.created_at)}</p>
                        {/* <p
                            style={{
                              backgroundColor: message.is_read ? '': '#90EE90',
                              padding: 5,
                              borderRadius: 10,
                              fontSize: 12,
                              height: 30,
                              width: 30
                            }}
                          >
                           {console.log(message.is_read)}
                          </p> */}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '80vh',
                  alignContent: 'center',
                  marginLeft: 20
                }}
              >
                <h3>No messages yet</h3>
              </div>
            )}
          </>
        </div>
      </Drawer>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        usertype={usertype}
        userName={user?.name}
        showModal={handleShow}
        toggleDrawer={toggleDrawer}
      />
      <NavBar
        usertype={usertype}
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <>
        {modal()}
        {modal2()}
        {MessageDetailModal()}
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <React.Suspense fallback={<Spinner />}>
                <Routes>
                  {PrivateRoutes.map((prop, key) => {
                    return (
                      <Route
                        path={prop.layout + prop.path}
                        element={prop.component}
                        exact={prop.exact}
                        key={key}
                      />
                    );
                  })}
                </Routes>
              </React.Suspense>
              <Outlet />
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default DashboardLayout;
