import {BsTrashFill} from 'react-icons/bs'
import WebFont from 'webfontloader'
import "../../AnimatedCard/styles.css";
import { useEffect } from 'react';

function TableRows({rowsData, deleteTableRows, handleChange}) {

    useEffect(() => {
        WebFont.load({
          google: {
            families: ['Poppins']
          }
        })
      }, [])

    return(
        
        rowsData.map((data, index)=>{
            const {name, date_of_birth,occupation,sum_assured}= data;
            return(
                <tr key={index}>
                <td>
               <input type="text" value={name} onChange={(evnt)=>(handleChange(index, evnt))} name="name" className="form-control font-loader"/>
                </td>
                <td><input type="text" value={occupation}  onChange={(evnt)=>(handleChange(index, evnt))} name="occupation" className="form-control font-loader"/> </td>
                <td><input type="number" value={sum_assured}  onChange={(evnt)=>(handleChange(index, evnt))} name="sum_assured" className="form-control font-loader" /> </td>
                <td><input type="date" value={date_of_birth}  onChange={(evnt)=>(handleChange(index, evnt))} name="date_of_birth" className="form-control font-loader" /> </td>
                <td><button className="btn btn-outline-danger" onClick={()=>(deleteTableRows(index))}>{<BsTrashFill />}</button></td>
            </tr>
            )
        })
   
    )
    
}
export default TableRows;