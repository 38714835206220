import React from 'react';
import myCustomSpinner from '../assets/img/logo_animation.gif';
export default () => {
  return (
    <img
      style={{
        position: 'relative',
        marginTop: '85%',
        left: '48%'
      }}
      src={myCustomSpinner}
    />
  );
};
