import React from 'react';
// import { Suspense } from 'react';
// import {
//   Route,
//   Switch,
//   Redirect,
//   BrowserRouter as Router
// } from 'react-router-dom';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import LandingPage from 'src/views/auth/LandingPage';
import ClientIndex from 'src/views/client/ClientView';
import Dashboard from 'src/views/reports/DashboardView';
import { proxyPrivateRoutes, PrivateRoutes } from 'src/routes';
import LandingPageMobile from 'src/views/auth/LandingPageMobile';
import SuccessfulPaymentPage from 'src/views/paymentpages/SuccessfulPaymentPage';
import HomeOwnerFormLoggedIn from 'src/components/AnimatedCard/HomeOwnerFormLoggedIn';
import AnimatedCardV2 from 'src/components/AnimatedCard/AnimatedCardV2';
import UpdateMotorRequest from 'src/components/AnimatedCard/UpdateMotorRequest';
import HomeOwnerForm from 'src/components/AnimatedCard/HomeOwnerForm';
import AnimatedCardLoggedIn from 'src/components/AnimatedCard/AnimatedCardLoggedIn';
import FailurePaymentPage from 'src/views/paymentpages/FailurePaymentPage';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { isMobile, isBrowser } from 'react-device-detect';
import AnimatedCard from 'src/components/AnimatedCard/AnimatedCard';
import EmailConfimration from 'src/views/auth/EmailConfimation';
import NotVerified from 'src/components/NotVerified';
import AddItems from 'src/components/AnimatedCard/AddItems';
import UploadDocuments from 'src/components/UploadPhoto/Upload';
import CustomerSignUpV2 from 'src/views/auth/SignUpPage/CustomerSignUpV2';
import IntermediarySignup from 'src/views/auth/SignUpPage/IntermediarySignup';
import UploadFile2 from 'src/components/UploadPhoto/Upload2';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import ErrorPage from 'src/views/Error';

// const Dashbaord = React.lazy(() => import('../layouts/DashboardLayout'));
const SelectUser = React.lazy(() => import('../views/auth/SelectUserView'));
const SelectUserMobile = React.lazy(() =>
  import('../views/auth/SelectUserMobile')
);

const ProtectedRoutes = () => {
  if (!localStorage.user) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;

export const Content = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="customer" element={<DashboardLayout />}>
          <Route index element={<ClientIndex />} />
          {PrivateRoutes.map((route, key) => (
            <Route
              key={key}
              path={route.layout + route.path}
              Component={route.component}
            />
          ))}
        </Route>
        <Route path="intermediary" element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />
          {proxyPrivateRoutes.map((route, key) => (
            <Route
              key={key}
              path={route.layout + route.path}
              Component={route.component}
            />
          ))}
        </Route>
        <Route path="error-page" element={<ErrorPage />} />
      </Route>
      <Route exact path="/not-verified" element={<NotVerified />} />
      <Route path="/success-page" element={<SuccessfulPaymentPage />} />
      <Route path="/something-went-wrong" element={<SomethingWentWrong />} />
      <Route exact path="/add-item" element={<AddItems />} />
      <Route exact path="/email-confirmation" element={<EmailConfimration />} />
      <Route exact path="/animate" element={<AnimatedCard />} />
      <Route exact path="/animateV2" element={<AnimatedCardV2 />} />
      <Route exact path="/HomeRequestsLanding" element={<HomeOwnerForm />} />
      <Route path="/failure-page" element={<FailurePaymentPage />} />
      <Route
        path="/HomeOwnerPolicyRequest"
        element={<HomeOwnerFormLoggedIn />}
      />
      <Route path="/MotorPolicyRequest" element={<AnimatedCardLoggedIn />} />
      <Route
        exact
        path="/intermediarysignup"
        element={<IntermediarySignup />}
      />
      <Route
        exact
        path="/update-motor-details"
        element={<UpdateMotorRequest />}
      />
      <Route exact path="/upload-document" element={<UploadDocuments />} />
      <Route exact path="/upload-document-login" element={<UploadFile2 />} />
      <Route
        exact
        path="/selectuser"
        element={isBrowser ? <SelectUser /> : <SelectUserMobile />}
      />
      <Route
        exact
        path="/"
        element={isMobile ? <LandingPageMobile /> : <LandingPage />}
      />
      <Route
        exact
        path="/UpdatedCustomerSignUp"
        element={<CustomerSignUpV2 />}
      />
      {/* <Redirect to="/" /> */}
    </Routes>
  );
};
