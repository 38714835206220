import React from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import { BsArrowUpRight } from 'react-icons/bs';
import { formatCurrency } from 'src/utils/FormatCurrency';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { formatDate } from 'src/utils/FormatDate';

const TransactionHistory = ({ transactions, showModal, setTransdetails }) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }


  const styles = {
    container: {
      padding: '10px',
      // border: '1px solid #ccc',
      backgroundColor: 'white',
      width: isMobile ? windowSize?.innerWidth-20 : '100%'
    },
    header: {},
    headerTitle: {
      fontSize: '19px',
      fontWeight: '600',
      textTransform: 'capitalize',
      letterSpacing: '1px'
    },
    body: {
      marginTop: '10px',
      overflowY: 'scroll',
      //overflow: "hidden",
      height: '300px',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
        width: '0 !important'
      },
      '&::-webkit-scrollbar-track': {
        display: 'none'
      },
      '&::-webkit-scrollbar-thumb ': {
        display: '#FF0000'
      }
    },
    card: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: '10px',
      padding: '10px',
      borderBottom: '1px solid #ccc',
      margin: isMobile ? '20px 5px' : '10px 5px',
      height: isMobile ? '130px' : '100px'
    },
    contentTitle: {
      fontSize: '17px',
      fontWeight: '600',
      textTransform: 'capitalize',
      letterSpacing: '1px',
      margintTop: '10px'
    },
    contenDesc: {
      fontSize: '13px',
      textTransform: 'capitalize',
      color: '#666'
    },
    contentEnd: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: '5px',
      marginTop: '30px'
    },
    content: {
      flex: '1'
    },
    iconContainer: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      backgroundColor: '#e91e6369',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    contentStatus: {
      color: '#50cd84',
      padding: '5px 10px',
      borderRadius: '40px',
      fontSize: '15px',
      textTransform: 'capitalize',
      letterSpacing: '1px'
    },
    contentStatus2: {
      color: '#ffd600',
      padding: '5px 10px',
      borderRadius: '40px',
      fontSize: '15px',
      textTransform: 'capitalize',
      letterSpacing: '1px'
    },
    contentStatus3: {
      color: '#4169e1',
      padding: '5px 10px',
      borderRadius: '40px',
      fontSize: '15px',
      textTransform: 'capitalize',
      letterSpacing: '1px'
    }
  };

  const handleRowClick = row => {
    console.log(row);
    // let m = {
    //   type: row[0],
    //   reference: row[1],
    //   paid_to: row[4],
    //   amount: row[3],
    //   datepaid: row[6],
    //   status: row[2]
    // };
    // setTransdetails(m);
    // showModal();
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <p className="transaction-heading font-loader">Transaction History</p>
      </div>
      <div style={styles.body}>
        <div style={styles.child}>
          {transactions?.map(transaction => (
            <div onClick={(e) => {
              e.preventDefault();
              handleRowClick(transaction)
            }} style={styles.card}>
              <div style={styles.iconContainer}>
                <FiArrowUpRight size={30} color={'#e91e63'} />
              </div>
              <div style={styles.content}>
                <h1 style={styles.contentTitle}>Premium Payment</h1>
                <p style={styles.contenDesc}>{formatDate(transaction.created_at)}</p>

                <p style={styles.contenDesc}>
                  Reference: {transaction.reference}
                </p>
              </div>
              <div style={styles.contentEnd}>
                <h1 style={styles.contentTitle}>
                  {formatCurrency(transaction.amount)}
                </h1>
                {transaction.status === 'success' ? (
                  <p style={styles.contentStatus} className="bg-greenLight">
                    {transaction.status}
                  </p>
                ) : transaction.status === 'pending' ? (
                  <p style={styles.contentStatus2} className="bg-blueLight">
                    {transaction.status}
                  </p>
                ) : transaction.status === 'failed' ? (
                  <p style={styles.contentStatus3} className="bg-orangeLight">
                    {transaction.status}
                  </p>
                ) : null}

                <p
                  style={{
                    fontSize: 12,
                    color: '#666',
                    position: 'relative',
                    top: -10
                  }}
                >
                  Paid to: {transaction.quote.insurance_company.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
