export const months = [
  // { month: 1, id: 30 },
  // { month: 2, id: 60 },
  // { month: 3, id: 90 },
  // { month: 4, id: 120 },
  // { month: 5, id: 150 },
  // { month: 6, id: 180 },
  // { month: 7, id: 210 },
  // { month: 8, id: 240 },
  // { month: 9, id: 270 },
  // { month: 10, id: 300 },
  // { month: 11, id: 330 },
  { month: 12, id: 365 },
  { month: 24, id: 730 },
  { month: 36, id: 1095 }
]

export const vehicleinfo = [
  {
    title: 'Tell us about your car',
    questions: [
      {
        label: 'What policy type do you prefer?',
        type: 'select',
        name: 'insurance_product_id',
        placeholder: 'Select preferred policy type'
      },
      {
        label: 'What is the make of your vehicle?',
        type: 'select',
        name: 'vehicle_make'
      },
      {
        label: 'What is the model?',
        type: 'select',
        name: 'model_id'
      },
      {
        label: 'What is the year of manufacture?',
        type: 'select',
        name: 'year_of_manufacturing',
        placeholder: 'Select year of manufacture'
      },
      {
        label: 'What is the year of registration?',
        type: 'select',
        name: 'year_of_registration',
        placeholder: 'Select year of registration'
      },
      {
        label: 'What is the current estimated value of your vehicle?',
        type: 'number',
        name: 'cost_price',
        placeholder: 'Input estimated value'
      }
      // {
      //   label: 'What is the colour?',
      //   type: 'text',
      //   name: 'color',
      //   placeholder: 'Input Vehicle Color'
      // },
      // {
      //   label: 'What is your vehicle registration number?',
      //   type: 'string',
      //   name: 'registration_number',
      //   placeholder: 'Input registration number'
      // }
    ]
  }
  // {
  //   title: 'Tell us about your car',
  //   questions: [
  //     {
  //       label: 'What is your vehicle chassis number?',
  //       type: 'text',
  //       name: 'chassis_number',
  //       placeholder: 'Input chassis number'
  //     },
  //     {
  //       label: 'What is the year of registration?',
  //       type: 'select',
  //       name: 'year_of_registration',
  //       placeholder: 'Select year of registration'
  //     },
  //     {
  //       label: 'Vehicle Image',
  //       type: 'file',
  //       name: 'road_worthy_doc',
  //       placeholder: 'Click to select file'
  //     }
  //   ]
  // }
]

export const update_vehicle_info = [
  {
    title: 'Give us more info about your car',
    questions: [
      {
        label: 'What is the colour?',
        type: 'text',
        name: 'color',
        placeholder: 'Input Vehicle Color'
      },
      {
        label: 'What is your vehicle registration number?',
        type: 'string',
        name: 'registration_number',
        placeholder: 'Input registration number'
      },
      {
        label: 'What is your vehicle chassis number?',
        type: 'text',
        name: 'chassis_number',
        placeholder: 'Input chassis number'
      }
    ]
  }
]

export const Policyinfo = [
  {
    title: 'Tell us about your policy',
    questions: [
      {
        label: 'When would you like it to start?',
        type: 'date',
        name: 'inception_date',
        placeholder: 'Policy start date'
      },
      {
        label: 'How long do you want the policy to run?',
        type: 'select',
        name: 'interval',
        placeholder: 'Duration of policy'
      },
      {
        label: 'Do you want buy back excess on your policy',
        type: 'select',
        name: 'buy_back_excess'
      }
    ]
  },
  {
    title: 'Comprehensive Cover Information',
    questions: [
      {
        label: 'Is your vehicle currently in excellent shape?',
        type: 'select',
        name: 'repair_state',
        placeholder: ''
      },
      {
        label:
          'In case of an accident, would you like your policy to cover third-party damage? ',
        type: 'select',
        name: 'increase_thirparty_damage_limit',
        placeholder: 'Third party damaged'
      },
      {
        label:
          'Have you changed the original design of your vehicle in any way?',
        type: 'select',
        name: 'altered_vehicle_design',
        placeholder: ''
      },
      {
        label: 'Have you ever made a claim? ',
        type: 'select',
        name: 'state_of_claim',
        placeholder: ''
      }
    ]
  },
  {
    title: 'Further Comprehensive Policy Details',
    questions: [
      {
        label: 'If yes, how many years since your last claim?',
        type: 'number',
        name: 'no_claim_years',
        placeholder: 'Input number of years'
      },
      {
        label: 'What’s the primary use of your vehicle?',
        type: 'select',
        name: 'usage_type',
        placeholder: 'Select vehicle use'
      },
      {
        label: 'How many seats are in your vehicle?',
        type: 'number',
        name: 'seats',
        placeholder: 'Including driver’s seat *Select number of seats'
      }
    ]
  },
  {
    title: 'Further Comprehensive Policy Details',
    questions: [
      {
        label: 'When was your vehicle purchased?',
        type: 'date',
        name: 'purchase_date',
        placeholder: 'Input purchase date'
      },
      {
        label: 'What is your engine capacity?',
        type: 'number',
        name: 'engine_capacity',
        placeholder: 'Input Engine capacity'
      },

      {
        label: 'What is the current mileage of your vehicle?',
        type: 'number',
        name: 'mileage',
        placeholder: 'Input vehicle mileage'
      }
    ]
  }
]

export const profileinfo = [
  {
    title: 'Tell us about You',
    questions: [
      // {
      //   label: 'Are you purchasing insurance for yourself?',
      //   type: 'select',
      //   name: 'purchase_for_self',
      //   placeholder: ''
      // },
      {
        label: 'Full name',
        type: 'text',
        name: 'fullName',
        placeholder: 'First Name | Middle Name | Last Name'
      },
      {
        label: 'Email',
        type: 'email',
        name: 'email',
        placeholder: 'edgarpoe@email.com'
      },
      {
        label: 'Password',
        type: 'password',
        name: 'password',
        placeholder: ''
      },
      {
        label: 'Phone',
        type: 'text',
        name: 'phone_number',
        placeholder: '020 000 0000'
      },
      {
        label: 'ID',
        type: 'text',
        name: 'national_id',
        placeholder: 'Input Ghana card number'
      },
      {
        label: 'Gender',
        type: 'select',
        name: 'gender',
        placeholder: ''
      },
      {
        label: 'Country of Origin',
        type: 'select',
        name: 'country',
        placeholder: ''
      },
      {
        label: 'Date of Birth',
        type: 'date',
        name: 'dob',
        placeholder: ''
      },
      {
        label: 'Digital Address',
        type: 'text',
        name: 'digital_address',
        placeholder: ''
      }
    ]
  }
  // {
  //   title: 'Beneficiary of Insurance',
  //   questions: [
  //     {
  //       label: 'Full name',
  //       type: 'text',
  //       name: 'beneficiary_name',
  //       placeholder: 'First Name | Middle Name | Last Name'
  //     },
  //     {
  //       label: 'Email',
  //       type: 'email',
  //       name: 'beneficiary_email',
  //       placeholder: 'edgarpoe@email.com'
  //     },
  //     {
  //       label: 'Phone',
  //       type: 'text',
  //       name: 'beneficiary_phone_number',
  //       placeholder: '020 000 0000'
  //     },
  //     {
  //       label: 'ID/Company Number',
  //       type: 'text',
  //       name: 'beneficiary_id',
  //       placeholder: 'Input Beneficiary Ghana card number'
  //     }
  //   ]
  // }
]

export const profileinfoLoggedIn = [
  {
    title: 'Who is the cover for?',
    questions: [
      {
        label: 'Are you purchasing insurance for yourself?',
        type: 'select',
        name: 'purchase_for_self',
        placeholder: ''
      }
    ]
  },
  {
    title: 'Beneficiary Details (If Any)',
    questions: [
      {
        label: 'Full name',
        type: 'text',
        name: 'beneficiary_name',
        placeholder: 'First Name | Middle Name | Last Name'
      },
      {
        label: 'Email',
        type: 'email',
        name: 'beneficiary_email',
        placeholder: 'edgarpoe@email.com'
      },
      {
        label: 'Phone',
        type: 'text',
        name: 'beneficiary_phone_number',
        placeholder: '020 000 0000'
      },
      {
        label: 'ID/Company Number',
        type: 'text',
        name: 'beneficiary_id',
        placeholder: 'Input Beneficiary Ghana card number'
      }
    ]
  }
]
