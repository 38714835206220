import dayjs, { Dayjs } from 'dayjs';

export const formatDate = created => {
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    weekday: 'long'
  };

  const date = new Date(created);

  return date.toLocaleDateString('en-GB', options);
};

export const getToday = () => {
  let date = new Date();

  return date.toISOString().substring(0, 10);
};

export const formatDateFull = date => {
  var options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long'
  };
  let dateToFormat = new Date(date);
  let formmateddate = dateToFormat.toLocaleDateString('en-GB', options);
  return formmateddate;
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result.toISOString().substring(0, 10);
};

export const addMonth = (date, months) => {
  let mm = dayjs(date);
  console.log(months);
  console.log('DayJS ', mm.add(months, 'month').toISOString());

  return date;
};

export const addMonthV2 = (date, months) => {
  let myDate = new Date(date);
  var d = myDate.getDate();
  myDate.setMonth(myDate.getMonth() + Number(months));
  if (myDate.getDate() != d) {
    myDate.setDate(0);
  }
  return myDate.toISOString().substring(0, 10);
};

export const SortData = array => {
  array.sort(function(a, b) {
    return new Date(b.original_date) - new Date(a.original_date);
  });

  return array;
};

export const generatePassword = () => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  let formattedString = '@' + result + '09';
  return formattedString;
};
