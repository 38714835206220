import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  CircularProgress,
  Box,
  Card,
  CardContent
} from '@material-ui/core';
import Page from 'src/components/Page';
import TotalVendors from './Vendors';
import TotalOrders from './TotalOrders';
import Summary from './Summary';
import LatestRedemptions from './LatestRedemptions';
// import Sales from './Sales';
// import TasksProgress from './TasksProgress';
import { useAppContext } from '../../../Context';
import TotalCustomers from './policychart';
import { Modal } from 'react-bootstrap';
import Policychart from './policychart';
import PieChart from './PieChart';
import BarChart from './BarChart';
import { CommissionOverview, RequestOverview, QuoteSent } from './Data';
import { ArrowUpRight } from 'react-feather';
import data from 'src/views/product/ProductListView/data';
import './text.css';
// import TrafficByDevice from './TrafficByDevice';
import {
  GET_INTERMEDIARY_DASHBOARD_POLICY_INFO,
  GET_INTERMEDIARY_WEEKLY_POLICY_SALE,
  GET_ALL_BEENFITS,
  GET_REQUEST_OVERVIEW
} from 'src/Graphql/Queries';
import { useQuery, useSubscription, gql } from '@apollo/client';
import { formatCurrency } from '../../../utils/FormatCurrency';
import './dashboard.css';
import { isMobile } from 'react-device-detect';
import WebFont from 'webfontloader';
import { api } from '../../../utils/Api';
// import { browserNotification } from 'src/utils/lib';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  smallercard: {
    display: 'flex',
    borderRadius: 20,
    justifyContent: 'center',
    padding: 30
  }
}));

// const REQUESTS_SUBSCRIPTION = gql`
//   subscription GetRequests($proxyId: uuid, $includeNull: Boolean!) {
//     assigned_unassigned_view(
//       where: {
//         _or: [
//           { proxy_id: { _eq: $proxyId } }
//           { proxy_id: { _is_null: $includeNull } }
//         ]
//       }
//       order_by: { created_at: desc }
//     ) {
//       created_at
//       request_id
//       request_type_id
//       customer_name
//       proxy_id
//     }
//   }
// `;

const Dashboard = () => {
  const classes = useStyles();
  const context = useAppContext();

  const { dispatch, user } = context;

  const [requestOverView, setRequestOverview] = useState(null);

  const {
    data: PoliciesData,
    loading: PoliciesLoading,
    error: PoliciesError
  } = useQuery(GET_INTERMEDIARY_DASHBOARD_POLICY_INFO, {
    variables: { proxyID: user?.id }
  });

  const {
    data: WeeklyData,
    loading: WeeklyDataLoading,
    error: WeeklyDataError
  } = useQuery(GET_INTERMEDIARY_WEEKLY_POLICY_SALE, {
    variables: { intermediary_id: user?.id }
  });

  //  useSubscription(REQUESTS_SUBSCRIPTION, {
  //   variables: {
  //     proxyId: user?.id,
  //     includeNull: true
  //   },

  //   onSubscriptionData: ({ subscriptionData: { data } }) => {
  //     // setSubscriptionData(data);
  //     browserNotification('New customer request!!!');
  //     // console.log(data);
  //   },

  //   onSubscriptionComplete: () => {

  //   },

  //   onError: err => {
  //     console.log(err);
  //   }
  // });

  // useEffect(() => {
  //   if (data) {
  //     // console.log(data);
  //     browserNotification('New customer request!!!');
  //   } else {
  //     // setSubscriptionData(data);
  //   }
  // }, [data]);

  const {
    data: benefitData,
    loading: benefitLoading,
    error: benefitError
  } = useQuery(GET_ALL_BEENFITS, {
    variables: {
      product_type_id: '640cb761-2573-421d-914e-a8ded6a4a4bd',
      intermediary_id: user?.id
    }
  });

  const {
    data: requestsData,
    loading: requestLoading,
    error: requestError
  } = useQuery(GET_REQUEST_OVERVIEW);

  const [lineChartData, setLineChartData] = useState(null);
  const [requestPieChartData, setrequestPieChartData] = useState(null);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    });
  }, []);

  //Setting up to plot the Line Chart for the policy Chart
  useEffect(() => {
    if (user?.id) {
      let currentYear = new Date().getFullYear();
      api
        .post(`/reports/proxy/year_policy`, {
          year: currentYear.toString(),
          proxy_id: user?.id
        })
        .then(response => {
          let data = response.data; //this part can be reeditted, I don't know the structure of the response from the endpoint
          let policySalesGraphData = data.data;
          // console.log(policySalesGraphData)
          setLineChartData({
            labels: policySalesGraphData?.map(label =>
              label.month_name.toUpperCase()
            ),

            datasets: [
              {
                label: 'Policy Sales Over Time',
                fill: true,
                lineTension: 0.5,
                backgroundColor: 'rgba(0, 94, 242, 0.59)',
                data: policySalesGraphData?.map(
                  ordinate => ordinate.total_amount
                )
              }
            ]
          });
          // console.log(data.data)
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [user]);

  // useEffect(() => {
  //  if(benefitData){
  //   dispatch({
  //     type: 'FETCH_PRODUCT_BENEFIT',
  //     payload: benefitData.product_benefit
  //   });
  //  }

  // }, [benefitData])

  useEffect(() => {
    if (requestsData) {
      // console.log("Request Data ", requestsData)
      setRequestOverview([
        {
          label: 'Canceled Requests',
          value: requestsData?.cancelledRequests?.aggregate.count
        },
        {
          label: 'Pending Requests',
          value: requestsData?.pendingRequests?.aggregate.count
        },
        {
          label: 'Active Requests',
          value: requestsData?.activeRequests?.aggregate.count
        },
        {
          label: 'Completed Requests',
          value: requestsData?.completedRequests?.aggregate.count
        }
      ]);
    }
  }, [requestsData]);

  useEffect(() => {
    if (requestOverView) {
      setrequestPieChartData({
        labels: requestOverView?.map(data => data.label),
        datasets: [
          {
            label: 'Request Overview',
            backgroundColor: ['#D84315', '#FFFF00', '#00E676', '#03A9F4'],
            // borderColor: ['#F1F6FE', '#F1F6FE'],
            data: requestOverView.map(data => data.value)
          }
        ]
      });
    }
  }, [requestOverView]);

  const items = [
    { id: 1, text: 'Clients', value: 0 },
    { id: 1, text: 'Avg. Quote Response Rate', value: 0 },
    { id: 3, text: 'Avg. Conversion Rate', value: 0 }
  ];

  // let save = (canvas) => {
  //   const ctx = canvas.getContext("2d");
  //   const gradient = ctx.createLinearGradient(0, 0, 300, 0);
  //   gradient.addColorStop(0, '#2E79E9');
  //   gradient.addColorStop(0, 'white');
  // }

  // //Setting up to plot the Pie Chart for the Commission Overview
  // const [commissionPieChartData, setcommissionPieChartData] = useState({
  //   labels: CommissionOverview.map(data => data.label),
  //   datasets: [
  //     {
  //       label: 'Commission Overview',
  //       backgroundColor: ['#2E79E966', '#00E06966'],
  //       borderColor: ['#2E79E9', '#2E79E9'],
  //       data: CommissionOverview.map(data => data.value)
  //     }
  //   ]
  // });

  // Setting up to plot the Pie Chart for the Request Overview
  // const [requestPieChartData, setrequestPieChartData] = useState({
  //   labels: RequestOverview.map(data => data.label),
  //   datasets: [
  //     {
  //       label: 'Request Overview',
  //       backgroundColor: ['#2E79E9', '#FFD6AB'],
  //       borderColor: ['#F1F6FE', '#F1F6FE'],
  //       data: RequestOverview.map(data => data.value)
  //     }
  //   ]
  // });

  // //Setting up to plot Bar Chart for Quote Sent
  // const [quoteSent, setquoteSent] = useState({
  //   labels: QuoteSent.map(data => data.month),
  //   datasets: [
  //     {
  //       label: 'Quotes Sent',
  //       backgroundColor: '#2E79E9',
  //       borderRadius: '15px',
  //       data: QuoteSent.map(data => data.value),
  //     }
  //   ]
  // });

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid style={{ display: 'flex', flexDirection: 'row' }}>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: 700,
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '82%' }}
            >
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  margin: 5
                }}
              >
                {items.map((item, key) => (
                  <Grid key={key} className="TopCards">
                    <Card className="TopCards card ">
                      <CardContent>
                        <div
                          style={{
                            float: 'right',
                            backgroundColor: '#90EE90',
                            display: 'flex',
                            flexDirection: 'row',
                            height: 24,
                            width: 62,
                            borderRadius: 10,
                            opacity: 0.7,
                            margin: 10,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: 15
                          }}
                        >
                          <p
                            className="font-loader topCard-vals"
                            style={{ color: 'green', fontSize: 10 }}
                          >
                            {PoliciesLoading ? (
                              <CircularProgress
                                size={12}
                                style={{ marginTop: 9, marginRight: 5 }}
                              />
                            ) : (
                              item.value
                            )}
                            % <ArrowUpRight />{' '}
                          </p>
                        </div>
                        <p className="TopCardsText font-loader">{item.text}</p>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Grid className="policyChartContainer">
                <Grid>
                  <Card className="policyChart">
                    <CardContent style={{ minHeight: 550 }}>
                      {lineChartData ? (
                        <Policychart chartData={lineChartData} />
                      ) : (
                        <CircularProgress
                          style={{
                            position: 'relative',
                            marginTop: '15%',
                            left: '50%'
                          }}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>

            <Grid className="overviewContainer">
              {/* {console.log(commissionPieChartData)} */}
              <Grid>
                <Card className="overViewCard">
                  <CardContent>
                    {/* <div style={{border:'solid', height:'100%'}}> */}
                    {/* <PieChart chartData={commissionPieChartData} /> */}
                    {/* </div> */}
                  </CardContent>
                </Card>
              </Grid>

              <Grid>
                <Card className="overViewCard">
                  <CardContent>
                    {requestOverView ? (
                      <PieChart chartData={requestPieChartData} />
                    ) : (
                      <CircularProgress />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="valuesContainer">
            <Grid style={{ marginBottom: 25.3, marginLeft: 0 }}>
              <Card className="valuesCard">
                <CardContent>
                  <h3 className="cardHeader font-loader">
                    {' '}
                    {PoliciesLoading ? (
                      <CircularProgress />
                    ) : PoliciesData?.policies_aggregate?.aggregate
                        ?.policySoldSum?.amount ? (
                      formatCurrency(
                        PoliciesData?.policies_aggregate?.aggregate
                          ?.policySoldSum?.amount
                      )
                    ) : (
                      formatCurrency(0)
                    )}
                  </h3>
                  <p className="cardSubTitle font-loader">
                    {' '}
                    Total value of policies sold
                  </p>
                </CardContent>
              </Card>
            </Grid>

            <Grid style={{ marginBottom: 25.2, marginLeft: 0 }}>
              <Card className="valuesCard">
                <CardContent>
                  <h3 className="cardHeader font-loader">
                    {' '}
                    {PoliciesLoading ? (
                      <CircularProgress />
                    ) : PoliciesData?.policies_aggregate?.aggregate
                        ?.policySummary?.total_paid ? (
                      formatCurrency(
                        PoliciesData?.policies_aggregate?.aggregate
                          ?.policySummary?.total_paid
                      )
                    ) : (
                      formatCurrency(0)
                    )}
                  </h3>
                  <p className="cardSubTitle font-loader">
                    Total of Payments Recieved for Policies
                  </p>
                </CardContent>
              </Card>
            </Grid>
            <Grid style={{ marginBottom: 33, marginLeft: 0 }}>
              <Card className="valuesCard">
                <CardContent>
                  <h3 className="cardHeader font-loader">
                    {PoliciesLoading ? (
                      <CircularProgress />
                    ) : (
                      PoliciesData?.policies_aggregate?.aggregate?.policyCount
                    )}
                  </h3>
                  <p className="cardSubTitle font-loader">
                    Total policies sold
                  </p>
                </CardContent>
              </Card>
            </Grid>

            <Grid>
              <Card className="quoteBarPlot">
                <CardContent>
                  {/* { isMobile ? <BarChart width={5} height={150} titleFont={12} chartData={quoteSent} /> :  <BarChart width={12} height={220} titleFont={20} chartData={quoteSent} />}   */}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
