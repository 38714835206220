export const months = [
  { month: 1, id: 30 },
  { month: 2, id: 60 },
  { month: 3, id: 90 },
  { month: 4, id: 120 },
  { month: 5, id: 150 },
  { month: 6, id: 180 },
  { month: 7, id: 210 },
  { month: 8, id: 240 },
  { month: 9, id: 270 },
  { month: 10, id: 300 },
  { month: 11, id: 330 },
  { month: 12, id: 360 },
  { month: 24, id: 720 },
  { month: 36, id: 1080 }
]

export const PoilcyDetails = {
  name: 'Policy Information',
  content: [
    {
      title: 'Tell us about your preferred Policy',
      questions: [
        {
          label: 'When do you want the policy to start?',
          type: 'date',
          name: 'inception_date'
        },
        {
          label: 'When do you want policy to end?',
          type: 'select',
          name: 'interval'
        },
        {
          label: 'What is the Physical Address of your home?',
          type: 'text',
          name: 'physical_address'
        },
        {
          label: 'What is the Digital Address of your home?',
          type: 'text',
          name: 'digital_address'
        }
      ]
    }
  ]
}

export const Building = {
  name: 'Building Insurance',
  content: [
    {
      title: 'Tell Us about your Building Policy',
      questions: [
        {
          label: 'Have you insured the building before?',
          type: 'select',
          name: 'have_insured_building_before'
        },
        {
          label: 'If yes, what type of insurance did you have?',
          type: 'text',
          name: 'prev_building_insurance',
          placeholder: 'Type N/A if you have not had previous insurance'
        }
      ]
    },
    {
      title: 'Tell us about your building',
      questions: [
        {
          label: 'What type of building is this?',
          type: 'select',
          name: 'building_type',
        },
        {
          label:
            'What colour is the building? [include colours for walls and roof]',
          type: 'text',
          name: 'building_color',
        },
        {
          label: 'What year was this building constructed?',
          type: 'select',
          name: 'construction_year'
        }
      ]
    },
    {
      title: 'Tell us about your building',
      questions: [
        {
          label: 'Is this a residential building?',
          type: 'select',
          name: 'is_residential'
        },
        {
          label: 'Do you conduct any business or trade in the building?',
          type: 'select',
          name: 'is_for_business'
        },
        {
          label:
            'Does any institution have a financial interest in the building?',
          type: 'select',
          name: 'has_financial_institution_interest'
        },
        {
          label: 'Do you have any financial interest in the building?',
          type: 'select',
          name: 'has_financial_interest'
        },
        {
          label: 'Do you want to include any buyback excess in your policy?',
          type: 'select',
          name: 'has_buy_back'
        },
        {
          label: 'Do you want to insure your walls?',
          type: 'select',
          name: 'insure_walls'
        }
      ]
    },
    {
      title: 'Other Building Information',
      questions: [
        {
          label: 'What materials is the building made of?',
          type: 'check',
          name: 'building_materials'
        },
        {
          label: 'What materials is the roof made of?',
          type: 'check',
          name: 'roofing_materials'
        }
      ]
    },
    {
      title: 'Other Building Information',
      questions: [
        {
          label: 'What is the cost of rebuilding the wall?',
          type: 'number',
          name: 'wall_rebuilding_cost',
          placeholder: 'Type 0 if this is not applicable to you'
        },
        {
          label: 'What is the cost of rebuilding your home?',
          type: 'number',
          name: 'rebuidling_cost',
          placeholder: 'Type 0 if this is not applicable to you'
        }
      ]
    }
  ]
}

export const Content = {
  name: 'Content Insurance',
  content: [
    {
      title: 'Tell us about your Content Policy',
      questions: [
        {
          label: 'Have you insured your content before?',
          type: 'select',
          name: 'have_insured_content_before'
        },
        {
          label: 'If yes, what type of insurance did you have?',
          type: 'text',
          name: 'prev_content_insurance',
          placeholder: 'Type N/A if you have not had previous insurance'
        }
      ]
    },
    {
      title: 'Content Information',
      questions: [
        {
          label: 'Please add the items you want to insure',
          type: 'array',
          name: 'content_items'
        },
        {
          label: 'How much do you want as your content sum assured?',
          type: 'number',
          name: 'content_sum_assured'
        },
        {
          label: 'Who are you insuring for?',
          type: 'array',
          name: 'content_people'
        }
      ]
    }
  ]
}

export const Personal_Accident = {
  name: 'Personal Accident',
  content: [
    {
      title: 'Tell us about Personal Accident Policy',
      questions: [
        {
          label: 'Have you been insured against personal accidents before?',
          type: 'select',
          name: 'have_been_personally_insured'
        },
        {
          label: 'If yes, what type of insurance did you have?',
          type: 'text',
          name: 'prev_personal_accident_policy',
          placeholder: 'Type N/A if you have not had previous insurance'
        },
        {
          label: 'How much do you want as your personal accident sum assured?',
          type: 'number',
          name: 'personal_accident_sum_assured'
        }
      ]
    }
  ]
}

export const Public_Liability = {
  name: 'Personal Liability',
  content: [
    {
      title: 'Tell Us about your Public Liability Policy',
      questions: [
        {
          label: 'Have you been insured for public liability before?',
          type: 'select',
          name: 'have_been_public_liability_insured'
        },
        {
          label: 'If yes, what type of insurance did you have?',
          type: 'text',
          name: 'prev_public_liability_policy',
          placeholder: 'Type N/A if you have not had previous insurance'
        },
        {
          label: 'How much do you want as your public liability sum assured? ',
          type: 'number',
          name: 'public_liability_sum_assured'
        }
      ]
    }
  ]
}

export const Rent = {
  name: 'Rent Insurance',
  content: [
    {
      title: 'Tell Us about your Rent Policy',
      questions: [
        {
          label: 'Have you been insured for rent before?',
          type: 'select',
          name: 'have_been_rent_insured'
        },
        {
          label: 'If yes, what type of insurance did you have?',
          type: 'text',
          name: 'prev_rent_policy',
          placeholder: 'Type N/A if you have not had previous insurance'
        },
        {
          label: 'How much do you want as your rent sum assured?  ',
          type: 'number',
          name: 'rent_sum_assured'
        }
      ]
    }
  ]
}

export const profileinfoLoggedIn = {
  name: 'Policy Holder Information',
  content: [
    {
      title: 'Who is the cover for?',
      questions: [
        {
          label: 'Are you purchasing insurance for yourself?',
          type: 'select',
          name: 'purchase_for_self',
          placeholder: ''
        }
      ]
    },
    {
      title: 'Beneficiary Details (If Any)',
      questions: [
        {
          label: 'Full name',
          type: 'text',
          name: 'beneficiary_name',
          placeholder: 'First Name | Middle Name | Last Name'
        },
        {
          label: 'Email',
          type: 'email',
          name: 'beneficiary_email',
          placeholder: 'edgarpoe@email.com'
        },
        {
          label: 'Phone',
          type: 'text',
          name: 'beneficiary_phone_number',
          placeholder: '020 000 0000'
        },
        {
          label: 'ID',
          type: 'text',
          name: 'beneficiary_id',
          placeholder: 'Input Beneficiary Ghana card number'
        }
      ]
    }
  ]
}

export const profileinfo = {
  name: 'Policy Holder Information',
  content: [
    {
      title: 'Tell us about You',
      questions: [
        {
          label: 'Are you purchasing insurance for yourself?',
          type: 'select',
          name: 'purchase_for_self',
          placeholder: ''
        },
        {
          label: 'Full name',
          type: 'text',
          name: 'fullName',
          placeholder: 'First Name | Middle Name | Last Name'
        },
        {
          label: 'Email',
          type: 'email',
          name: 'email',
          placeholder: 'edgarpoe@email.com'
        },
        {
          label: 'Password',
          type: 'password',
          name: 'password',
          placeholder: ''
        },
        {
          label: 'Phone',
          type: 'text',
          name: 'phone_number',
          placeholder: '020 000 0000'
        },
        {
          label: 'ID',
          type: 'text',
          name: 'national_id',
          placeholder: 'Input Ghana card number'
        },
        {
          label: 'Gender',
          type: 'select',
          name: 'gender',
          placeholder: ''
        },
        {
          label: 'Country of Origin',
          type: 'select',
          name: 'country',
          placeholder: ''
        },
        {
          label: 'Date of Birth',
          type: 'date',
          name: 'dob',
          placeholder: ''
        },
        {
          label: 'Digital Address',
          type: 'text',
          name: 'digital_address',
          placeholder: ''
        }
      ]
    },
    {
      title: 'Beneficiary of Insurance',
      questions: [
        {
          label: 'Full name',
          type: 'text',
          name: 'beneficiary_name',
          placeholder: 'First Name | Middle Name | Last Name'
        },
        {
          label: 'Email',
          type: 'email',
          name: 'beneficiary_email',
          placeholder: 'edgarpoe@email.com'
        },
        {
          label: 'Phone',
          type: 'text',
          name: 'beneficiary_phone_number',
          placeholder: '020 000 0000'
        },
        {
          label: 'ID/Company Number',
          type: 'text',
          name: 'beneficiary_id',
          placeholder: 'Input Beneficiary Ghana card number'
        }
      ]
    }
  ]
}
