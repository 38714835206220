import React, { useEffect } from 'react'
import fail from '../../assets/img/fail.png'
import WebFont from 'webfontloader'
import './index.css'

const ErrorPage = ({ error, resetErrorBoundary }) => {
  // const history = useHistory()

  // const message = props?.location?.state;
 
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    })
  }, [])

  return (
    <div>
      <div className='main-container'>
        <div className='logo-container'>
          <img src={fail} className='logo' />
        </div>
        <div>
          <h3 className='title font-loader'>Something went wrong</h3>
        </div>
        <div className='sub-title-container'>
          <h5 className='sub-title font-loader'>
            {error?.message}
          </h5>
        </div>
        <div className='button-container'>
          <input
            style={{
              color: '#F6F8FB',
              backgroundColor: '#2E79E9',
              border: 'none',
              width: '160px',
              height: '40px',
              fontSize: '14px',
              cursor: 'pointer',
              margin: 15
            }}
            className='getstarted font-loader'
            type='button'
            value='Try again'
            onClick={resetErrorBoundary}
          />

          {/* <input
            style={{
              color: '#2E79E9',
              backgroundColor: '#F6F8FB',
              border: 'solid #2E79E9',
              width: '160px',
              height: '40px',
              fontSize: '14px',
              cursor: 'pointer',
              margin: 15
            }}
            className='getstarted font-loader'
            type='button'
            value='Contact Support'
            onClick={() => history.push('/')}
          /> */}
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
