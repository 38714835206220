import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import WebFont from 'webfontloader';
import './style.css';
import logo from '../assets/img/welcome.png'


function NotVerified() {


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Poppins']
            }
        });
    }, []);

    const { logout } = useAuth0();
    return (
        <div className='main-container'>
            <div className='logo-container'>
                <img className='logo' src={logo} />
            </div>
            <div>
                <h3 className='title font-loader'>Welcome to MyPolicy.market</h3>
            </div>
            <div className='sub-title-container'>
                <h5 className='sub-title font-loader'>We are verifying your information. Account confirmation will be sent to your email soon.</h5>
            </div>
            <div>
                <input
                    style={{
                        color: '#F6F8FB',
                        backgroundColor: '#2E79E9',
                        border: 'none',
                        width: '160px',
                        height: '40px',
                        fontSize: '14px',
                        cursor: "pointer"
                    }}
                    className='getstarted font-loader' type="button" value="Back to home" 
                    onClick={() => logout({ returnTo: window.location.origin }, localStorage.clear())} />
            </div>
        </div>
    );
}

export default NotVerified;