import React, { Component, useContext } from 'react';
import MUIDataTable from 'mui-datatables';
import { formatDate } from 'src/utils/FormatDate';
import { formatCurrency } from '../../../utils/FormatCurrency';
import { isMobile } from 'react-device-detect';
import './index.css';


const PolicyTable = ({ policies }) => {

  const columns = [
    {
      name: 'quote',
      label: 'Insurance Type',
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (val) => {
          return val?.request?.request_type?.name
        }
      }
    },
    {
      name: 'amount',
      label: 'Premium',
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (val) => {
          return formatCurrency(val)
        }
      }
    },
    {
      name: 'quote',
      label: 'Insurance Company',
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (val) => {
          return val?.insurance_company?.name
        }
      }
    },
    {
      name: 'validity_end',
      label: 'Expiry Date',
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (val) => {
          return formatDate(val);
        }
      }
    },
  ];


  const options = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    customToolbarSelect: () => {},
    download: false,
    search: false,
    viewColumns: false,
    selectableRows: false,
    rowsPerPage: 3,
    responsive: 'simple',
    sortOrder: {
      name: 'Date_Purchased',
      direction: 'desc'
    }
  };

  return(
    <div style={{ padding: 15, paddingLeft: isMobile ? 20: 0, paddingRight: isMobile ? 20 : 0 }}>
      <MUIDataTable
          data={policies   ?? []}
          columns={columns}
          options={options}
        />
    </div>
  )

}

export default PolicyTable;

