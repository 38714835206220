import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Drawer,
  Hidden,
  List,
  IconButton,
  makeStyles
} from '@material-ui/core'
import {

  Layers as QuotesIcon,
  TrendingUp as PolicySaleIcon,
  MessageSquare as RequestIcon,
  Settings as SeetingsIcon
} from 'react-feather'
import {
  HomeIcon,
  ChatBubbleLeftRightIcon,
  ArrowLeftOnRectangleIcon,
  FolderOpenIcon,
  UserCircleIcon,
  ClipboardDocumentCheckIcon
} from '@heroicons/react/24/outline'
import NavItem from './NavItem'
import { useAppContext } from '../../../Context'
import { useAuth0 } from '@auth0/auth0-react'
import logo from '../../../assets/img/logo.png'
import { browserNotification } from 'src/utils/lib'

const items = [
  {
    href: '/intermediary',
    icon: HomeIcon,
    title: 'Dashboard'
  },

  {
    href: '/intermediary/requests',
    icon: RequestIcon,
    title: 'Requests'
  },
  {
    href: '/intermediary/quotes',
    icon: QuotesIcon,
    title: 'Quotes'
  },
  {
    href: '/intermediary/policies',
    icon: PolicySaleIcon,
    title: 'Policy Sales'
  },
  // {
  //   href: '/intermediary/report',
  //   icon: AnalyticsIcon,
  //   title: 'Reports & Analysis'
  // },
  {
    href: '/intermediary/settings',
    icon: SeetingsIcon,
    title: 'Settings'
  }
]

const customeritems = [
  {
    href: '/customer',
    icon: HomeIcon,
    title: 'My Dashboard',
  },

  {
    href: '/customer/myrequests',
    icon: ChatBubbleLeftRightIcon,
    title: 'My Requests',
  },
  {
    href: '/customer/acceptedquotes',
    icon: ClipboardDocumentCheckIcon,
    title: 'My Accepted Quotes',
  },
  {
    href: '/customer/customerpolicy',
    icon: FolderOpenIcon,
    title: 'My Policies',
  },
  {
    href: '/customer/profilesettings',
    icon: UserCircleIcon,
    title: 'Account',
  }
]

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
    height: '100%'
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: '100%'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    position: 'absolute',
    top: -120,
    left: 60
  }
}))

const NavBar = ({ onMobileClose, openMobile, usertype }) => {
  const classes = useStyles()
  const { logout } = useAuth0()
  const context = useAppContext()
  const { dispatch } = context
  const [isUser, setUser] = useState(usertype)

 

 

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }

    // console.log(formatteddata)

    if (isUser === 'Proxy') {
      browserNotification("Welcome Back")
      dispatch({
        type: 'SET_PAGE_HEADER',
        payload: 'Dashboard'
      })
    } else {
      dispatch({
        type: 'SET_PAGE_HEADER',
        payload: 'Customer Dashboard'
      })
    }
  }, [usertype])

  const content = (
    <Box
      style={{
        position: 'relative',
        height: '100%',
        overflowY: 'hidden'
      }}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      {/* <Divider /> */}
      <Box
        style={{
          position: 'relative',
          top: 130,
          marginBottom: 10,
          overflowY: 'auto'
        }}
        p={2}
      >
        {usertype === 'Proxy' ? (
          <List>
            {/* <Avatar className={classes.avatar} src={profile_pic}></Avatar> */}
            <img
              style={{ position: 'relative', width: '90%', marginTop: '-20%' }}
              src={logo}
            />

            {items.map(item => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                onMobileClose={onMobileClose}
              />
            ))}
          </List>
        ) : (
          <List>
            {/* <Avatar className={classes.avatar} src={profile_pic}></Avatar> */}

            <img
              style={{ position: 'relative', width: '90%', marginTop: '-20%' }}
              src={logo}
            />
            <div style={{ marginTop: '-10%' }}>
              {customeritems.map(item => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  onMobileClose={onMobileClose}
                />
              ))}
            </div>
          </List>
        )}
      </Box>
      <Box
        style={{
          backgroundColor: '#0E3E86',
          height: 70,
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: 0
        }}
      >
        <IconButton
          onClick={() => {
            logout({ returnTo: window.location.origin }, localStorage.clear())
          }}
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            paddingRight: 40,
            width: "100%",
          }}
        >
          <ArrowLeftOnRectangleIcon style={{ color: 'white', width: 30, height: 30,}} />
          <p
            style={{
              color: 'white',
              fontSize: 15,
              marginLeft: 10,
            }}
          >
            Sign out
          </p>
        </IconButton>
      </Box>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor='left'
          classes={{ paper: classes.desktopDrawer }}
          open
          variant='persistent'
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
}

export default NavBar
