import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import success from '../../assets/img/success.png';
import WebFont from 'webfontloader';
import './style.css';


function SuccessfulPaymentPage() {
    const navigate = useNavigate();
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Poppins']
            }
        });
    }, []);

    return (
        <div className='main-container'>
            <div className='logo-container'>
                <img className='logo' src={success} />
            </div>
            <div>
                <h3 className='title font-loader'>Transaction Successful!</h3>
            </div>
            {/* <div className='sub-title-container'>
                <h5 className='sub-title font-loader'>Continue to view receipt.</h5>
            </div> */}
            <div>
                <input
                    style={{
                        color: '#F6F8FB',
                        backgroundColor: '#2E79E9',
                        border: 'none',
                        width: '160px',
                        height: '40px',
                        fontSize: '14px',
                        cursor: "pointer"
                    }}
                    className='getstarted font-loader' type="button" value="Back to home"
                    onClick={() => navigate("/customer")}/>
            </div>
        </div>
    );
}

export default SuccessfulPaymentPage;