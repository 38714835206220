import React from 'react'
import { Form } from 'react-bootstrap'

import { useQuery, gql } from '@apollo/client'
import { useAppContext } from 'src/Context'



const GET_BUILDING_MATERIALS = gql`
query getBuildingMaterials {
  references(where: {reference_type_id: {_eq: 2}}) {
    id
    name
  }
}
`

const GET_ROOFING_MATERIALS = gql`
query getRoofingMaterials {
  references(where: {reference_type_id: {_eq: 4}}) {
    id
    name
  }
}
`

const MaterialsPicker = ({ name, materials, label, handleChange }) => {


  const { data: building_materials } = useQuery(GET_BUILDING_MATERIALS)

  const { data: roofing_materials } = useQuery(GET_ROOFING_MATERIALS)


  // const handleBuildingMaterialChange = (e) => {
  //   const { value, checked, name } = e.target;

  //   if (checked) {
  //       materials?.push({ building_material_id: value })
  //   } else {
  //       materials = materials?.filter(material => {
  //           return material.building_material_id != value
  //         })
  //   }
  // }

  // const handleRoofingMaterialChange = (e) => {
  //   const { value, checked, name } = e.target;

  //   if (checked) {
  //       materials?.push({ roofing_material_id: value })
  //   } else {
  //       materials = materials?.filter(material => {
  //           return material.roofing_material_id != value;
  //         });
  //   }
  // }

  return (
    <div>
      {name === 'building_materials' ? (
        <Form>
          <Form.Group>
            <Form.Label>
              <span style={{ fontSize: 25 }}>{label}</span>
              <span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around'
              }}
            >
              {building_materials?.references.map((option, key) => {
                return (
                  <Form.Check
                    key={key}
                    value={option.name}
                    label={option.name}
                    onChange={handleChange}
                    name='building_materials'
                    checked={materials?.includes(option.name)}
                  />
                )
              })}
            </div>
          </Form.Group>
        </Form>
      ) : (
        <Form>
          <Form.Group>
            <Form.Label>
              <span style={{ fontSize: 25 }}>{label}</span>
              <span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around'
              }}
            >
              {roofing_materials?.references.map((option, key) => {
                return (
                  <Form.Check
                    key={key}
                    value={option.name}
                    label={option.name}
                    onChange={handleChange}
                    name='roofing_materials'
                    checked={materials?.includes(option.name)}
                  />
                )
              })}
            </div>
          </Form.Group>
        </Form>
      )}
    </div>
  )
}

export default MaterialsPicker
