import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { client } from './AppoloClient';
import Spinner from './components/Spinner';
import { Content } from './Content';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './views/Error';

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <BrowserRouter>
              <ToastContainer />
              <React.Suspense fallback={<Spinner />}>
                <Content />
              </React.Suspense>
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
