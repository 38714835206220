import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import AppWrapper from './AppWrapper';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.createRoot(document.getElementById('root')).render(
  <Auth0Provider
    domain="althia.eu.auth0.com"
    clientId="0un6YbQwjXI5kOdbM0JqUAFKcGlrrDrs"
    redirectUri={window.location.origin}
  >
    <AppWrapper />
  </Auth0Provider>
);

serviceWorkerRegistration.register();
