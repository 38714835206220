import { set } from 'lodash'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { BiTrash } from 'react-icons/bi'
import './AddItemStyle.css'

function AddItems ({ name, label, setContents }) {
  const [items, setItems] = useState([])
  const [people, setPeople] = useState([])

  const [itemName, setItemName] = useState('')
  const [value, setValue] = useState('')
  const [quantity, setQuantity] = useState()

  //For Policy Persons
  const [personName, setPersonName] = useState('')
  const [occupation, setOccupation] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState()
  const [sumAssured, setSumAssured] = useState()

  const addItem = (itemName, value, quantity ) => {
    setItems([
      ...items,
      {
        name: itemName,
        value: Number(value),
        quantity: Number(quantity),
        total_value: Number(value) * Number(quantity)
      }
    ])

    return items;
  }

  const addPeople = (personName, occupation, dateOfBirth, sumAssured) => {
    setPeople([
      ...people,
      {
        name: personName,
        occupation: occupation,
        date_of_birth: dateOfBirth,
        sum_assured: Number(sumAssured)
      }
    ])
    return people;
  }

  const removeItem = id => {
    if (name === 'policy_contents') {
      setItems(current =>
        current.filter(item => {
          return item.id !== id
        })
      )
    } else {
      setPeople(current => {
        current.filter(person => {
          return person.id != id
        })
      })
    }
  }

  return name === 'policy_contents' ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          paddingTop: 15,
          paddingLeft: 5,
          paddingRight: 5
        }}
      >
        <div>
          <Form.Label>
            {label}
            <span style={{ color: 'red' }}>*</span>
          </Form.Label>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 800
          }}
        >
          <Form.Group className='mb-1 mr-1' style={{ width: '20%' }}>
            <Form.Control
              type='text'
              placeholder='Item name'
              onChange={e => setItemName(e.target.value)}
              value={itemName}
            />
          </Form.Group>
          <Form.Group className='mb-1 mr-1' style={{ width: '20%' }}>
            <Form.Control
              type='number'
              placeholder='Quantity of item'
              onChange={e => setQuantity(e.target.value)}
              value={quantity}
            />
          </Form.Group>
          <Form.Group className='mb-1 mr-1' style={{ width: '20%' }}>
            <Form.Control
              type='number'
              placeholder='Value of item'
              onChange={e => setValue(e.target.value)}
              value={value}
            />
          </Form.Group>
          <button
            className='btn btn-primary'
            style={{ height: 40 }}
            onClick={e => {
              e.preventDefault()
              setContents('policy_contents', addItem(itemName, value, quantity)) 
              setItemName('')
              setValue('')
              setQuantity('')
            }}
          >
            Add item
          </button>
        </div>
      </div>
      <hr />
      <div
        style={{
          position: 'relative',
          marginTop: -30,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: 800,
          textAlign: 'center',
          alignItems: 'center',
          marginBottom: 10
        }}
      >
        {items.length > 0 ? (
          items.map((item, index) => (  
            <div
              key={index}
              className='mb-1 mr-2'
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: 10,
                paddingTop: 20,
                margin: 10,
                backgroundColor: 'orange',
                borderRadius: 5,
                color: 'white'
              }}
            >
              <p style={{ marginRight: 5, fontSize: 18 }}>
                {item.name} - GHS{item.total_value} - {item.quantity} 
              </p>
              <BiTrash
                size={24}
                style={{ cursor: 'pointer' }}
                onClick={() => removeItem(item.id)}
              />
              <hr />
            </div>
          ))
        ) : (
          <p style={{ position: 'relative', left: 260 }}>No Items added yet</p>
        )}
      </div>
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          paddingTop: 15,
          paddingLeft: 5,
          paddingRight: 5
        }}
      >
        <div>
          <Form.Label>
            {label}
            <span style={{ color: 'red' }}>*</span>
          </Form.Label>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 800
          }}
        >
          <Form.Group className='mb-1 mr-1' style={{ width: '20%' }}>
            <Form.Control
              type='text'
              placeholder='Person name'
              onChange={e => setPersonName(e.target.value)}
              value={personName}
            />
          </Form.Group>
          <Form.Group className='mb-1 mr-1' style={{ width: '20%' }}>
            <Form.Control
              type='text'
              placeholder='Person occupation'
              onChange={e => setOccupation(e.target.value)}
              value={occupation}
            />
          </Form.Group>
          <Form.Group className='mb-1 mr-1' style={{ width: '20%' }}>
            <Form.Control
              type='date'
              placeholder=''
              onChange={e => setDateOfBirth(e.target.value)}
              value={dateOfBirth}
            />
          </Form.Group>
          <Form.Group className='mb-1 mr-1' style={{ width: '20%' }}>
            <Form.Control
              type='number'
              placeholder='Sum assured'
              onChange={e => setSumAssured(e.target.value)}
              value={sumAssured}
            />
          </Form.Group>
          <button
             className='btn btn-primary'
            style={{ height: 40 }}
            onClick={e => {
              e.preventDefault()
              setContents('policy_persons',  addPeople(personName, occupation, dateOfBirth, sumAssured))
              setPersonName('')
              setOccupation('')
              setDateOfBirth('')
              setSumAssured('')
            }}
          >
            Add item
          </button>
        </div>
      </div>
      <hr />
      <div
        style={{
          position: 'relative',
          marginTop: -30,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: 800,
          textAlign: 'center',
          alignItems: 'center',
          marginBottom: 10
        }}
      >
        {people.length > 0 ? (
          people.map((person, index) => (
            <div
              key={index}
              className='mb-1 mr-2'
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: 10,
                paddingTop: 20,
                margin: 10,
                backgroundColor: 'orange',
                borderRadius: 5,
                color: 'white'
              }}
            >
              <p style={{ marginRight: 5, fontSize: 18 }}>
                {person.name} - {person.date_of_birth} - GHS{person.sum_assured}
              </p>
              <BiTrash
                size={24}
                style={{ cursor: 'pointer' }}
                onClick={() => removeItem(person.id)}
              />
              <hr />
            </div>
          ))
        ) : (
          <p style={{ position: 'relative', left: 260 }}>
            No Persons added yet
          </p>
        )}
      </div>
    </div>
  )
}

export default AddItems
