import { BsFillCloudUploadFill } from 'react-icons/bs'
import React, { useState, useEffect } from 'react'
import { useUploadForm } from './Hook'
// import { BsCloudUpload } from 'react-icons/bs'
import Button from 'react-bootstrap/Button'
// import ProgressBar from 'react-bootstrap/ProgressBar'
// import Alert from 'react-bootstrap/Alert'
// import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import leftGrid from '../../assets/img/Repeat_Grid_1.png'
import axios from 'axios'
// import FileUploader from 'src/views/auth/FileUploader'
import {
  UPLOAD_MOTOR_REQUEST_DOCS_FOR_EXISTING_USER,
  SIGN_UP_CUSTOMER,
  SUBMIT_REGISTERED_CUSTOMER_REQUEST
} from 'src/Graphql/Mutations'
import { CUSTOMER_PIE_PLOT } from 'src/Graphql/Queries'
import { useMutation, useQuery, gql, useLazyQuery } from '@apollo/client'
import { SuccessNotification, ErrorNotification } from '../Notification'
import { generatePassword } from 'src/utils/FormatDate'
import { useNavigate } from "react-router-dom";
import { BiArrowBack, BiTrash } from 'react-icons/bi'
import logo from '../../assets/img/welcome.png'
import { Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import { formatDate, addDays } from 'src/utils/FormatDate'
import FormOptions from '../AnimatedCard/FormOptions'
import { months } from '../AnimatedCard/AllQuestions'
import { isMobile } from 'react-device-detect'
import WebFont from 'webfontloader'
import '../../views/auth/styles.css'
import myPolicyLogo from '../../assets/img/MyPolicy.svg'
import customLoading from '../../assets/img/logo_animation.gif'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import FormOptionsV2 from '../AnimatedCard/FormOptionsV2'

const GET_MOTOR_INSURANCE_OPTIONS = gql`
  query getMotorOptions {
    request_types(where: { id: { _eq: 1 } }) {
      properties
    }
  }
`

const GET_COUNTRIES = gql`
  query getCountries {
    countries {
      id
      name
    }
  }
`

const GET_CUSTOMER_BY_EMAIL = gql`
query getCustomerByEmail($email: jsonb) {
  customers(where: {emails: {_eq: $email}}) {
    id
  }
}
`

const UploadFile = () => {
  const [worthyDocument, setworthDocument] = useState(null)
  const [period, setPeriod] = useState('yyyy/mm/dd')
  const [firstPic, setFirstPic] = useState(null)
  const [backPic, setBackPic] = useState(null)
  const navigate = useNavigate();
  const [customerID, setCustomerID] = useState(null)

  const [getCustomerByEmail] = useLazyQuery(GET_CUSTOMER_BY_EMAIL, {
    onCompleted: data => {
      if(data.customers?.length > 0){
        ErrorNotification("Sorry email belongs to existing user");
      }else {
        signUpCustomer();
      }
    }
  })

  const FILE_SIZE = 10000000
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png'
  ]

  let currentDate = new Date()
  currentDate.setDate(currentDate.getDate() - 1)

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      phoneNumber: '',
      digital_address: '',
      dob: '',
      id_no: '',
      buy_back_excess: '',
      gender: '',
      password: '',
      country: '',
      inception_date: '',
      interval: '',
      expiry_date: '',
      insurance_product_id: '',
      vehicle_cost: '',
      road_worthy_front: null,
      road_worth_back: null,
      vehicle_img: null,
      motorInsuranceType: ''
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required('Please enter fullname'),
      email: Yup.string()
        .email('Enter valid email')
        .required('Please enter an email'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/,
          'Should contain at least one Upper Case, one Lower Case, one number and a special character'
        )
        .required('Please enter a password'),
      // country: Yup.string().required('Select a country'),
      digital_address: Yup.string()
        .matches(
          /^[A-Z]{2}-[0-9]{3,4}-[0-9]{4}$/,
          'Please enter a valid digital'
        )
        .required('Kindly Provide your digital address'),
      id_no: Yup.string()
        .required('ID number is required')
        .matches(/^[A-Z]{3}-[0-9]{9}-[0-9]{1}$/, 'Invalid ID number'),
      dob: Yup.date().required('Kindly provide your first name').nullable(),
      phoneNumber: Yup.string()
        .max(10, 'Must be 10 numbers or less')
        .matches(/[0-9]{3}[0-9]{3}[0-9]{4}/, 'Invalid phone number')
        .required('Please enter a phone number'),
      inception_date: Yup.date()
        .min(
          currentDate,
          'Policy Start date must be equal or greater then current date '
        )
        .required('Kindly indicate when you want your insurance to start'),
      expiry_date: Yup.string().required(
        'Please choose when you want ypur policy to end'
      ),
      country: Yup.string().required('Select a country'),
      insurance_product_id: Yup.string().required(
        'Please choose the policy type you want'
      ),
      road_worthy_front: Yup.mixed()
        .required('Please upload your road worthy document(front)')
        .test(
          'fileSize',
          'File is too large',

          value => value && value.size <= FILE_SIZE
        ),

      road_worth_back: Yup.mixed()

        .required('Please upload your road worthy document(back)')
        .test(
          'fileSize',

          'File is too large',

          value => value && value.size <= FILE_SIZE
        ),

      vehicle_img: Yup.mixed()

        .required('Please upload your vehicle image')
        .test(
          'fileSize',

          'File is too large',

          value => value && value.size <= FILE_SIZE
        )
    }),

    onSubmit: values => {
      confirmSubmission()
      // console.log(values)
    }
  })

  useEffect(() => {
    if (customerID) {
      SubmitMotorPolicyRequest()
    }
  }, [customerID])

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    })
  }, [])

  const { data: insuranceOptionsData, loading: insuranceOptionsLoading } =
    useQuery(GET_MOTOR_INSURANCE_OPTIONS)

  const { data: CountriesData } = useQuery(GET_COUNTRIES)

  const [loading, setLoading] = useState(false)

  const [
    SubmitMotorPolicyRequest,
    { motorPolicyRequestError, motorPolicyRequestLoading }
  ] = useMutation(SUBMIT_REGISTERED_CUSTOMER_REQUEST, {
    variables: {
      created_by: 'self_upload',
      customer_id: customerID,
      request_type_id: 1,
      status: 'pending',
      motor_details: {
        inception_date: formik.values.inception_date,
        expiry_date: formik.values.expiry_date,
        insurance_product_id: formik.values.insurance_product_id,
        vehicle_cost: formik.values.vehicle_cost,
        interval: formik.values.interval,
        motorInsuranceType: formik.values.motorInsuranceType,
        buy_back_excess: formik.values.buy_back_excess
      },
      home_details: null
    },
    onCompleted: async data => {
      let roadworthyFront = await uploadFile(
        `road_worthy_front`,
        formik.values.road_worthy_front,
        data.insert_requests_one.id
      )

      let roadworthyBack = await uploadFile(
        `road_worthy_back`,
        formik.values.road_worth_back,
        data.insert_requests_one.id
      )

      let vehicleImage = await uploadFile(
        `vehicleImage`,
        formik.values.vehicle_img,
        data.insert_requests_one.id
      )

      if (
        roadworthyFront.data.data.id &&
        roadworthyBack.data.data.id &&
        vehicleImage.data.data.id
      ) {
        SuccessNotification('Successfully submitted request')
        navigate('/')
      } else {
        ErrorNotification('Request failed to upload')
      }
    },
    onError: error => console.log(error)
    // refetchQueries: [
    //   { query: CUSTOMER_PIE_PLOT, variables: { customer_id: customerID } },
    //   'getPiePlotData'
    // ]
  })

  const [signUpCustomer, { error, loading: RequestLoading }] = useMutation(
    SIGN_UP_CUSTOMER,
    {
      variables: {
        first_name: formik.values.fullname.split(' ')[0],
        last_name:
          formik.values.fullname.split(' ')[
            formik.values.fullname.split(' ').length - 1
          ],
        other_names: formik.values.middleName ? formik.values.middleName : '',
        emails: [formik.values.email],
        phone_numbers: [formik.values.phone],
        dob: formik.values.dob,
        gender: formik.values.gender,
        id_no: formik.values.id_no,
        country: formik.values.country,
        digital_address: formik.values.digital_address,
        created_by: 'self'
      },
      onCompleted: data => {
        setCustomerID(data.insert_customers_one.id)
      },
      onError: error => console.log(error)
    }
  )

  const [
    uploadDocsExistingUser,
    {
      error: uploadDocsExistingUserError,
      loading: uploadDocsExistingUserLoading
    }
  ] = useMutation(UPLOAD_MOTOR_REQUEST_DOCS_FOR_EXISTING_USER, {
    onCompleted: data => {
      SuccessNotification('Request sucessfully sent')
     navigate('/customer')
    },
    onError: error => console.log(error)
  })

  const createToken = () => {
    var data = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      audience: process.env.REACT_APP_AUDIENCE,
      grant_type: 'client_credentials'
    }

    setLoading(true)
    var config = {
      method: 'post',
      url: 'https://althia.eu.auth0.com/oauth/token',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    }

    axios(config)
      .then(function (response) {
        const token = response.data.access_token
        signup0(token)
      })
      .catch(function (error) {
        console.log(error)
        setLoading(false)
      })
  }

  const signup0 = async token => {
    var data = JSON.stringify({
      connection: 'Username-Password-Authentication',
      email: formik.values.email,
      password: formik.values.password
    })

    var config = {
      method: 'post',
      url: 'https://althia.eu.auth0.com/api/v2/users',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    }

    axios(config)
      .then(function (response) {
        setLoading(false)
        // SubmitMotorPolicyRequest()
        // console.log(response)
        if (response.data.user_id && response.status === 201) {
          signUpCustomer()
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error.response)
        ErrorNotification('Sign Up failed')
      })
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo
      let baseURL = ''
      // Make new FileReader
      let reader = new FileReader()

      // Convert the file to base64 text
      reader.readAsDataURL(file)

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        baseURL = reader.result.replace('data:', '').replace(/^.+,/, '')

        // console.log(baseURL);
        resolve(baseURL)
      }
      // console.log(fileInfo);
    })
  }

  const checkUser = (email) => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/check-user?email=${email}`)
    .then(response => {

      // console.log(response.data.data)

      if(response.data.data === email){
        getCustomerByEmail({variables:{
          email: [email]
        }})
      }else{
        createToken()
      }

    })
  }

  const uploadFile = async (fieldIdentifier, file, reference_id) => {

    let indexOfSlash = file.type.indexOf('/');
    let ext = file.type.slice((indexOfSlash+1), file.type.length)

    return getBase64(file).then(res => {
      const body = {
        key: `${fieldIdentifier}.${ext}`,
        type: 'request_image',
        contents: res,
        reference_id: reference_id,
        properties: '',
        created_by: {
          id: reference_id,
          username: formik.values.fullname,
          name: formik.values.fullname
        }
      }

      return axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/files/upload`,
        body
      )
    })
  }

  const setInceptionDate = e => {
    const value = e.target.value
    formik.setFieldValue(
      'inception_date',
      new Date(value).toISOString().substring(0, 10)
    )
  }

  const onPeriodInput = e => {
    const interval = e.target.value
    let initialdate = formik.values['inception_date']

    if (initialdate) {
      let finaldate = addDays(initialdate, parseInt(e.target.value))
      let formatteddate = formatDate(finaldate)
      formik.setFieldValue('expiry_date', finaldate)

      setPeriod(formatteddate)
    }
  }

  const confirmSubmission = () => {
    Swal.fire({
      title: 'Are you sure you want to submit this request?',
      text: 'You won\'t be able to revert this',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, accept',
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        checkUser(formik.values.email)
      }
    })
  }

  return (
    <>
      {insuranceOptionsLoading ? (
        <div>
          <img
            style={{
              position: 'relative',
              marginTop: isMobile ? '80%' : '20%',
              left: isMobile ? '35%' : '45%'
            }}
            src={customLoading}
          />
        </div>
      ) : (
        <div className='font-loader' style={container}>
          <div
            style={{
              display: 'flex',
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',

              alignContent: 'center',
              flexDirection: 'column',
              marginTop: 200,
              width: '100%'
            }}
          >
            <div>
              <header
                id='header'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'stretch'
                }}
                className='fixed-top'
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignSelf: 'stretch',
                    width: '100%',
                    padding: isMobile ? 0 : 5
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '75%'
                    }}
                  >
                    <div
                      style={{
                        margin: 15,
                        margin: isMobile ? 5 : 15,
                        left: isMobile ? -35 : 0
                      }}
                    >
                      <a href='/'>
                        <img
                          style={{
                            height: isMobile ? '18px' : '28px',
                            width: isMobile ? '129px' : '259.84px'
                          }}
                          src={myPolicyLogo}
                        />
                      </a>
                    </div>
                    <div>
                      {isMobile ? (
                        <>
                          <button
                            className='btn btn-primary'
                            onClick={e => {
                              e.preventDefault()
                             navigate(-1)
                            }}
                            style={{
                              backgroundColor: '#FFFFFF',
                              border: 'none',
                              fontWeight: 'bold'
                            }}
                          >
                            <AiOutlineArrowLeft
                              size={20}
                              style={{ color: '#2E79E9' }}
                            />
                          </button>
                        </>
                      ) : (
                        <input
                          className='getstarted font-loader scrollto'
                          onClick={e => {
                            e.preventDefault()
                           navigate(-1)
                          }}
                          style={{
                            color: '#F6F8FB',
                            backgroundColor: '#2E79E9',
                            border: 'none',
                            width: '160px',
                            height: '40px',
                            fontSize: '18px'
                          }}
                          type='button'
                          value='Go Back'
                        />
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </div>
            <Form
              // onSubmit={formik.handleSubmit}
              style={{
                width: '100%',
                position: 'relative',
                top: isMobile ? -100 : -75
              }}
            >
              <div style={isMobile ? wrapperMobile : wrapper}>
                <div style={center}>
                  <div style={card}>
                    <p style={{ fontWeight: 'bolder', fontSize: 15 }}>
                      Road worthy document(front)
                    </p>
                    {worthyDocument ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={URL.createObjectURL(worthyDocument)}
                          alt='selected file'
                          style={image}
                        />
                      </div>
                    ) : (
                      <div style={imageUpload}>
                        <input
                          style={fileUpload}
                          type='file'
                          onChange={event => {
                            // console.log(event.currentTarget.files[0].size)
                            formik.setFieldValue(
                              'road_worthy_front',
                              event.currentTarget.files[0]
                            )
                            setworthDocument(event.currentTarget.files[0])
                          }}
                          onBlur={formik.handleBlur}
                          accept='image/*'
                          name='road_worthy_front'
                        />
                        <div style={iconBox}>
                          <BsFillCloudUploadFill style={icon} />
                        </div>
                        <small
                          style={{
                            color: 'lightgray',
                            fontSize: 13,
                            marginTop: 10
                          }}
                        >
                          Files should not exceed 10MB
                        </small>
                      </div>
                    )}
                    {formik.touched.road_worthy_front &&
                      formik.errors.road_worthy_front && (
                        <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                          {formik.errors.road_worthy_front}
                        </p>
                      )}
                  </div>
                  {worthyDocument && (
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {' '}
                      <h1 style={subText}>{worthyDocument?.name}</h1>{' '}
                      <BiTrash
                        style={{
                          marginLeft: 10,
                          marginTop: 3,
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setworthDocument('')
                          formik.setFieldValue('road_worthy_front', null)
                        }}
                      />
                    </span>
                  )}
                </div>
                <div style={center}>
                  <div style={card}>
                    <p style={{ fontWeight: 'bolder', fontSize: 15 }}>
                      Road worthy document(back)
                    </p>
                    {firstPic ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={URL.createObjectURL(firstPic)}
                          alt='selected file'
                          style={image}
                        />
                      </div>
                    ) : (
                      <div style={imageUpload}>
                        <input
                          style={fileUpload}
                          type='file'
                          onChange={event => {
                            formik.setFieldValue(
                              'road_worth_back',
                              event.target.files[0]
                            )
                            setFirstPic(event.currentTarget.files[0])
                          }}
                          onBlur={formik.handleBlur}
                          accept='image/*'
                          name='road_worth_back'
                        />

                        <div style={iconBox}>
                          <BsFillCloudUploadFill style={icon} />
                        </div>
                        <small
                          style={{
                            color: 'lightgray',
                            fontSize: 13,
                            marginTop: 10
                          }}
                        >
                          Files should not exceed 10MB
                        </small>
                      </div>
                    )}
                    {formik.touched.road_worth_back &&
                      formik.errors.road_worth_back && (
                        <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                          {formik.errors.road_worth_back}
                        </p>
                      )}
                  </div>

                  {firstPic && (
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {' '}
                      <h1 style={subText}>{firstPic?.name}</h1>{' '}
                      <BiTrash
                        style={{
                          marginLeft: 10,
                          marginTop: 3,
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setFirstPic('')
                          formik.setFieldValue('road_worth_back', null)
                        }}
                      />
                    </span>
                  )}
                </div>
                <div style={center}>
                  <div style={card}>
                    <p style={{ fontWeight: 'bolder', fontSize: 15 }}>
                      Vehicle image (front preferably)
                    </p>
                    {backPic ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={URL.createObjectURL(backPic)}
                          alt='selected file'
                          style={image}
                        />
                      </div>
                    ) : (
                      <div style={imageUpload}>
                        <input
                          style={fileUpload}
                          type='file'
                          onChange={event => {
                            formik.setFieldValue(
                              'vehicle_img',
                              event.target.files[0]
                            )
                            setBackPic(event.currentTarget.files[0])
                          }}
                          onBlur={formik.handleBlur}
                          accept='image/*'
                          name='vehicle_img'
                        />
                        <div class='drag-text'>
                          <div style={iconBox}>
                            <BsFillCloudUploadFill style={icon} />
                          </div>
                        </div>
                        <small
                          style={{
                            color: 'lightgray',
                            fontSize: 13,
                            marginTop: 10
                          }}
                        >
                          Files should not exceed 10MB
                        </small>
                      </div>
                    )}
                    {formik.touched.vehicle_img &&
                      formik.errors.vehicle_img && (
                        <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                          {formik.errors.vehicle_img}
                        </p>
                      )}
                  </div>

                  {backPic && (
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {' '}
                      <h1 style={subText}>{backPic?.name}</h1>{' '}
                      <BiTrash
                        style={{
                          marginLeft: 10,
                          marginTop: 3,
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setBackPic('')
                          formik.setFieldValue('vehicle_img', null)
                        }}
                      />
                    </span>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'left',
                  width: '100%',
                  justifyContent: 'center',
                  marginLeft: isMobile ? 5 : 0,
                  alignContent: 'center',
                  alignSelf: 'center',
                  position: 'relative'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    width: '95%'
                  }}
                >
                  <div
                    style={{ marginTop: 1, width: isMobile ? '95%' : '45%' }}
                  >
                    <label>Full Name</label>
                    <input
                      style={{
                        borderColor:
                          formik.touched.fullname && formik.errors.fullname
                            ? 'red'
                            : null
                      }}
                      type='text'
                      onChange={formik.handleChange}
                      className='form-control'
                      onBlur={formik.handleBlur}
                      name='fullname'
                    />
                    {formik.touched.fullname && formik.errors.fullname && (
                      <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                        {formik.errors.fullname}
                      </p>
                    )}
                  </div>

                  <div
                    style={{ marginTop: 1, width: isMobile ? '95%' : '45%' }}
                  >
                    <label>Email</label>
                    <input
                      style={{
                        borderColor:
                          formik.touched.email && formik.errors.email
                            ? 'red'
                            : null
                      }}
                      type='email'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className='form-control'
                      name='email'
                    />
                    {formik.touched.email && formik.errors.email && (
                      <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                        {formik.errors.email}
                      </p>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    width: '95%',
                    marginBottom: 20
                  }}
                >
                  <div
                    style={{ marginTop: 10, width: isMobile ? '95%' : '45%' }}
                  >
                    <label>Password</label>
                    <input
                      style={{
                        borderColor:
                          formik.touched.password && formik.errors.password
                            ? 'red'
                            : null
                      }}
                      type='password'
                      onChange={formik.handleChange}
                      className='form-control'
                      onBlur={formik.handleBlur}
                      name='password'
                    />
                    {formik.touched.password && formik.errors.password && (
                      <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                        {formik.errors.password}
                      </p>
                    )}
                  </div>
                  <div
                    style={{ marginTop: 10, width: isMobile ? '95%' : '45%' }}
                  >
                    <label>Phone Number</label>
                    <input
                      style={{
                        borderColor:
                          formik.touched.phoneNumber &&
                          formik.errors.phoneNumber
                            ? 'red'
                            : null
                      }}
                      type='text'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className='form-control'
                      name='phoneNumber'
                    />
                    {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber && (
                        <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                          {formik.errors.phoneNumber}
                        </p>
                      )}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    width: '95%',
                    marginBottom: 20
                  }}
                >
                  <div
                    style={{ marginTop: 10, width: isMobile ? '95%' : '45%' }}
                  >
                    <label>ID Number</label>
                    <input
                      style={{
                        borderColor:
                          formik.touched.id_no && formik.errors.id_no
                            ? 'red'
                            : null
                      }}
                      type='text'
                      onChange={formik.handleChange}
                      className='form-control'
                      onBlur={formik.handleBlur}
                      name='id_no'
                    />
                    {formik.touched.id_no && formik.errors.id_no && (
                      <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                        {formik.errors.id_no}
                      </p>
                    )}
                  </div>
                  <div
                    style={{ marginTop: 10, width: isMobile ? '95%' : '45%' }}
                  >
                    <label>Date of birth</label>
                    <input
                      style={{
                        borderColor:
                          formik.touched.dob && formik.errors.dob ? 'red' : null
                      }}
                      type='date'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className='form-control'
                      name='dob'
                    />
                    {formik.touched.dob && formik.errors.dob && (
                      <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                        {formik.errors.dob}
                      </p>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    width: '95%',
                    marginBottom: 20
                  }}
                >
                  <div
                    style={{ marginTop: 10, width: isMobile ? '95%' : '45%' }}
                  >
                    <label>Digital Address</label>
                    <input
                      style={{
                        borderColor:
                          formik.touched.digital_address &&
                          formik.errors.digital_address
                            ? 'red'
                            : null
                      }}
                      type='text'
                      onChange={formik.handleChange}
                      className='form-control'
                      onBlur={formik.handleBlur}
                      name='digital_address'
                    />
                    {formik.touched.digital_address &&
                      formik.errors.digital_address && (
                        <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                          {formik.errors.digital_address}
                        </p>
                      )}
                  </div>
                  <div
                    style={{ marginTop: 10, width: isMobile ? '95%' : '45%' }}
                  >
                    <FormOptionsV2
                      type={'select'}
                      name={'gender'}
                      label={'Gender'}
                      handleChange={formik.handleChange}
                      options={[
                        {
                          name: 'Male',
                          id: 'Male',
                          value: 'Male'
                        },
                        {
                          name: 'Female',
                          id: 'Female',
                          value: 'Female'
                        }
                      ]}
                      value={formik.values['gender']}
                      style={{
                        borderColor:
                          formik.touched['gender'] && formik.errors['gender']
                            ? 'red'
                            : null
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    width: '95%',
                    marginBottom: 20
                  }}
                >
                  <div
                    style={{
                      marginTop: 1,
                      width: isMobile ? '95%' : '45%',
                      marginBottom: 5
                    }}
                  >
                    <FormOptionsV2
                      type={'select'}
                      name={'country'}
                      label={'Country'}
                      handleChange={formik.handleChange}
                      options={CountriesData?.countries}
                      value={formik.values['country']}
                      style={{
                        borderColor:
                          formik.touched['country'] && formik.errors['country']
                            ? 'red'
                            : null
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </div>

                  <div
                    style={{
                      marginTop: 1,
                      width: isMobile ? '95%' : '45%',
                      marginBottom: 5
                    }}
                  >
                    <label>When do you want your policy to start?</label>
                    <input
                      style={{
                        borderColor:
                          formik.touched.inception_date &&
                          formik.errors.inception_date
                            ? 'red'
                            : null
                      }}
                      type='date'
                      onBlur={formik.handleBlur}
                      // onChange={formik.handleChange}
                      name='inception_date'
                      onInput={setInceptionDate}
                      className='form-control'
                    />
                    {formik.touched.inception_date &&
                      formik.errors.inception_date && (
                        <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                          {formik.errors.inception_date}
                        </p>
                      )}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    width: '95%'
                  }}
                >
                  <div
                    style={{ marginTop: 1, width: isMobile ? '95%' : '45%' }}
                  >
                    <FormOptionsV2
                      type={'select'}
                      style={{
                        borderColor:
                          formik.touched.interval && formik.errors.interval
                            ? 'red'
                            : null
                      }}
                      label={'How long do you want your policy to run?'}
                      handleChange={e => {
                        e.preventDefault()
                        formik.handleChange(e)
                        onPeriodInput(e)
                      }}
                      name='interval'
                      onBlur={formik.handleBlur}
                      options={months}
                    />
                    {formik.values['expiry_date'] ? (
                      <div
                        style={{
                          marginBottom: 15,
                          marginTop: -12
                        }}
                      >
                        <span
                          style={{
                            position: 'relative',
                            fontSize: 13
                          }}
                        >
                          Your policy will expire on{' '}
                          <span style={{ fontWeight: 'bold' }}>{period}</span>
                        </span>
                      </div>
                    ) : null}
                    {formik.touched.interval && formik.errors.interval && (
                      <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                        {formik.errors.interval}
                      </p>
                    )}
                  </div>
                  <div
                    style={{ marginTop: 1, width: isMobile ? '95%' : '45%' }}
                  >
                    <FormOptionsV2
                      type={'select'}
                      style={{
                        width: '100%',
                        borderColor:
                          formik.touched.insurance_product_id &&
                          formik.errors.insurance_product_id
                            ? 'red'
                            : null
                      }}
                      label={'What policy do you want?'}
                      handleChange={e => {
                        formik.handleChange(e)
                        let prodType =
                          insuranceOptionsData?.request_types[0]?.properties?.products?.filter(
                            prod => {
                              if (prod.id === Number(e.target.value)) {
                                return prod
                              }
                            }
                          )
                        // setInsuranceType(prodType[0]?.name)
                        formik.setFieldValue(
                          'motorInsuranceType',
                          prodType[0]?.name
                        )
                      }}
                      onBlur={formik.handleBlur}
                      options={
                        insuranceOptionsData?.request_types[0]?.properties
                          ?.products
                      }
                      name='insurance_product_id'
                    />
                    {formik.touched.insurance_product_id &&
                      formik.errors.insurance_product_id && (
                        <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                          {formik.errors.insurance_product_id}
                        </p>
                      )}
                  </div>
                </div>

                <div
                  style={{
                    marginTop: 1,
                    width: '95%',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                    style={{
                      marginTop: 1,
                      width: isMobile ? '95%' : '45%',
                      marginBottom: isMobile ? 30 : 0
                    }}
                  >
                    <label>What is the cost of the vehicle?</label>
                    <input
                      type='number'
                      name='vehicle_cost'
                      onChange={formik.handleChange}
                      className='form-control'
                    />
                  </div>
                  <div
                    style={{ marginTop: 1, width: isMobile ? '95%' : '45%' }}
                  >
                    <FormOptionsV2
                      type={'select'}
                      style={{
                        width: '100%',
                        borderColor:
                          formik.touched.buy_back_excess &&
                          formik.errors.buy_back_excess
                            ? 'red'
                            : null
                      }}
                      label={'Do you want buy back excess on your policy?'}
                      handleChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      options={[
                        {
                          name: 'Yes',
                          id: 'Yes',
                          value: true
                        },
                        {
                          name: 'No',
                          id: 'No',
                          value: false
                        }
                      ]}
                      name='buy_back_excess'
                    />
                    {formik.touched.buy_back_excess &&
                      formik.errors.buy_back_excess && (
                        <p style={{ color: 'red', fontSize: 12, marginTop: 5 }}>
                          {formik.errors.buy_back_excess}
                        </p>
                      )}
                  </div>
                </div>

                <div style={{ display: 'flex', width: '95%', marginTop: 17 }}>
                  <button
                    style={{
                      display: 'block',
                      width: isMobile ? '95%' : '100%',
                      height: 45
                    }}
                    className='btn btn-primary font-loader'
                    // type='submit'
                    disabled={
                      loading || RequestLoading || motorPolicyRequestLoading
                    }
                    onClick={e => {
                      e.preventDefault()
                      formik.handleSubmit()
                    }}
                  >
                    {loading || RequestLoading || motorPolicyRequestLoading
                      ? 'Submitting...'
                      : 'Save details'}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  )
}

const imageUpload = {
  marginTop: '20px',
  position: 'relative',
  // hover
  '&:hover': {
    backgroundColor: '#f5f5f5',
    cursor: 'pointer'
  },

  height: '180px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  border: '1px solid #ccc',
  backgroundColor: 'white',
  flexDirection: 'column',
  margin: 10
}
const iconBox = {
  fontSize: '5rem',
  color: '#2e79e9',
  borderRadius: '10px',
  paddingRight: '20px',
  paddingLeft: '20px',
  backgroundColor: '#2e79e9'
}

const image = {
  width: '100%',
  objectFit: 'cover',
  height: '300px',
  shadow: '10px 10px 20px 0 rgba(0, 0, 0, 0.2)',
  borderRadius: '5px',
  position: 'relative'
}

const icon = {
  fontSize: '5rem',
  color: 'white',
  cursor: 'pointer'
}

const fileUpload = {
  position: 'absolute',
  margin: '0',
  padding: '0',
  width: '80%',
  height: '80%',
  outline: 'none',
  opacity: '0',
  cursor: 'pointer',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0'
}

const container = {
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  wider: '100%',
  maxWidth: '1000px',
  margin: '0 auto',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative'

  // display should be initial on small screens
}

const wrapper = {
  padding: '10px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
}

const wrapperMobile = {
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
}

const center = {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  width: '100%'
}

const subText = {
  fontSize: '14px',
  marginTop: '10px',
  textTransform: 'capitalize',
  fontWeight: 900
}

const card = {}
const logoContainer = {
  position: 'relative',
  marginTop: '-200px'
}

const button = {
  backgroundColor: 'dodgerblue',
  color: 'white',
  padding: '1rem',
  height: '2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px'
}

const buttonWrapper = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%'
}

const text = {
  color: 'white',
  fontSize: '16px',
  textTransform: 'capitalize'
}

export default UploadFile
