import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
} from '@material-ui/core'
import { useAppContext } from 'src/Context'

import { ChevronRight } from 'react-feather'
import { useQuery, gql } from '@apollo/client'
import PieChart from './PieChart'
import PolicyTable from './PolicyTable'
// import { textAlign } from '@mui/material/node_modules/@mui/system';
import {
  GET_POLICIES_OF_CUSTOMERS,
  CUSTOMER_PIE_PLOT,
  GETCUSTOMERPAYMENT
} from 'src/Graphql/Queries'
import Spinner from 'react-bootstrap/Spinner'
import noRequests from '../../../../src/assets/img/no_requests.png'

import noPolicies from '../../../../src/assets/img/no_policies.png'
import noTransactions from '../../../../src/assets/img/no_transactions.png'
import { useNavigate } from "react-router-dom";
import './index.css'
import WebFont from 'webfontloader'
import { Modal } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { isMobile } from 'react-device-detect'
import TransactionHistory from './NewTransactionTable'

const ClientIndex = ({ className, ...rest }) => {
  const context = useAppContext()
  const { dispatch, user } = context
  const [requestPieChartData, setrequestPieChartData] = useState(null)
  const [policies, setPolicies] = useState([])
  const [transactions, setTransactions] = useState([])
  const navigate = useNavigate();
  const [fixloader, setFixLoader] = useState(false)
  const [transdetails, setTransdetails] = useState()

  const [openModal, setOpenModal] = useState(false)
  const handleClose = () => {
    setOpenModal(false)
  }

  // let x = JSON.parse(localStorage.user)
  // console.log(user)

  const handleOpen = () => {
    setOpenModal(true)
  }

  // useEffect(() => {

  //   setCurrentUser(user)
  // }, [user, currentUser])

  const {
    data: PoliciesData,
  } = useQuery(GET_POLICIES_OF_CUSTOMERS, {
    variables: {
      _customerID: user?.id
    },
    onCompleted: data => {
      // console.log("Policies Query ran")
      setPolicies(data?.policies)
    }
  })

  const {
    data: CustomerChartData,
    loading: CustomerChartLoading,
    error: CustomerChartError,
    refetch
  } = useQuery(CUSTOMER_PIE_PLOT, {
    variables: { _userID: user?.id },
    onCompleted: data => {
      // console.log(data)
      // console.log(user?.id)
    }
  })

  const {
    loading: TransactionDataLoading,
    error: TransactionError,
    refetch: Transactionrefetch
  } = useQuery(GETCUSTOMERPAYMENT, {
    variables: { _customerID: user?.id },
    onCompleted: data => {
      setTransactions(data?.payments)
    }
  })

  const navigateToPolicy = () => {
    dispatch({
      type: 'SET_PAGE_HEADER',
      payload: 'My Policies'
    })
    navigate('/customer/customerpolicy')
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    })
  }, [])

  useEffect(() => {
    // dispatch({
    //   type: 'FETCH_MESSAGES',
    //   payload: window.localStorage.getItem('token')
    // });

    if (CustomerChartData) {
      setrequestPieChartData({
        labels: ['Active Requests', 'Pending Requests'],
        datasets: [
          {
            label: 'Stats',
            backgroundColor: ['#2E79E9', '#FFD6AB'],
            borderColor: ['#FFFFFF', '#FFFFFF',],
            data: [
              CustomerChartData?.activeRequests?.aggregate?.count,
              CustomerChartData?.pendingRequests?.aggregate?.count,
              // CustomerChartData?.completedRequests?.aggregate?.count,
              // CustomerChartData?.uploadedRequests?.aggregate?.count
            ]
          }
        ]
      })
    }
  }, [CustomerChartData])

  // useEffect(() => {
  //   if (PoliciesData) {
  //     if (PoliciesData.policy_request?.length < 5) {
  //       let policies = [];
  //       for (let policy of PoliciesData.policy_request) {
  //         let obj = {};

  //         obj.insuranceType = policy.insurance_product.name;
  //         obj.premium = policy.premium;
  //         obj.dueDate = policy.proposed_expiry_date;
  //         obj.insurer = policy.accepted_quote?.insurance_company.name;

  //         policies.push(obj);
  //       }

  //       setPolicies(policies);
  //     } else {
  //       let policies = [];
  //       for (let i = 0; i < 5; i++) {
  //         let obj = {};

  //         obj.insuranceType =
  //           PoliciesData.policy_request[i].insurance_product.name;
  //         obj.premium = PoliciesData.policy_request[i].premium;
  //         obj.dueDate = PoliciesData.policy_request[i].proposed_expiry_date;
  //         obj.insurer =
  //           PoliciesData.policy_request[
  //             i
  //           ].accepted_quote?.insurance_company.name;

  //         policies.push(obj);
  //       }

  //       setPolicies(policies);
  //     }
  //   }
  // }, [PoliciesData]);

  // useEffect(() => {
  //   setTransactions([]);

  //   if (TransactionData) {
  //     if (TransactionData.payment?.length > 0) {
  //       for (const transaction of TransactionData.payment) {
  //         const {
  //           amount,
  //           payment_ref,
  //           state,
  //           quote: {
  //             policy_request: {
  //               insurance_product: { name },
  //               payment_date
  //             },
  //             intermediary_user: {
  //               intermediary: { name: paymentname }
  //             }
  //           }
  //         } = transaction;

  //         const item = {
  //           Reference: payment_ref,
  //           insuranceType: name,
  //           Amountpaid: amount,
  //           Paidto: paymentname,
  //           Status: state,
  //           dueDate: formatDateFull(payment_date),
  //           original_date: payment_date
  //         };

  //         setTransactions(prevState => [...prevState, item]);
  //       }
  //     }
  //   }
  // }, [TransactionData]);

  // const transdetails={
  //   type:"Motor insurance policy",
  //   datepaid:"24th January 2022",
  //   reference:"27382948294829174892",
  //   amount:"40000",
  //   status:"pending"
  // }

  // const fakeFixStatus = id => {
  //   setFixLoader(true)
  //   setTimeout(() => {
  //     if (id == '1') {
  //       SuccessNotification('Fixed successfully')
  //     }
  //   }, 4000)
  //   setFixLoader(false)
  // }

  if (!user) {
    return <Spinner animation='grow'></Spinner>
  } else {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
          <div className='top-container'>
            <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
              <div>
                {user ? (
                  <h1 className='username font-loader'>
                    Welcome, {user?.first_name + ' ' + user?.last_name}
                  </h1>
                ) : (
                  <h1 className='username font-loader'>
                    Welcome, My Policy User
                  </h1>
                )}
              </div>
              <Grid
                lg={12}
                sm={12}
                xs={12}
                className='activities'
                style={{ borderRadius: 30 }}
              >
                <p className='activities-header font-loader'>
                  Activities (
                  {CustomerChartData?.canceledRequests?.aggregate?.count +
                    CustomerChartData?.pendingRequests?.aggregate?.count +
                    CustomerChartData?.completedRequests?.aggregate?.count || 0}
                  )
                  {/* <ChevronRight /> */}
                </p>
                <div className='pie-chart-container'>
                  {CustomerChartLoading ? (
                    <Spinner
                      style={{
                        position: 'relative',
                        left: '41%',
                        marginTop: '35%'
                      }}
                      animation='grow'
                    ></Spinner>
                  ) : (
                    <>
                      {CustomerChartData?.canceledRequests?.aggregate?.count +
                        CustomerChartData?.pendingRequests?.aggregate?.count +
                        CustomerChartData?.completedRequests?.aggregate?.count >
                      0 ? (
                        <>
                          <PieChart chartData={requestPieChartData} />
                        </>
                      ) : (
                        <div
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                          }}>
                            <img
                              style={{
                                width: '35%',
                                height: '35%',
                                marginBottom: 10
                              }}
                              src={noRequests}
                            />
                            <h1
                              font-loader
                              style={{
                                fontSize: '45px',
                                color: '#0E3E86'
                              }}
                            >
                              No Request
                            </h1>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            </div>
            <div>
              <Grid
                // container
                lg={12}
                sm={12}
                xs={12}
                className={'policies-container'}
                style={{ padding: 12 }}
              >
                <div className='policies-headings'>
                  <p className='policies-heading-1 font-loader'>
                    Recent Policies
                  </p>
                  <p
                    onClick={() => navigateToPolicy()}
                    className='policies-heading-2 font-loader'
                  >
                    View More
                    <ChevronRight style={{ cursor: 'pointer' }} />
                  </p>
                </div>
                {!user ? (
                  <Spinner
                    style={{
                      position: 'relative',
                      left: '41%',
                      marginTop: '35%'
                    }}
                    animation='grow'
                  ></Spinner>
                ) : (
                  <>
                    {PoliciesData?.policies.length === 0 ? (
                      <div
                      className='policy-img-container'
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center'
                        }}
                      >
                        <div className='policy-emptyState-box' >
                          <img className='policy-img' src={noPolicies} />
                          <h1 className='policy-img-heading font-loader'>
                            No Policies{' '}
                          </h1>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                          top: isMobile ? -80 : 0
                        }}
                      >
                        <PolicyTable policies={policies} />
                      </div>
                    )}
                  </>
                )}
              </Grid>
            </div>
          </div>
          <div>
            <Grid
              container
              lg={12}
              sm={12}
              xs={12}
              className='transaction-container'
              style={{ padding: 12 }}
            >
              <div className=''>
                {!user ? (
                  <Spinner
                    style={{
                      position: 'relative',
                      left: '50%',
                      marginTop: '10%'
                    }}
                    animation='grow'
                  ></Spinner>
                ) : (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {transactions?.length < 1 ? (
                        <div
                          style={{
                            textAlign: 'center',
                            paddingTop: 80
                          }}
                        >
                          <div className='policy-emptyState-box'>
                            <img
                              className='transaction-img'
                              src={noTransactions}
                            />
                            <h1 className='policy-img-heading font-loader'>
                              No Transactions
                            </h1>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{ width: '100%', marginRight: 15 }}
                          className='trans-table'
                        >
                          <TransactionHistory
                            transactions={transactions}
                            showModal={handleOpen}
                            setTransdetails={setTransdetails}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </Grid>
          </div>
        </div>
      </>
    )
  }
}

ClientIndex.propTypes = {
  className: PropTypes.string
}

export default ClientIndex
