import { useQuery, gql } from '@apollo/client'

export const LOAD_COMPANIES = gql`
  query getProxiesInsurers($proxy_id: uuid) {
    proxy_insurance_company_links(where: { proxy_id: { _eq: $proxy_id } }) {
      insurance_company {
        name
      }
      insurance_company_id
    }
  }
`

export const GET_INSURER_BANK_DETAILS = gql`
  query getInsurerBankDetails($proxy_id: uuid) {
    proxy_insurance_company_links(where: { proxy_id: { _eq: $proxy_id } }) {
      bank_account_no
      references {
        name
      }
      insurance_company {
        name
      }
    }
  }
`

export const GET_CUSTOMER_POLICIES = gql`
  query GetCustomerQuotes($customer_id: uuid) {
    policy_request(where: { currency_id: { _eq: $customer_id } }) {
      id
      currency {
        id
        name
        symbol
      }
      premium
      payment_ref
      state
      payment_status
      payment_channel
      proposed_inception_date
      proposed_inception_date
      created_at
      updated_at
      customer_acceptance_date
      insurance_product {
        id
        name
      }
    }
  }
`

export const GET_VEHICLE_INFO = gql`
  query BodyTypesAndMake {
    body_types: body_type(
      order_by: { name: asc }
      where: { is_active: { _eq: true } }
    ) {
      id
      name
    }
    vehicle_makes: vehicle_make(
      order_by: { name: asc }
      where: { is_active: { _eq: true } }
    ) {
      id
      name
      vehicle_models(
        order_by: { name: asc }
        where: { is_active: { _eq: true } }
      ) {
        id
        name
      }
    }
    usage_types(order_by: { name: asc }, where: { is_active: { _eq: true } }) {
      id
      name
    }
  }
`

export const GET_BUILDING_MATERIALS = gql`
  query getBuildingMaterials {
    building_material(
      order_by: { name: asc }
      where: { is_active: { _eq: true } }
    ) {
      id

      name

      is_active
    }
  }
`

export const GET_HOME_INSURANCE_OPTIONS = gql`
  query getInsuranceOptions($parent_insurance_name: String) {
    insurance_product(
      order_by: { name: asc }
      where: {
        parent_product: { name: { _eq: $parent_insurance_name } }
        is_active: { _eq: true }
      }
    ) {
      id
      name
    }
  }
`

export const GET_INSURANCE_PRODUCTS_OPTIONS = gql`
  query getInsuranceProductOptions($id: Int) {
    request_types(where: { id: { _eq: $id } }) {
      properties
    }
  }
`

export const GET_ROOFING_MATERIALS = gql`
  query getRoofingMaterials {
    roofing_material(
      order_by: { name: asc }
      where: { is_active: { _eq: true } }
    ) {
      id
      name
      is_active
    }
  }
`

export const GET_CUSTOMER_MOTOR_REQUEST_DETAILS = gql`
  query GetMotorRequestByRequestID($requestID: uuid) {
    requests(where: { id: { _eq: $requestID } }) {
      motor_details
      quotes {
        amount
        additional_options
        created_at
        id
        status
        proxy {
          name
          id
        }
        insurance_company {
          id
          name
        }
      }
    }
  }
`

export const GET_PROXY_INSURER_COMMISSION = gql`
  query getProxyInsurerCommission($proxyID: uuid, $insurerID: uuid) {
    proxy_commissions(
      where: {
        proxy_id: { _eq: $proxyID }
        insurance_company_id: { _eq: $insurerID }
      }
    ) {
      broker_commission
    }
  }
`

export const GET_CUSTOMER_HOME_OWNER_REQUEST_DETAILS = gql`
  query GetHomeOwnerRequestByRequestID($requestID: uuid) {
    requests(where: { id: { _eq: $requestID } }) {
      home_details
      quotes {
        amount
        created_at
        id
        proxy {
          name
          id
        }
        insurance_company {
          id
          name
        }
      }
    }
  }
`

export const GET_CUSTOMER_REQUEST = gql`
  query getRequests($_userID: uuid, $limit: Int, $offset: Int) {
    homeOwnerRequests: requests(
      where: {
        customer_id: { _eq: $_userID }
        request_type: { name: { _eq: "Home Owner" } }
        status: { _eq: "pending" }
      }
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      created_at
      id
      request_type {
        name
      }
      status
      quotes {
        created_at
      }
      motor_details
      home_details
    }

    motorRequests: requests(
      where: {
        customer_id: { _eq: $_userID }
        request_type: { name: { _eq: "Motor" } }
        status: { _eq: "pending" }
      }
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      created_at
      id
      request_type {
        name
      }
      status
      quotes {
        created_at
      }
      motor_details
      home_details
    }

    unansweredRequests: requests(
      where: {
        customer_id: { _eq: $_userID }
        quotes_aggregate: { count: { predicate: { _eq: 0 } } }
        status: { _eq: "pending" }
      }
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      created_at
      id
      request_type {
        name
      }
      status
      quotes {
        created_at
      }
      motor_details
      home_details
    }

    answeredRequests: requests(
      where: {
        customer_id: { _eq: $_userID }
        quotes_aggregate: { count: { predicate: { _gt: 0 } } }
        status: { _eq: "pending" }
      }
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      created_at
      id
      request_type {
        name
      }
      status
      quotes {
        created_at
      }
      motor_details
      home_details
    }

    allRequests: requests(
      where: { customer_id: { _eq: $_userID }, status: { _eq: "pending" } }
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      created_at
      id
      request_type {
        name
      }
      status
      quotes {
        created_at
      }
      motor_details
      home_details
    }
  }
`
export const CHECK_CUSTOMER_EXIST = gql`
  query CheckCustomerDetails(
    $phone_number: bigint
    $email: String
    $national_id: String
  ) {
    customer(
      limit: 1
      order_by: { created_at: desc }
      where: {
        _or: [
          { phone_number: { _eq: $phone_number } }
          { user: { email: { _eq: $email } } }
          { national_id: { _eq: $national_id } }
        ]
      }
    ) {
      id
    }
  }
`

export const GET_PRODUCT_BY_ID = gql`
  query GetProductByTypeId($product_type_id: uuid!) {
    product_type_by_pk(id: $product_type_id) {
      insurance_products(
        where: { is_active: { _eq: true } }
        order_by: { name: asc }
      ) {
        id

        name
      }
    }
  }
`

export const LOGIN_USER = gql`
  query getUserByEmail($_proxyEmail: jsonb, $_email: jsonb) {
    customers(where: { emails: { _eq: $_email } }) {
      active
      country
      created_by
      digital_address
      dob
      emails
      first_name
      gender
      id
      id_no
      last_name
      phone_numbers
    }
    proxies(where: { emails: { _eq: $_proxyEmail } }) {
      active
      country
      digital_address
      emails
      id
      name
      phone_numbers
      type
      system_commission
    }
  }
`

export const GET_REQUEST_OVERVIEW = gql`
  query getRequestOverview {
    cancelledRequests: requests_aggregate(
      where: { status: { _eq: "canceled" } }
    ) {
      aggregate {
        count
      }
    }
    pendingRequests: requests_aggregate(where: { status: { _eq: "pending" } }) {
      aggregate {
        count
      }
    }
    activeRequests: requests_aggregate(where: { status: { _eq: "active" } }) {
      aggregate {
        count
      }
    }
    completedRequests: requests_aggregate(
      where: { status: { _eq: "completed" } }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_UNPAID_QUOTES_BY_CUSTOMER_ID = gql`
  query getUpaidQuotes($customerID: uuid, $_nin: [uuid!]) {
    quotes(
      where: {
        customer_id: { _eq: $customerID }
        status: { _eq: "accepted" }
        id: { _nin: $_nin }
      }
    ) {
      id
      amount
      created_at
      insurance_company {
        name
      }
      proxy {
        name
      }
    }
  }
`

export const GET_SUCCESS_FULL_PAID_QUOTES = gql`
  query getSuccessfullPayments($customerID: uuid) {
    payments(
      where: { customer_id: { _eq: $customerID }, status: { _eq: "success" } }
    ) {
      quote_id
    }
  }
`

export const GET_BROKER_QUOTES = gql`
  query QuotesByStateAndIntermediary($intermediary_id: uuid, $state: String) {
    quote(
      where: {
        state: { _eq: $state }
        intermediary_user: { intermediary_id: { _eq: $intermediary_id } }
      }
      order_by: { updated_at: desc }
    ) {
      id

      premium

      proposed_inception_date

      proposed_expiry_date

      state

      created_at

      updated_at

      insurance_company {
        id

        name
      }

      quotation_date

      policy_purchase_date

      customer_review_remarks

      intermediary_review_date

      customer_acknowledgement_date

      broker_review_remarks

      details

      currency {
        id

        name

        symbol

        review_intermediary: review_intermediary_user {
          id

          name
        }

        quote_intermediary: intermediary_user {
          id

          intermediary {
            id

            name

            logo
          }
        }
      }
    }
  }
`

export const GET_INSURANCE_COMPANIES_OF_BROKERS = gql`
  query GetIntermediaryInsuranceCompanies($intermediary_id: uuid) {
    intermediary_insurance_company(
      order_by: { insurance_company: { name: asc } }
      where: { intermediary_id: { _eq: $intermediary_id } }
    ) {
      insurance_company {
        id

        name

        email

        logo

        phone_number
      }
    }
  }
`

export const GET_UNPAID_QUOTE_FROM_CUSTOMERS = gql`
  query GetUnpaidQuotesOfIntermediary($intermediary_id: uuid!) {
    quote(
      where: {
        intermediary_user: { intermediary_id: { _eq: $intermediary_id } }
      }
      order_by: { premium: asc, updated_at: desc }
    ) {
      quote_code

      policy_request {
        id

        request_code

        customer {
          id

          full_name
        }

        insurance_product {
          id

          name

          product_type {
            id

            name
          }
        }
      }

      id

      state

      premium

      proposed_inception_date

      proposed_expiry_date

      state

      created_at

      updated_at

      quotation_date

      insurance_company {
        id

        name
      }

      quotation_date

      policy_purchase_date

      customer_review_remarks

      intermediary_review_date

      customer_acknowledgement_date

      broker_review_remarks

      details

      currency {
        id

        name

        symbol
      }

      review_intermediary: review_intermediary_user {
        id

        name
      }

      quote_intermediary: intermediary_user {
        id

        name

        intermediary {
          id

          name

          logo
        }
      }
    }
  }
`

export const GET_PRODUCT_BENEFIT = gql`
  query GetAllBenefitsByPolicyType($product_type_id: uuid!) {
    product_benefit(
      where: {
        insurance_product_type_id: { _eq: $product_type_id }
        is_active: { _eq: true }
      }
    ) {
      id

      product_type {
        id

        name
      }
    }
  }
`

export const GET_POLICIES_OF_BROKER = gql`
  query getPoliciesByProxyID($_proxyID: uuid) {
    policies(
      where: { quote: { proxy_id: { _eq: $_proxyID } } }
      order_by: { created_at: desc }
    ) {
      id
      amount
      active
      customer {
        first_name
        last_name
        other_names
      }
      total_paid
      validity_end
      validity_start
      quote {
        insurance_company {
          name
        }
      }
    }
  }
`

export const GET_POLICIES_OF_CUSTOMERS = gql`
  query getPoliciesByCustomerID($_customerID: uuid) {
    policies(
      where: { customer_id: { _eq: $_customerID } }
      order_by: { created_at: desc }
      limit: 5
    ) {
      id
      amount
      validity_end
      validity_start
      quote {
        insurance_company {
          name
        }
        request {
          request_type {
            name
          }
        }
      }
    }
  }
`

export const GET_ALL_BANKS = gql`
  query GetAllBanks {
    bank(order_by: { name: asc }, where: { is_active: { _eq: true } }) {
      id

      name
    }
  }
`

export const GET_ALL_NOTIFICATIONS = gql`
  subscription GetUserNotifications($user_id: uuid, $state: [String!]) {
    notifications: notification(
      where: { user_id: { _eq: $user_id }, state: { _in: $state } }
    ) {
      id

      user_id

      title

      state

      body

      created_at

      updated_at
    }
  }
`

export const CUSTOMER_PIE_PLOT = gql`
  query getPiePlotData($_userID: uuid) {
    pendingRequests: requests_aggregate(
      where: { customer_id: { _eq: $_userID }, status: { _eq: "pending" } }
    ) {
      aggregate {
        count
      }
    }

    canceledRequests: requests_aggregate(
      where: { customer_id: { _eq: $_userID }, status: { _eq: "canceled" } }
    ) {
      aggregate {
        count
      }
    }

    completedRequests: requests_aggregate(
      where: { customer_id: { _eq: $_userID }, status: { _eq: "completed" } }
    ) {
      aggregate {
        count
      }
    }

    uploadedRequests: requests_aggregate(
      where: {
        created_by: { _eq: "self_upload" }
        customer_id: { _eq: $_userID }
        status: { _eq: "pending" }
      }
    ) {
      aggregate {
        count
      }
    }

    activeRequests: requests_aggregate(
      where: { customer_id: { _eq: $_userID }, status: { _eq: "active" } }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const NEW_REQUEST_NOT_QUOTED = gql`
  query GetRequests($proxyId: uuid, $includeNull: Boolean!) {
    assigned_unassigned_view(
      where: {
        _or: [
          { proxy_id: { _eq: $proxyId } }
          { proxy_id: { _is_null: $includeNull } }
        ]
      }
      order_by: { created_at: desc }
    ) {
      created_at
      request_id
      request_type_id
      customer_name
      proxy_id
    }
  }
`

export const GET_INTERMEDIARY_DASHBOARD_POLICY_INFO = gql`
  query getProxyAggregates($proxyID: uuid) {
    policies_aggregate(where: { quote: { proxy_id: { _eq: $proxyID } } }) {
      aggregate {
        policyCount: count
        policySoldSum: sum {
          amount
          total_paid
        }
      }
    }
  }
`

export const GETALLDOCUMENTSOFPOLICY = gql`
  query getFileByReference($reference: String) {
    files(where: { reference_id: { _eq: $reference } }) {
      id
      key
      type
      reference_id
      properties
    }
  }
`

export const GETCUSTOMERPAYMENT = gql`
  query GetCustomerPayments($_customerID: uuid) {
    payments(
      where: { customer_id: { _eq: $_customerID } }
      order_by: { created_at: desc }
    ) {
      amount
      created_at
      customer_id
      status
      reference
      created_by
      quote {
        insurance_company {
          name
        }
      }
    }
  }
`

export const GETCUSTOMERID = gql`
  query GetCustomerById($id: uuid!) {
    customer: customer_by_pk(id: $id) {
      id

      first_name

      last_name

      user {
        email
      }

      photo

      phone_number
    }
  }
`

export const GET_QUOTE_OF_INTEMEDIARY_BY_STATE = gql`
  query getQuotesByProxyID($_proxyID: uuid) {
    quotes(
      where: { proxy_id: { _eq: $_proxyID } }
      order_by: { created_at: desc }
    ) {
      id
      additional_options
      customer {
        first_name
        last_name
        other_names
      }
      amount
      created_at
      request_id
      status
      request {
        request_type {
          name
        }
      }
    }
  }
`

export const GET_ALL_INTERMEDIATES = gql`
  query getAllIntermediaries {
    intermediaries: intermediary {
      id

      name

      email

      phone_number

      physical_address

      digital_address

      license_number

      license_document

      logo

      intermediary_type

      bank {
        id

        name
      }

      bank_branch

      system_commission_rate

      approved_at

      approver {
        id

        name
      }

      approved_at

      approval_remarks

      approval_status

      created_at
    }
  }
`

export const GET_INTERMEDIARY_BY_ID = gql`
  query getIntermediaryById($intermediary_id: uuid!) {
    intermediary: intermediary_by_pk(id: $intermediary_id) {
      id
      name
      email
      phone_number
      physical_address
      digital_address
      license_number
      license_document
      logo
      intermediary_type
      bank {
        id
        name
      }
      bank_branch
      system_commission_rate
      approved_at
      approver {
        id
        name
      }
      approved_at
      approval_remarks
      approval_status
      created_at
      account_no
    }
  }
`

export const GET_INTERMEDIARY_WEEKLY_POLICY_SALE = gql`
  query GetWeeklyIntermediaryPolicySale($intermediary_id: uuid) {
    view_intermediary_policy_sale(
      where: {
        intermediary_user: { intermediary_id: { _eq: $intermediary_id } }
      }
    ) {
      week

      total_count
    }
  }
`

export const GET_ALL_BEENFITS = gql`
  query GetAllBenefitsByPolicyType(
    $product_type_id: uuid!
    $intermediary_id: uuid!
  ) {
    product_benefit(
      where: {
        intermediary_id: { _eq: $intermediary_id }
        insurance_product_type_id: { _eq: $product_type_id }
        is_active: { _eq: true }
      }
    ) {
      id
      name
      product_type {
        id
        name
      }
    }
  }
`

export const GET_ALL_COMPANIES_FOR_INTERMEDIARY = gql`
  query GetIntermediaryInsuranceCompanies($intermediary_id: uuid) {
    intermediary_insurance_company(
      order_by: { insurance_company: { name: asc } }
      where: { intermediary_id: { _eq: $intermediary_id } }
    ) {
      insurance_company {
        id

        name

        email

        logo

        phone_number
      }
    }
  }
`
