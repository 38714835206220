import * as React from 'react'  
import PropTypes from 'prop-types';
import {Line} from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js'



const Policychart = ({ chartData, ...rest }) => {

  return (
      <Line data={chartData} options={
        {
          title:{
            display:true,
            text: chartData?.datasets[0].label,
            fontSize:20,
            fontColor:'#0E3E86'
          },
          legend:{
            display:false
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Sales in GHS'
              }
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Month'
              }
            }]
          }  
        }
      } /> 

  );
};

Policychart.propTypes = {
  className: PropTypes.string
};

export default Policychart;
