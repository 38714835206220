import { BsTrashFill } from 'react-icons/bs';
import WebFont from 'webfontloader'
import "../../AnimatedCard/styles.css";
import {  useEffect } from 'react';



function TableRows({ rowsData, deleteTableRows, handleChange, onMakeInput }) {


  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    })
  }, [])

  return rowsData.map((data, index) => {
    const { name, quantity, value } = data;
    return (
      <tr key={index}>
        <td>
          <input
            type="text"
            value={name}
            onChange={evnt => handleChange(index, evnt)}
            name="name"
            className="form-control font-loader"
          />
        </td>
        <td>
          <input
            type="number"
            value={quantity}
            onChange={evnt => handleChange(index, evnt)}
            name="quantity"
            className="form-control font-loader"
          />{' '}
        </td>
        <td>
          <input
            type="number"
            value={value}
            onInput={e => onMakeInput(index,e)}
            onChange={evnt => handleChange(index, evnt)}
            name="value"
            className="form-control font-loader"
          />{' '}
        </td>
        <td>
          <button
            className="btn btn-outline-danger font-loader"
            onClick={() => deleteTableRows(index)}
          >
            {<BsTrashFill />}
          </button>
        </td>
      </tr>
    );
  });
}
export default TableRows;