import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SummaryItem from './SummaryItem';
import SummaryItem2 from './SummaryItem2';
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Grid,
  Container
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));


const Summary = ({stats, className, ...rest }) => {
  const classes = useStyles();
  const {most_loyal,most_purchased}=stats

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
    
      <Container maxWidth={false}>
        <CardHeader title="Most purchased product" />
        <Grid container spacing={3}>
          {most_purchased.map(data => (
            <Grid  item lg={4} sm={4} xl={4} xs={12}>
              <SummaryItem key={data.id} data={data} />
            </Grid>
          ))}

         
        </Grid>
        <CardHeader title="Most Loyal Customer" />
        <Grid container spacing={3}>
          {most_loyal.map(data => (
            <Grid  item lg={4} sm={4} xl={4} xs={12}>
              <SummaryItem2 key={data.id} data={data} />
            </Grid>
          ))}

         
        </Grid>
      </Container>

      <Divider />
    </Card>
  );
};

Summary.propTypes = {
  className: PropTypes.string
};

export default Summary;
