import {
  ApolloClient,
  ApolloLink,
  split,
  InMemoryCache,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ extensions }) => {
      switch (extensions.code) {
        case "data-exception":
        case "validation-failed":
          // <Link to="/something-went-wrong"></Link>
          break;
        default:
          // default case
          console.log(extensions.code);
      }
    });
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // this.props.history.push("/network-error");
  }
});

function getHeaders() {
  const headers = {
    "x-hasura-admin-secret": process.env.REACT_APP_HASURA_SECRET,
    "content-type": "application/json",
  };

  return headers;
}

const link = from([
  errorLink,
  new HttpLink({ uri: process.env.REACT_APP_GRAPHQL }),
]);

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_SUB_LINK,
    reconnect: true,
    timeout: 30000,
    connectionParams: {
      headers: getHeaders(),
    },
  }),
);

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      "x-hasura-admin-secret": process.env.REACT_APP_HASURA_SECRET,
      "content-type": "application/json",
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  authLink.concat(wsLink),
  authLink.concat(link),
);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
  },
});
