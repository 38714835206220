import { useQuery, gql } from '@apollo/client'

export const SIGN_UP_PROXY = gql`
  mutation signUpProxy(
    $name: String
    $phone_numbers: jsonb
    $created_by: jsonb
    $digital_address: String
    $emails: jsonb
    $type: proxy_type
    $country: String
    $address_line_1: String
    $address_line_2: String
  ) {
    insert_proxies_one(
      object: {
        name: $name
        phone_numbers: $phone_numbers
        created_by: $created_by
        digital_address: $digital_address
        emails: $emails
        type: $type
        country: $country
        address_line_1: $address_line_1
        address_line_2: $address_line_2
        active: false
      }
    ) {
      id
    }
  }
`

export const SIGN_UP_CUSTOMER = gql`
  mutation signUpCustomer(
    $first_name: String
    $other_names: String
    $last_name: String
    $dob: date
    $emails: jsonb
    $gender: String
    $id_no: String
    $phone_numbers: jsonb
    $country: String
    $digital_address: String
    $created_by: jsonb
  ) {
    insert_customers_one(
      object: {
        first_name: $first_name
        other_names: $other_names
        last_name: $last_name
        dob: $dob
        emails: $emails
        gender: $gender
        id_no: $id_no
        phone_numbers: $phone_numbers
        country: $country
        digital_address: $digital_address
        created_by: $created_by
      }
    ) {
      id
    }
  }
`

export const SUBMIT_REGISTERED_CUSTOMER_REQUEST = gql`
  mutation createMotorRequest(
    $created_by: jsonb
    $customer_id: uuid
    $motor_details: jsonb
    $home_details: jsonb
    $request_type_id: Int
  ) {
    insert_requests_one(
      object: {
        created_by: $created_by
        customer_id: $customer_id
        motor_details: $motor_details
        home_details: $home_details
        request_type_id: $request_type_id
        status: "pending"
      }
    ) {
      id
    }
  }
`

export const QUOTEACKNOWLEDGEMENT = gql`
  mutation CustomerQuoteAcknowledgement(
    $quote_id: uuid!
    $quoteState: String
    $customer_acknowledgement_date: timestamptz
    $customer_review_remarks: String
  ) {
    update_quote_by_pk(
      pk_columns: { id: $quote_id }
      _set: {
        state: $quoteState
        customer_acknowledgement_date: $customer_acknowledgement_date
        customer_review_remarks: $customer_review_remarks
      }
    ) {
      id

      state
    }
  }
`
export const REQUESTSTATUSUPDATE = gql`
  mutation UpdateRequestByID($request_id: uuid!, $requestState: String!) {
    update_policy_request_by_pk(
      pk_columns: { id: $request_id }
      _set: { state: $requestState }
    ) {
      newState: state
    }
  }
`

export const UPDATE_AFTER_PAYMENT = gql`
  mutation UpdateQuoteStateByPk(
    $quote_id: uuid!
    $customer_review_remarks: String
    $state: String
  ) {
    update_quote_by_pk(
      pk_columns: { id: $quote_id }
      _set: {
        state: $state
        customer_review_remarks: $customer_review_remarks
        customer_acknowledgement_date: now
      }
    ) {
      id
    }
  }
`

export const SEND_NOTIFICATION_CUSTOMER = gql`
  mutation SendNotificationCustomer(
    $id: String!
    $name: JSON
    $username: String!
    $id1: ID!
    $messageShort: String!
    $subject: String!
  ) {
    data: send_customer_notification(
      request: {
        id: $id1
        subject: $subject
        messageShort: $messageShort
        createdBy: { id: $id, username: $username, name: $name }
      }
    ) {
      message
      data
    }
  }
`

export const INITIATE_PAYMENT = gql`
  mutation InitiatePayment(
    $id: String!
    $name: JSON
    $username: String!
    $description: String
    $quoteId: ID!
    $returnUrl: String!
  ) {
    data: initiate_payment(
      request: {
        quoteId: $quoteId
        returnUrl: $returnUrl
        createdBy: { id: $id, name: $name, username: $username }
        description: $description
      }
    ) {
      message
      data
    }
  }
`

export const EDIT_QUOTE_BY_ID = gql`
  mutation editQuote($id: uuid, $amount: numeric, $additional_options: jsonb) {
    update_quotes(
      where: { id: { _eq: $id } }
      _set: { amount: $amount, additional_options: $additional_options }
    ) {
      affected_rows
    }
  }
`

export const BROKER_CREATE_QUOTE = gql`
  mutation createQuotes($objects: [quotes_insert_input!]!) {
    insert_quotes(objects: $objects) {
      affected_rows
    }
  }
`

export const PAY_QUOTE = gql`
  mutation PayQuoteByPk(
    $quote_id: uuid!
    $customer_review_remarks: String
    $payment_ref: String
    $premium: float8
    $customer_acceptance_date: timestamptz
    $exchange_rate: float8
    $premium_in_ghs: float8
    $system_commission: float8
    $third_party_ref: String
  ) {
    update_quote_by_pk(
      pk_columns: { id: $quote_id }
      _set: {
        state: "PAYMENT_INITIATED"
        customer_review_remarks: $customer_review_remarks
        customer_acknowledgement_date: now
        policy_purchase_date: now
      }
    ) {
      id

      policy_request_id
    }

    insert_payment_one(
      object: {
        amount: $premium_in_ghs
        third_party_ref: $third_party_ref
        payment_ref: $payment_ref
        quote_id: $quote_id
      }
      on_conflict: {
        constraint: payment_payment_ref_key
        update_columns: updated_at
      }
    ) {
      id
    }

    update_policy_request(
      where: { quotes: { id: { _eq: $quote_id } } }
      _set: {
        state: "PAYMENT_INITIATED"
        payment_status: "PAYMENT_INITIATED"
        payment_ref: $payment_ref
        premium: $premium
        payment_date: now
        customer_acceptance_date: $customer_acceptance_date
        exchange_rate: $exchange_rate
        premium_in_ghs: $premium_in_ghs
        accepted_quote_id: $quote_id
        system_commission: $system_commission
      }
    ) {
      affected_rows
    }
  }
`

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateUserNotification($id: uuid!, $state: String) {
    update_notification_by_pk(
      pk_columns: { id: $id }
      _set: { state: $state }
    ) {
      id

      user_id

      title

      state

      body

      created_at

      updated_at
    }
  }
`

export const DELETE_ALL_NOTIFICATION = gql`
  mutation DeleteBulkNotification($user_id: uuid, $state: [String!]) {
    delete_notification(
      where: { user_id: { _eq: $user_id }, state: { _in: $state } }
    ) {
      affected_rows
    }
  }
`

export const DELETE_SINGLE_NOTIFICATION = gql`
  mutation DeleteNotification($id: uuid!) {
    delete_notification_by_pk(id: $id) {
      id

      user_id

      title

      body

      created_at

      updated_at
    }
  }
`

export const ADD_FILES_TO_DATABASE = gql`
  mutation AddPolicyDocuments(
    $policy_documents: [policy_document_insert_input!]!
  ) {
    insert_policy_document(objects: $policy_documents) {
      affected_rows
    }
  }
`

export const UPDATE_USER_PROFILE = gql`
  mutation updateCustomerDetails(
    $id: uuid
    $first_name: String
    $last_name: String
    $phone_numbers: jsonb
  ) {
    update_customers(
      where: { id: { _eq: $id } }
      _set: {
        first_name: $first_name
        last_name: $last_name
        phone_numbers: $phone_numbers
      }
    ) {
      affected_rows
      returning {
        first_name
        last_name
        emails
        phone_numbers
      }
    }
  }
`

export const CANCEL_POLICY_REQUEST = gql`
  mutation cancelRequest($_requestID: uuid) {
    update_requests(
      where: { id: { _eq: $_requestID } }
      _set: { status: "canceled" }
    ) {
      affected_rows
    }
  }
`

export const ACCEPT_QUOTE = gql`
  mutation acceptQuote(
    $quoteID: uuid
    $requestID: uuid
    $requestStatus: request_status
    $quoteStatus: quote_status
  ) {
    update_requests(
      where: { id: { _eq: $requestID } }
      _set: { status: $requestStatus }
    ) {
      affected_rows
    }
    update_quotes(
      where: { id: { _eq: $quoteID } }
      _set: { status: $quoteStatus }
    ) {
      affected_rows
    }
  }
`

export const UPDATE_REQUEST_COMMISSION = gql`
  mutation updateRequestCommission($requestID: uuid, $commission: numeric) {
    update_requests(
      where: { id: { _eq: $requestID } }
      _set: { commission: $commission }
    ) {
      affected_rows
    }
  }
`

export const REJECT_QUOTE = gql`
  mutation rejectQuote($quoteID: uuid, $quoteStatus: quote_status) {
    update_quotes(
      where: { id: { _eq: $quoteID } }
      _set: { status: $quoteStatus }
    ) {
      affected_rows
    }
  }
`

export const DELETE_DOCUMENT = gql`
  mutation deletePolicyDoc($document_id: uuid!) {
    delete_policy_document_by_pk(id: $document_id) {
      id

      policy_request_id

      name

      document
    }
  }
`

export const UPDATE_BANK_ACCOUNT = gql`
  mutation IntermediaryAccount(
    $intermediary_id: uuid!
    $bank_id: uuid
    $account_no: String
    $bank_branch: String
  ) {
    update_intermediary_account: update_intermediary_by_pk(
      pk_columns: { id: $intermediary_id }
      _set: {
        bank_id: $bank_id
        account_no: $account_no
        bank_branch: $bank_branch
      }
    ) {
      id

      bank {
        id

        name
      }

      account_no

      bank_branch
    }
  }
`

export const UPDATE_PROFILE_PIC = gql`
  mutation updateCustomerPhoto($id: uuid!, $photo: String) {
    updated_customer: update_customer_by_pk(
      _set: { photo: $photo }
      pk_columns: { id: $id }
    ) {
      id

      first_name

      last_name

      photo

      phone_number

      user_id

      user {
        email

        user_type
      }
    }
  }
`

export const CREATE_NEW_PRODUCT_BENEFIT = gql`
  mutation createProductTypeBenefit(
    $id: uuid
    $product_type_id: uuid
    $intermediary_id: uuid
    $description: String
    $name: String
  ) {
    insert_product_benefit_one(
      object: {
        id: $id
        insurance_product_type_id: $product_type_id
        intermediary_id: $intermediary_id
        description: $description
        name: $name
      }
      on_conflict: {
        constraint: product_benefit_name_insurance_product_type_id_key
        update_columns: updated_at
      }
    ) {
      id

      name

      description

      product_type {
        id

        name
      }
    }
  }
`

export const PHONEVERIFY = gql`
  mutation PhoneVerify($user_id: uuid!) {
    update_user_by_pk(
      pk_columns: { id: $user_id }
      _set: { is_verified: true }
    ) {
      id
    }
  }
`

export const SUBMIT_HOME_POLICY_REQUEST_LOGGED_IN = gql`
  mutation createPropertyRequest(
    $insurance_product_id: uuid
    $inception_date: date
    $expiry_date: date
    $customer_id: uuid
    $buy_back_excess: Boolean
    $num_months: Int
    $name: String
    $physical_address: String
    $gh_gps: String
    $have_insured_before: Boolean
    $is_solely_residential: Boolean
    $is_for_commerce: Boolean
    $construction_year: Int
    $any_financial_interest: Boolean
    $rebuilding_cost: float8
    $insure_walls: Boolean
    $insure_outhouse: Boolean
    $outhouse_rebuilding_cost: float8
    $walls_rebuilding_cost: float8
    $content_sum_insured: float8
    $public_liability_sum_insured: float8
    $public_accident_sum_insured: float8
    $rent_sum_insured: float8
    $beneficiary_name: String
    $beneficiary_email: String
    $beneficiary_phone_number: bigint
    $beneficiary_id: String
    $purchase_for_self: Boolean
    $no_claim_years: Int
    $policy_contents: [property_policy_detail_content_insert_input!]!
    $policy_materials: [property_policy_detail_material_insert_input!]!
    $policy_roofings: [property_policy_detail_roofing_insert_input!]!
    $policy_options: [property_policy_detail_option_insert_input!]!
    $policy_persons: [property_policy_detail_person_insert_input!]!
  ) {
    insert_property_policy_detail_one(
      object: {
        name: $name
        physical_address: $physical_address
        gh_gps: $gh_gps
        have_insured_before: $have_insured_before
        is_solely_residential: $is_solely_residential
        is_for_commerce: $is_for_commerce
        construction_year: $construction_year
        any_financial_interest: $any_financial_interest
        rebuilding_cost: $rebuilding_cost
        insure_walls: $insure_walls
        insure_outhouse: $insure_outhouse
        outhouse_rebuilding_cost: $outhouse_rebuilding_cost
        walls_rebuilding_cost: $walls_rebuilding_cost
        content_sum_insured: $content_sum_insured
        public_liability_sum_insured: $public_liability_sum_insured
        public_accident_sum_insured: $public_accident_sum_insured
        rent_sum_insured: $rent_sum_insured
        beneficiary_name: $beneficiary_name
        beneficiary_email: $beneficiary_email
        beneficiary_phone_number: $beneficiary_phone_number
        beneficiary_id: $beneficiary_id
        purchase_for_self: $purchase_for_self
        policy_contents: { data: $policy_contents }
        policy_materials: { data: $policy_materials }
        policy_roofings: { data: $policy_roofings }
        policy_options: { data: $policy_options }
        policy_persons: { data: $policy_persons }
        policy_request: {
          data: {
            num_months: $num_months
            customer_id: $customer_id
            proposed_inception_date: $inception_date
            proposed_expiry_date: $expiry_date
            no_claim_years: $no_claim_years
            buy_back_excess: $buy_back_excess
            insurance_product_id: $insurance_product_id
          }
        }
      }
    ) {
      id

      policy_request_id
    }
  }
`

export const SUBMIT_HOME_OWNER_REQUEST = gql`
  mutation QuickPropertyRequest(
    $insurance_product_id: uuid
    $inception_date: date
    $expiry_date: date
    $buy_back_excess: Boolean
    $num_months: Int
    $name: String
    $physical_address: String
    $gh_gps: String
    $have_insured_before: Boolean
    $is_solely_residential: Boolean
    $is_for_commerce: Boolean
    $construction_year: Int
    $any_financial_interest: Boolean
    $rebuilding_cost: float8
    $insure_walls: Boolean
    $insure_outhouse: Boolean
    $outhouse_rebuilding_cost: float8
    $walls_rebuilding_cost: float8
    $content_sum_insured: float8
    $public_liability_sum_insured: float8
    $public_accident_sum_insured: float8
    $rent_sum_insured: float8
    $beneficiary_name: String
    $beneficiary_email: String
    $beneficiary_phone_number: bigint
    $beneficiary_id: String
    $purchase_for_self: Boolean
    $no_claim_years: Int
    $policy_contents: [property_policy_detail_content_insert_input!]!
    $policy_materials: [property_policy_detail_material_insert_input!]!
    $policy_roofings: [property_policy_detail_roofing_insert_input!]!
    $policy_options: [property_policy_detail_option_insert_input!]!
    $policy_persons: [property_policy_detail_person_insert_input!]!
    $first_name: String
    $last_name: String
    $phone_number: bigint
    $date_of_birth: date
    $occupation: String
    $digital_address: String
    $national_id: String
    $residential_address: String
    $postal_address: String
    $gender: String
    $middle_name: String
    $nationality_id: uuid
    $company_name: String
    $company_reg_number: String
    $company_reg_document: String
    $registration_type: String
    $email: String
  ) {
    insert_property_policy_detail_one(
      object: {
        name: $name
        physical_address: $physical_address
        gh_gps: $gh_gps
        have_insured_before: $have_insured_before
        is_solely_residential: $is_solely_residential
        is_for_commerce: $is_for_commerce
        construction_year: $construction_year
        any_financial_interest: $any_financial_interest
        rebuilding_cost: $rebuilding_cost
        insure_walls: $insure_walls
        insure_outhouse: $insure_outhouse
        outhouse_rebuilding_cost: $outhouse_rebuilding_cost
        walls_rebuilding_cost: $walls_rebuilding_cost
        content_sum_insured: $content_sum_insured
        public_liability_sum_insured: $public_liability_sum_insured
        public_accident_sum_insured: $public_accident_sum_insured
        rent_sum_insured: $rent_sum_insured
        beneficiary_name: $beneficiary_name
        beneficiary_email: $beneficiary_email
        beneficiary_phone_number: $beneficiary_phone_number
        beneficiary_id: $beneficiary_id
        purchase_for_self: $purchase_for_self
        policy_contents: { data: $policy_contents }
        policy_materials: { data: $policy_materials }
        policy_roofings: { data: $policy_roofings }
        policy_options: { data: $policy_options }
        policy_persons: { data: $policy_persons }
        policy_request: {
          data: {
            num_months: $num_months
            customer: {
              data: {
                first_name: $first_name
                last_name: $last_name
                middle_name: $middle_name
                phone_number: $phone_number
                date_of_birth: $date_of_birth
                occupation: $occupation
                digital_address: $digital_address
                national_id: $national_id
                residential_address: $residential_address
                postal_address: $postal_address
                gender: $gender
                nationality_id: $nationality_id
                company_name: $company_name
                company_reg_number: $company_reg_number
                company_reg_document: $company_reg_document
                registration_type: $registration_type
                user: { data: { email: $email, user_type: $registration_type } }
              }
            }
            proposed_inception_date: $inception_date
            proposed_expiry_date: $expiry_date
            no_claim_years: $no_claim_years
            buy_back_excess: $buy_back_excess
            insurance_product_id: $insurance_product_id
          }
        }
      }
    ) {
      id

      policy_request_id
    }
  }
`

export const UPLOAD_MOTOR_REQUEST_DOCS = gql`
  mutation uploadMotorRequest(
    $vehicle_front_img: String
    $vehicle_back_img: String
    $road_worthy_cert: String
    $phone: bigint
    $email: String
  ) {
    insert_uploadable_motor_request_one(
      object: {
        vehicle_front_img: $vehicle_front_img
        vehicle_back_img: $vehicle_back_img
        road_worthy_cert: $road_worthy_cert
        phone: $phone
        email: $email
      }
    ) {
      id

      vehicle_front_img

      vehicle_back_img

      road_worthy_cert

      phone

      email
    }
  }
`

export const UPLOAD_MOTOR_REQUEST_DOCS_FOR_EXISTING_USER = gql`
  mutation UploadMotorRequest(
    $vehicle_front_img: String
    $vehicle_back_img: String
    $road_worthy_cert: String
    $start_at: date
    $end_at: date
    $vehicle_cost: float8
    $insurance_product_id: uuid!
    $customer_id: uuid!
  ) {
    insert_uploadable_motor_request_one(
      object: {
        policy_start_at: $start_at
        policy_end_at: $end_at
        vehicle_front_img: $vehicle_front_img
        vehicle_back_img: $vehicle_back_img
        road_worthy_cert: $road_worthy_cert
        vehicle_cost: $vehicle_cost
        insurance_product_id: $insurance_product_id
        customer_id: $customer_id
      }
    ) {
      id
      vehicle_front_img
      vehicle_back_img
      road_worthy_cert
      policy_start_at
      policy_end_at
      phone
      email
      vehicle_cost
      insurance_product {
        id
        name
        product_type {
          id
          name
        }
      }
      customer {
        id
        first_name
        middle_name
        last_name
      }
    }
  }
`

export const SIGN_CUSTOMER_UP_AND_UPLOAD_DOCS = gql`
  mutation QuickUploadMotorRequest(
    $vehicle_front_img: String
    $vehicle_back_img: String
    $road_worthy_cert: String
    $phone: bigint!
    $email: String
    $user_type: String
    $first_name: String!
    $last_name: String!
    $middle_name: String
    $national_id: String
    $start_at: date
    $end_at: date
    $vehicle_cost: float8
    $insurance_product_id: uuid!
  ) {
    insert_uploadable_motor_request_one(
      object: {
        policy_start_at: $start_at
        policy_end_at: $end_at
        vehicle_front_img: $vehicle_front_img
        vehicle_back_img: $vehicle_back_img
        road_worthy_cert: $road_worthy_cert
        phone: $phone
        email: $email
        vehicle_cost: $vehicle_cost
        insurance_product_id: $insurance_product_id
        customer: {
          data: {
            first_name: $first_name
            last_name: $last_name
            middle_name: $middle_name
            phone_number: $phone
            registration_type: $user_type
            national_id: $national_id
            user: { data: { email: $email, user_type: $user_type } }
          }
        }
      }
    ) {
      id

      vehicle_front_img

      vehicle_back_img

      road_worthy_cert

      policy_start_at

      policy_end_at

      phone

      email

      vehicle_cost

      insurance_product {
        id

        name

        product_type {
          id

          name
        }
      }

      customer {
        id

        first_name

        middle_name

        last_name
      }
    }
  }
`
