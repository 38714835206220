import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js'
//import ChartDataLabels from 'chartjs-plugin-datalabels';

const PieChart = ({ chartData }) => {

  return (
    <Pie data={chartData} options={
      {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom'
        }
      }
    }
    />
  )
}


export default PieChart
