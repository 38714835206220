import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import fail from '../../assets/img/fail.png';
import WebFont from 'webfontloader';
import './style.css';


function FailurePaymentPage(props) {

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Poppins']
            }
        });
    }, []);

    const navigate = useNavigate();
    return (
        <div className='main-container'>
            <div className='logo-container'>
                <img src={fail} className='logo' />
            </div>
            <div>
                <h3 className='title font-loader'>Transaction Failed</h3>
            </div>
            <div className='sub-title-container'>
                <h5 className='sub-title font-loader'>Please try again, or contact support</h5>
            </div>
            <div className='button-container'>
                <input
                    style={{
                        color: '#F6F8FB',
                        backgroundColor: '#2E79E9',
                        border: 'none',
                        width: '160px',
                        height: '40px',
                        fontSize: '14px',
                        cursor: "pointer",
                        margin: 15,
                    }}
                    className='getstarted font-loader' type="button" value="Try again"
                    onClick={() => navigate("/customer/acceptedquotes")} />
                    
                <input
                    style={{
                        color: '#2E79E9',
                        backgroundColor: '#F6F8FB',
                        border: 'solid #2E79E9',
                        width: '160px',
                        height: '40px',
                        fontSize: '14px',
                        cursor: "pointer",
                        margin: 15,
                    }}
                    className='getstarted font-loader' type="button" value="Contact Support"
                    onClick={() => navigate("/")} />
            </div>
        </div>
    );
}

export default FailurePaymentPage;