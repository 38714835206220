import React, { useState, useEffect } from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Form } from 'react-bootstrap'
import WebFont from 'webfontloader'
import './styles.css'

const FormInput = ({
  label,
  placeholder,
  handleChange,
  type,
  name,
  defaultValue,
  value,
  style,
  onInput,
  disabled,
  onBlur
}) => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    })
  }, [])

  return (
    <>
      {name?.includes('assured') ? (
        <>
          <OverlayTrigger
            placement='right'
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <Form.Group>
              <Form.Label className='font-loader'>
                {label}
                <span style={{ color: 'red' }}>*</span>
              </Form.Label>

              <Form.Control
                className='font-loader form-control'
                onChange={handleChange}
                type={type}
                placeholder={placeholder}
                name={name}
                defaultValue={defaultValue}
                value={value}
                style={style}
                onInput={onInput}
                disabled={disabled}
                onBlur={onBlur}
                min={type === 'number' ? '0' : null}
              ></Form.Control>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type='invalid'>
                Field must not be empty
              </Form.Control.Feedback>
            </Form.Group>
          </OverlayTrigger>
        </>
      ) : (
        <>
          <Form.Group>
            <Form.Label className='font-loader'>
              {label}
              <span style={{ color: 'red' }}>*</span>
            </Form.Label>

            <Form.Control
              // className='font-loader form-control'
              onChange={handleChange}
              type={type}
              placeholder={placeholder}
              name={name}
              defaultValue={defaultValue}
              value={value}
              style={style}
              onInput={onInput}
              disabled={disabled}
              onBlur={onBlur}
              min={type === 'number' ? '0' : null}
            ></Form.Control>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type='invalid'>
              Field must not be empty
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}
    </>
  )
}

const renderTooltip = (props) => {
  return (
    <Tooltip id='button-tooltip' {...props}>
      This is the guaranteed amount an insurance company will pay for a claim.
    </Tooltip>
  )
}

export default FormInput
