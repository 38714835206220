import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 5px 10px hsla(214, 37%, 89%, 0.996)',
    marginBottom: '20px',
    background: '#fff',
    borderRadius: '5px'
  },
  row1: {
    display: 'flex',
    // justifyContent: 'space-between',
    padding: '10px'
  },
  row2: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px'
  }
}));

const SummaryItem2 = ({data}) => {
 const {time,item_name,count,customer_name}=data
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.row1)}>
        <div>
          {' '}
          <Typography
            align="center"
            // color="primary"

            variant="body1"
          >
             <strong>{time} stats</strong>
          </Typography>
        </div>
        </div>
        <div className={clsx(classes.row1)}>
        <div>
          {' '}
          <Typography
            align="center"
            // color="primary"

            variant="body1"
          >
             <p>Name:  <strong>{customer_name||item_name}</strong> </p>
          </Typography>
        </div>
        </div>

      <div className={clsx(classes.row2)}>
        <div>
          <Typography
            align="center"
            variant="body1"
          >
            <p>Purchases Made: <strong>{count}</strong> </p>
          </Typography>
        </div>
      </div>
     
    </div>
  );
};

SummaryItem2.propTypes = {
  className: PropTypes.string
};

export default SummaryItem2;
