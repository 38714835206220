import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, ListItem, makeStyles } from '@material-ui/core';
import { useAppContext } from 'src/Context';
import '../style.css';
import WebFont from 'webfontloader';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 30
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1),
    height: 30
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    // backgroundColor:"#2E79E9",
    height: 52,
    width: 260,
    color: '#0E3E86',
    backgroundColor: '#E6F5FF',
    '& $title': {
      marginTop: 5,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 15,
      color: '#0E3E86'
    },
    '& $icon': {
      color: '#0E3E86',
      width: 22,
      height: 49
    }
  },
  inactive: {
    backgroundColor: 'white',
    height: 82,
    width: 260,
    color: '2E79E9',
    '& $title': {
      marginTop: 5,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 15,
      fontFamily: 'Poppins'
    },
    '& $icon': {
      color: '2E79E9',
      width: 22,
      height: 49
    }
  }
}));

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  isActive,
  onMobileClose,
  getLink,
  onClick,
  ...rest
}) => {
  const classes = useStyles();

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    });
  }, []);

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button
        onClick={() => onMobileClose()}
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
